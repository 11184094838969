import React, { useState } from "react";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableMeta,
} from "mui-datatables";
import { useFormContext } from "react-hook-form";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import StaffTourOfDutyItem from "./staffTourOfDutyItem.component";
import classnames from "classnames";
import "../assets/sass/elements/staffTourOfDutyDispGrid.styles.scss";
import { ITourOfDuty, TourPayLoad, IEmployeeInfo } from "../interfaces";
import { CollectionActions } from "../reducers/actions";
import { ActionTypes } from "../reducers/action-types";
import { Validation } from "../classes";
import { arrIndexerName } from "../utilities";

const useStyles = makeStyles({
  editIcon: {
    fontSize: "1.2rem",
  },
  hidden: {
    display: "none",
  },
  dispOnly: {
    "& [class*=MUIDataTableToolbar-root]": {
      minHeight: "42px",
      // maxHeight: "42px",
    },
    // '& [class*=MUIDataTableToolbar-root]:not(.MuiTablePagination-toolbar)': {
    //     display: 'none'
    // },
    // '& [class*=MUIDataTableToolbar-left]': {
    //     marginLeft: '-24px',
    // },
    "& [class*=MUIDataTableToolbar-actions] .MuiSvgIcon-root": {
      width: ".9em",
      height: ".9em",
    },
    "& [class*=MUIDataTablePagination-toolbar]": {
      minHeight: "42px",
      maxHeight: "42px",
      overflowY: "hidden",
    },
    "& [class*=MUIDataTableHeadCell-root]": {
      paddingLeft: "12px",
      fontSize: ".8rem",
    },
    "& [class*=MUIDataTableBodyCell-root]": {
      paddingLeft: "12px",
      fontSize: ".8rem",
    },
    // "& [class*=MuiTableCell-root]": {
    //   height: "42px",
    //   padding: "0",
    // },
    "& [class*=MuiTablePagination-caption]:first-of-type": {
      display: "none",
    },
    "& [class*=MuiTablePagination-selectRoot]": {
      display: "none",
    },
    "& #staffTourOfDutyGrid-title": {
      fontSize: "1rem",
    },
  },
  divider: {
    borderTop: "2px outset rgba(0, 0, 0, .1)",
  },
});

const getMuiTheme = () =>
  (createMuiTheme as any)({
    overrides: {
      MUIDataTable: {
        paper: {
          backgroundColor: "rgba(243, 243, 243, 1)",
          "@media print": {
            backgroundColor: "white",
            paddingTop: "3cm",
            paddingBottom: "3cm",
          },
        },
      },
      MUIDataTableToolbar: {
        left: {
          display: "inline-flex",
          marginLeft: "-12px",
        },
        actions: {
          "@media print": {
            display: "none",
          },
        },
      },
      MUIDataTableViewCol: {
        checkboxRoot: {
          "&&$checked": {
            color: "rgba(1, 65, 137, 1)",
          },
        },
      },
      MUIDataTableFilter: {
        checkbox: {
          "&&$checked": {
            color: "rgba(1, 65, 137, 1)",
          },
        },
      },
      MUIDataTableSelectCell: {
        root: {
          "@media print": {
            display: "none",
          },
        },
        headerCell: {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
        fixedHeaderCommon: {
          backgroundColor: "transparent",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeaderCommon: {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
        toolButton: {
          display: "inline-block",
        },
      },
      MuiTableCell: {
        root: {
          padding: "0",
        },
        body: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      MuiTypography: {
        root: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      MuiButton: {
        textPrimary: {
          color: "rgba(1, 65, 137, 1)",
          "&:focus": {
            outlineWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          "&:focus": {
            outlineWidth: 0,
          },
        },
      },
    },
  });

type StaffTourOfDutyDispGridProps = {
  parentId: string;
  reducer: [IEmployeeInfo, React.Dispatch<CollectionActions<ITourOfDuty>>];
  addClass?: string;
  isDispOnly?: boolean;
};

export default function StaffTourOfDutyDispGrid({
  addClass,
  isDispOnly,
  reducer,
}: StaffTourOfDutyDispGridProps) {
  const classes = useStyles();
  const DialogStyledButton = withStyles({
    root: {
      color: "#ffffff",
      backgroundColor: "#014189",
      opacity: 1,
      fontWeight: 400,
      fontSize: ".875rem",
      lineHeight: 1.5,
      display: "inline-flex",
      margin: "24px 10px 15px",
      "&:hover": {
        backgroundColor: "#346ba9",
      },
      "&:focus": {
        outlineWidth: 0,
      },
      padding: ".25rem .5rem",
      fontFamily: "inherit",
    },
    label: {
      textTransform: "capitalize",
    },
  })(Button);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
        viewColumns: true,
        searchable: false,
      },
    },
    {
      name: "empSSN",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
        searchable: false,
      },
    },
    {
      name: "empTourId",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
        searchable: false,
      },
    },
    {
      name: "empTourOffice",
      label: "Office",
      options: {},
    },
    {
      name: "empTourJob",
      label: "Job",
      options: {},
    },
    {
      name: "USStartDate",
      label: "Start Date",
      options: {
        // sortDirection: 'desc',
        // sortCompare: (order: any) => {
        //     return (objA: any, objB: any) => {
        //       const aDate = new Date(objA).toISOString();
        //       const bDate = new Date(objB).toISOString();
        //       return order==='asc' ? (aDate > bDate ? -1 : aDate < bDate ? 1 : 0) : (bDate > aDate ? -1 : bDate < aDate ? 1 : 0);
        //     };
        //   }
      },
    },
    {
      name: "USEndDate",
      label: "End Date",
      options: {},
    },
    {
      name: "Edit",
      label: "...",
      options: {
        filter: false,
        sort: false,
        print: false,
        viewColumns: false,
        searchable: false,
        setCellHeaderProps: (value: any) => {
          return {
            className: classnames({
              [classes.hidden]: isDispOnly,
            }),
            style: { justifyContent: "center", textAlign: "center" },
          };
        },
        customBodyRender: (
          _value: any,
          tableMeta: MUIDataTableMeta,
          updateValue: any
        ) => (
          <React.Fragment>
            <IconButton
              color="primary"
              onClick={() => {
                handleClickOpen(tableMeta.rowIndex, tableMeta.rowData);
              }}
            >
              <Tooltip title="Click to Edit...">
                <EditIcon className={classes.editIcon} />
              </Tooltip>
            </IconButton>
          </React.Fragment>
        ),
        setCellProps: (value: any) => {
          return {
            className: classnames({
              [classes.hidden]: isDispOnly,
            }),
            style: { textAlign: "center" },
          };
        },
      },
    },
  ] as any;

  const [ndx, setNdx] = useState(0);

  const [tourItem, setSelectedItem] = useState<ITourOfDuty | undefined>(
    undefined
  );

  const [employee, dispatch] = reducer;

  const { existingItems } = employee.toursOfDuty;

  const selectRows = isDispOnly ? "none" : "multiple";

  const [open, setOpen] = useState(false);

  const { control, setError, clearError } = useFormContext();

  const handleClickOpen = (index: number, tourOfDuty?: any[]) => {
    if (tourOfDuty) {
      const tour = existingItems[index];
      clearError();
      setNdx(index);
      setSelectedItem(tour);
      setOpen(true);
    }
  };
  const handleDelete = (u: any) => {
    const rows = u as {
      data: Array<{ index: number; dataIndex: number }>;
      lookup: { [property: number]: boolean };
    };

    const indicies = rows.data.map((d) => d.dataIndex);

    const sorted = indicies.sort();

    employee.toursOfDuty.deleteItems(sorted);

    dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employee });
  };

  const handleSave = () => {
    const record = control.getValues({ nest: true }) as TourPayLoad;
    const tourItem = record.existingItems[ndx];
    if (Validation.hasNoValue(tourItem.empTourOffice)) {
      setError(`existingItems[${ndx}].empTourOffice`, {
        type: "manual",
        message: "Office required",
      });
      return;
    }
    if (Validation.hasNoValue(tourItem.empTourJob)) {
      setError(`existingItems[${ndx}].empTourJob`, {
        type: "manual",
        message: "Job required",
      });
      return;
    }
    if (
      Validation.hasNoValue(tourItem.empTourStart) ||
      tourItem.empTourStart === ""
    ) {
      setError(arrIndexerName("existingItems", ndx, "empTourStart"), {
        type: "manual",
        message: "Start date required",
      });
      return;
    }
    if (
      tourItem.empTourEnd !== "" &&
      !Validation.isBeforeDate(tourItem.empTourStart, tourItem.empTourEnd)
    ) {
      setError(arrIndexerName("existingItems", ndx, "empTourEnd"), {
        type: "manual",
        message: "End date cannot precede start date",
      });
      return;
    }

    const updatedItem = record.existingItems[ndx];
    updatedItem.empTourId = existingItems[ndx].empTourId;

    employee.toursOfDuty.updateItem("empTourId", updatedItem);

    dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employee });

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <TableContainer
        id="staffTourOfDutyGrid"
        className={`${addClass} p-1${classnames({
          [" " + classes.dispOnly]: isDispOnly,
        })}`}
      >
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={
              <Typography
                variant="h6"
                id="staffTourOfDutyGrid-title"
                component="div"
              >
                Tours-of-Duty
              </Typography>
            }
            data={existingItems}
            columns={columns}
            options={{
              filterType: "multiselect",
              responsive: "scrollFullHeight",
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 15, 100],
              selectableRows: selectRows,
              selectableRowsHeader: !isDispOnly,
              elevation: 2,
              onRowsDelete: (deletedRows) => handleDelete(deletedRows),
            }}
          />
        </MuiThemeProvider>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{ variant: "outlined" }}
        className="border border-primary"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title" className="text-primary">
          Edit Tour
        </DialogTitle>
        <DialogContent>
          <StaffTourOfDutyItem
            parentId="thisParentId"
            name="existingItems"
            index={ndx}
            tourItem={tourItem}
          />
        </DialogContent>
        <DialogActions>
          <DialogStyledButton onClick={handleClose}>Cancel</DialogStyledButton>
          <DialogStyledButton onClick={handleSave}>Save</DialogStyledButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
