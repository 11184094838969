import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textMsg: {
      fontSize: ".8rem",
    },
    error: {
      display: "inline-block",
      width: "100%",
      textAlign: "left",
      color: "#cc0000",
      "& .icon": {
        color: "#cc0000",
        paddingRight: "5px",
      },
    },
  })
);

const TextMessage = ({
  error,
  text,
  fontSize,
}: {
  error?: boolean;
  text: string;
  fontSize?: string;
}) => {
  const classes = useStyles();

  return (
    <span className={(error && `${classes.error}`) || ""}>
      {error && (
        <FontAwesomeIcon icon={faExclamationTriangle} className="icon" />
      )}
      <span
        className={classes.textMsg}
        style={{ fontSize: (fontSize && `${fontSize}`) || "" }}
      >
        {text}
      </span>
    </span>
  );
};

export default TextMessage;
