import React, { useState, useEffect } from "react";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";

import { IJob } from "../interfaces";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TextMessage from "../components/textMessage.component";

import "../assets/sass/elements/jobSelector.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    popper: {
      minWidth: 350,
    },
  })
);

type JobSelectorProps = {
  ctrlName: string;
  ctrlLabel: string;
  addLabelClass?: string;
  addClass?: string;
  variant?: "filled" | "outlined" | "standard" | any;
  defaultValue?: IJob | null;
  required?: boolean;
};

const JobSelector = ({
  ctrlName,
  ctrlLabel,
  addLabelClass,
  addClass,
  variant,
  defaultValue,
  required,
}: JobSelectorProps) => {
  const classes = useStyles();

  const { control, errors } = useFormContext();

  useEffect(() => {
    let unmounted = false;
    async function getJobs() {
      const url = `https://aws-us-east-2.unbounded.cloud/databases/jobs/match`;
      const username = "ldrummondbruce@me.com";
      const password = "LqF8sTtTP6UGKR8";
      const headers = new Headers();
      headers.append(
        "Authorization",
        "Basic " + btoa(username + ":" + password)
      );
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const body = await response.json();

      if (!unmounted) {
        setJobs(Object.keys(body.results).map((key) => body.results[key]));
      }
    }
    getJobs();
    return () => {
      unmounted = true;
    };
  }, []);

  const [jobs, setJobs] = useState<Array<IJob>>([]);

  return (
    <div className={`${addClass ? " " + addClass : ""}`}>
      <Controller
        name={ctrlName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: true,
        }}
        onChange={([, newValue]) => {
          return newValue;
        }}
        as={
          <Autocomplete
            id={ctrlName}
            className={`${classes.formControl}`}
            autoHighlight
            autoSelect
            options={jobs}
            getOptionLabel={(option) => {
              return option.jobCode;
            }}
            renderOption={(option) => (
              <React.Fragment key={option.id}>
                <span className="selValue selJob-selValue">
                  {option.jobCode}
                </span>
                <span className="selLabel selJob-selLabel">
                  {option.jobTitle}
                </span>
              </React.Fragment>
            )}
            getOptionSelected={(option: IJob, value: IJob) => {
              return option.jobCode === value.jobCode;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={ctrlName}
                name={ctrlName}
                label={ctrlLabel}
                variant={variant}
                fullWidth
              />
            )}
            classes={{ popper: classes.popper }}
          />
        }
      />
      {required && (
        <ErrorMessage
          errors={errors}
          name={ctrlName}
          message={<TextMessage text="Job required" error />}
        />
      )}
    </div>
  );
};

export default JobSelector;
