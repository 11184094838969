import React from "react";
import SplashPage from "../components/splashPage.component";
import NavButtonBar from "../components/navButtonBar.component";
import NavButton from "../components/navButton.component";

export default function Reports() {
  return (
    <SplashPage pageId="pgReports" title="Reports">
      <div className="bodyText">
        {/* <h2 className="header">Reports</h2> */}
        <NavButtonBar title="Report Options" addClass="btn-group-med">
          <NavButton
            type="button"
            title="Personnel Directory"
            dest="/reports/preview/staffDir"
          />
          <NavButton
            type="button"
            title="Office Directory"
            dest="/reports/preview/officeDir"
          />
          <NavButton
            type="button"
            title="Tours-of-Duty"
            dest="/reports/filterSort/tours"
          />
          <NavButton
            type="button"
            title="TDY Assignments"
            dest="/reports/filterSort/travel"
          />
          <NavButton
            type="button"
            title="Vacancy/Posting Status (TBD)"
            dest="/reports/filterSort/staffing"
            isDisabled={true}
          />
        </NavButtonBar>
      </div>
    </SplashPage>
  );
}
