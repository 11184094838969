import React from "react";

import '../../assets/sass/elements/appFooter.styles.scss';

const curDate = new Date();
const currentYear = curDate.getFullYear();

function Footer() {
  return (
    <footer className="App-footer">
      <p className="text-muted">Copyright © {currentYear}</p>
    </footer>
  );
}

export default Footer;
