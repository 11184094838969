import React, { useState, useEffect, useContext } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { AppContext } from "../context";
import { EmpActionTypes } from "../reducers/action-types";
import { IEmployee } from "../interfaces";
import { Employee } from "../classes";
import { clientWrapper } from "../api";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../assets/sass/elements/staffSelector.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      minWidth: theme.spacing(15),
    },
    leftOffset: {
      left: "-10px",
    },
    leftOffsetSortOf: {
      paddingTop: "10px",
      paddingLeft: "3px",
    },
    divider: {
      borderTop: "2px outset rgba(0, 0, 0, .1)",
    },
  })
);

type StaffSelectorProps = {
  ctrlName: string;
  ctrlLabel: string;
  addClass?: string;
  variant?: "filled" | "outlined" | "standard" | any;
  preset?: string | null;
};

export default function StaffSelector({
  ctrlName,
  ctrlLabel,
  addClass,
  variant,
  preset,
}: StaffSelectorProps) {
  const classes = useStyles();

  const { dispatch } = useContext(AppContext);
  const { control } = useFormContext();

  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState<IEmployee[]>([]);

  const client = clientWrapper("aws-us-east-2");
  const personnelDb = client.database("personnel");

  const loading = open && employees.length === 0;
  useEffect(() => {
    let active = true;
    (async () => {
      try {
        if (open) {
          const employees = (await personnelDb
            .query()
            .sort((o: IEmployee) => o.empLastName + ", " + o.empFirstName)
            .map((o: IEmployee) => {
              // const testEmp = new Employee(o);
              return {
                ...o,
                empLastFirst: o.empLastName + ", " + o.empFirstName,
              };
            })
            .send()) as IEmployee[];
          if (employees && active) {
            setEmployees(employees);
          }
        } else {
          setEmployees([]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      active = false;
    };
  }, [open]);

  return (
    <Controller
      name={ctrlName}
      control={control}
      defaultValue={null}
      onChange={([, newValue]) => {
        console.log("newValue: ", newValue);
        dispatch({
          type: EmpActionTypes.GET_EMPLOYEE,
          payload: newValue,
        });

        return newValue;
      }}
      as={
        <Autocomplete
          id={ctrlName}
          className={`${classes.formControl}${addClass ? " " + addClass : ""}`}
          autoHighlight
          autoSelect
          loading={loading}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={employees}
          getOptionLabel={(option) =>
            option?.empLastFirst ? option.empLastFirst : ""
          }
          getOptionSelected={(option: IEmployee, value: IEmployee) => {
            return option.empSSN === value.empSSN;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={ctrlName}
              label={ctrlLabel}
              variant={variant}
              fullWidth
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              InputProps={{
                ...params.InputProps,
                className: classes.leftOffsetSortOf,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      }
    />
  );
}
