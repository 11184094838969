import React, { useState, useEffect, useContext, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  useForm,
  FormContext,
  Controller,
  ErrorMessage,
} from "react-hook-form";

import { AppContext } from "../../context";
import {
  EmpActionTypes,
  EmpInfoActionTypes,
  OfcActionTypes,
} from "../../reducers/action-types";
import {
  IEmployee,
  IDependent,
  ITraining,
  ICourse,
  IOffice,
} from "../../interfaces";
import { EmployeeInfo, Save } from "../../classes";
import { Validation } from "../../classes";
import { clientWrapper } from "../../api";
import { convertStrToDate, ofcObjAsStr } from "../../utilities";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import FormContainer from "../formContainer.component";
import StreetAddress from "../streetAddress.component";
import StaffSelector from "../staffSelector.component";
import OfficeSelector from "../officeSelector.component";
import CallPrefixSelector from "../callPrefixSelector.component";
import PhoneBlock from "../phoneBlock.component";
import NavButtonBar from "../navButtonBar.component";
import NavButton from "../navButton.component";
import MessageBar, { severity } from "../messageBar.component";
import StaffDependentInputRows from "../staffDependentInputRows.component";
import StaffTrainingInputRows from "../staffTrainingInputRows.component";
import TextMessage from "../textMessage.component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type TParams = { action: string };
type TStateProps = { prev: string; isCtxReset: boolean };

export default function StaffRecord({
  match,
  ...props
}: RouteComponentProps<TParams, any, TStateProps>) {
  const classes = useStyles();

  const { location } = props;
  const formAction = match.params.action;
  const isExisting = formAction !== "addRecord";
  const formTitle = !isExisting ? "New Employee" : "Update Employee";

  const client = clientWrapper("aws-us-east-2");
  const employeesDb = client.database("personnel");

  const depSave = new Save<IDependent>();
  const trngSave = new Save<ITraining>();

  const { state, dispatch } = useContext(AppContext);

  const methods = useForm<{
    mode: "onBlur";
    // shouldFocusError: true;
    empSelected?: IEmployee;
    empData: IEmployee;
    empDependents?: Array<IDependent>;
    empTraining?: Array<ITraining>;
  }>({});
  const {
    formState,
    setValue,
    control,
    handleSubmit,
    errors,
    reset,
    watch,
  } = methods;
  const watchEmpSelected = watch<string, IEmployee>("empSelected");

  const [disabled, setDisabled] = useState(isExisting ? true : false);
  const [msg, setMsg] = useState<{
    show: boolean;
    message: string;
    severity: severity;
  }>({ show: false, message: "", severity: undefined });

  const resetEmployee = useCallback(() => {
    setValue("empSelected", {});
    dispatch({
      type: EmpActionTypes.GET_EMPLOYEE,
      payload: null,
    });
    dispatch({
      type: EmpInfoActionTypes.INITIALIZE,
      payload: new EmployeeInfo(),
    });
    reset(
      {},
      {
        errors: false,
        dirtyFields: false,
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  }, [dispatch, setValue, reset]);

  const resetContext = useCallback(() => {
    setValue("empSelected", {});
    dispatch({
      type: EmpActionTypes.GET_EMPLOYEE,
      payload: null,
    });
    dispatch({
      type: EmpInfoActionTypes.INITIALIZE,
      payload: new EmployeeInfo(),
    });
    dispatch({
      type: OfcActionTypes.GET_OFFICE,
      payload: null,
    });
    reset(
      {},
      {
        errors: false,
        dirtyFields: false,
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  }, [dispatch, setValue, reset]);

  const hideMessage = () => {
    setMsg({ show: false, message: "", severity: undefined });
  };

  // useEffect(() => {
  //   console.log(control);
  //   if (Object.keys(errors).length > 0) {
  //     console.log(errors);
  //   }
  // });

  useEffect(() => {
    if (location) {
      if (location.state) {
        if (location.state.prev !== location.pathname) {
          if (!location.state.isCtxReset) {
            // console.log("location.state.isCtxReset: ", location.state.isCtxReset)
            resetContext();
            location.state.isCtxReset = true;
          }
        } else {
          // console.log("location.state.isCtxReset: ", location.state.isCtxReset)
          location.state.isCtxReset = false;
        }
      }
    }

    return () => {
      // console.log("unmount effect1 (staffRecord)");
    };
  }, [location, resetContext]);

  useEffect(() => {
    // console.log("isExisting: ", isExisting);
    // console.log("watchEmpSelected: ", watchEmpSelected);
    setDisabled(!isExisting || watchEmpSelected?.empId ? false : true);
    if (watchEmpSelected !== undefined) {
      setValue("empData.empStatus", watchEmpSelected?.empStatus || "");
      setValue("empData.empSSN", watchEmpSelected?.empSSN || "");
      setValue(
        "empData.empDOB",
        convertStrToDate(watchEmpSelected?.empDOB) || ""
      );
      setValue("empData.empRoleAorS", watchEmpSelected?.empRoleAorS || "");
      setValue("empData.empLastName", watchEmpSelected?.empLastName || "");
      setValue("empData.empFirstName", watchEmpSelected?.empFirstName || "");
      setValue(
        "empData.empHomeOfRecord",
        watchEmpSelected?.empHomeOfRecord || ""
      );
    } else {
      resetEmployee();
    }
    return () => {
      // console.log("unmount effect2 (staffRecord)");
    };
  }, [isExisting, watchEmpSelected, setValue, resetEmployee]);

  const onSubmit = async (data: {
    empData: IEmployee;
    empSelected?: IEmployee;
    empDependents?: Array<IDependent>;
    empTraining?: Array<ITraining>;
  }) => {
    const { empData, empSelected, empDependents, empTraining } = data;

    if (!empData) return;
    let empId: string = "";
    empData.empHomeOffice = ofcObjAsStr(empData.empHomeOffice);
    try {
      if (!empSelected) {
        empData.empStatus = "Active";
        const { inserted } = await employeesDb.add(empData);
        empId = inserted[0];
      } else {
        empId = empSelected.empId;
        const { updated } = await employeesDb
          .update()
          .match({ empId: empId })
          .set((data: IEmployee, obj: IEmployee) => {
            obj.empStatus = data.empStatus;
            obj.empSSN = data.empSSN;
            obj.empDOB = data.empDOB;
            obj.empRoleAorS = data.empRoleAorS;
            obj.empLastName = data.empLastName;
            obj.empFirstName = data.empFirstName;
            obj.empHomeOffice = data.empHomeOffice;
            obj.empHomeOfRecord = data.empHomeOfRecord;
            obj.empDialPrefix = data.empDialPrefix;
            obj.empPhone_1 = data.empPhone_1;
            obj.empPhone_2 = data.empPhone_2;
            obj.empPhone_3 = data.empPhone_3;
            obj.empPhone_4 = data.empPhone_4;
            obj.empOverseasAddress_line_1 = data.empOverseasAddress_line_1;
            obj.empOverseasAddress_line_2 = data.empOverseasAddress_line_2;
            obj.empOverseasAddress_line_3 = data.empOverseasAddress_line_3;
            obj.empOverseasAddress_line_4 = data.empOverseasAddress_line_4;
            return obj;
          })
          .bind(empData)
          .single()
          .send();
        console.log("updated: ", updated);
      }

      if (
        state.employeeInfo &&
        state.employeeInfo.dependents.deletedItems.length > 0
      ) {
        const { deletedItems } = state.employeeInfo.dependents;
        await depSave.deleteAction(
          "personnelDependents",
          "empDepId",
          deletedItems
        );
      }

      if (empDependents) {
        const newDeps = empDependents
          .filter((dep) => dep.empDepId === "")
          .map((dep) => {
            const newData = { ...dep };
            newData.empDepId = newData.id;
            delete newData.id;
            return newData;
          });

        const existing = empDependents
          .filter((dep) => dep.empDepId !== "")
          .map((dep) => dep);

        newDeps.length > 0 &&
          (await depSave.addAction("personnelDependents", newDeps));

        existing.length > 0 &&
          (await depSave.updateAction(
            "personnelDependents",
            "empDepId",
            existing,
            (newData, oldData) => {
              const replacement = { ...oldData };
              replacement.empDepDOB = newData.empDepDOB;
              replacement.empDepFullName = newData.empDepFullName;
              replacement.empDepRelationship = newData.empDepRelationship;
              replacement.empDepStation = newData.empDepStation;
              replacement.empSSN = newData.empSSN;
              return replacement;
            }
          ));
      }

      if (
        state.employeeInfo &&
        state.employeeInfo.trainingCollection.deletedItems.length > 0
      ) {
        const { deletedItems } = state.employeeInfo.trainingCollection;
        await trngSave.deleteAction(
          "personnelTraining",
          "empTrnId",
          deletedItems
        );
      }

      if (empTraining) {
        const newTrng = empTraining
          .filter((trng) => trng.empTrnId === "")
          .map((trng) => {
            const newData = { ...trng };
            newData.empTrnId = newData.id;
            delete newData.id;
            return {
              ...newData,
              empTrnOffice: ofcObjAsStr(newData.empTrnOffice),
              empTrnCourse:
                (trng.empTrnCourse as ICourse).courseCode || trng.empTrnCourse,
            };
          });

        const existing = empTraining
          .filter((trng) => trng.empTrnId !== "")
          .map((trng) => {
            return {
              ...trng,
              empTrnOffice: ofcObjAsStr(trng.empTrnOffice),
              empTrnCourse:
                (trng.empTrnCourse as ICourse).courseCode || trng.empTrnCourse,
            };
          });

        newTrng.length > 0 &&
          (await trngSave.addAction("personnelTraining", newTrng));

        existing.length > 0 &&
          (await trngSave.updateAction(
            "personnelTraining",
            "empTrnId",
            existing,
            (newData, oldData) => {
              const replacement = { ...oldData };
              replacement.empTrnCourse = newData.empTrnCourse;
              replacement.empTrnEnd = newData.empTrnEnd;
              replacement.empTrnStart = newData.empTrnStart;
              replacement.empTrnNote = newData.empTrnNote;
              replacement.empTrnOffice = newData.empTrnOffice;
              replacement.empSSN = newData.empSSN;
              return replacement;
            }
          ));
      }

      setMsg({ show: true, message: "Saved!", severity: "success" });
    } catch (error) {
      setMsg({ show: true, message: "Error!", severity: "error" });
      throw error;
    }
    resetEmployee();
  };

  return (
    <FormContainer id="pgStaffRecord" title={formTitle}>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="formStaffRecord">
          {isExisting && (
            <div className="form-row justify-content-between">
              <StaffSelector
                ctrlName="empSelected"
                ctrlLabel="Select Employee"
                variant="filled"
                addClass="col-xl-4 col-6"
              />
              <FormControl
                className={`${classes.formControl} col-xl-8 col-6`}
                component="fieldset"
              >
                <FormLabel component="legend">Employment Status</FormLabel>
                <Controller
                  id="empData.empStatus"
                  name="empData.empStatus"
                  defaultValue=""
                  className="d-inline-block text-nowrap"
                  aria-label="emp-status"
                  control={control}
                  as={
                    <RadioGroup>
                      <FormControlLabel
                        label="Active"
                        control={<Radio color="primary" disabled={disabled} />}
                        value="Active"
                      />
                      <FormControlLabel
                        label="Inactive"
                        control={<Radio color="primary" disabled={disabled} />}
                        value="Inactive"
                      />
                      <FormControlLabel
                        label="Retired"
                        control={<Radio color="primary" disabled={disabled} />}
                        value="Retired"
                      />
                    </RadioGroup>
                  }
                />
              </FormControl>
            </div>
          )}
          <div className="form-row">
            <div className="col-4">
              <Controller
                as={<TextField />}
                type="text"
                id="empData.empSSN"
                name="empData.empSSN"
                defaultValue=""
                label="SSN"
                // InputLabelProps={{
                //   className: classes.leftOffset,
                // }}
                className={`${classes.formControl}`}
                control={control}
                rules={{
                  required: true,
                  pattern: /^\d{3}-\d{2}-\d{4}$/,
                  // pattern: /^(\d{3})-?(\d{2})-?(\d{4})$/,
                }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="empData.empSSN"
                message={
                  (errors.empData?.empSSN &&
                    errors.empData?.empSSN.type === "pattern" && (
                      <TextMessage
                        text="Enter SSN in format '###-##-####'"
                        error
                      />
                    )) || <TextMessage text="SSN required" error />
                }
              />
            </div>
            <div className="col-4">
              <Controller
                as={<TextField />}
                type="date"
                id="empData.empDOB"
                name="empData.empDOB"
                defaultValue=""
                label="Date of Birth"
                InputLabelProps={{
                  shrink: true,
                  // className: classes.leftOffset,
                }}
                className={`${classes.formControl}`}
                control={control}
                rules={{
                  validate: {
                    lessThanToday: (value: string) => {
                      const today = new Date();
                      const clear = !value
                        ? true
                        : Validation.isBeforeDate(value, today);

                      if (clear) return true;

                      return false;
                    },
                  },
                }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name={"empData.empDOB"}
                message={<TextMessage text="DOB must be in the past" error />}
              />
            </div>
            <FormControl
              id="empRoleAorS-selector"
              className={`${classes.formControl} col-4`}
            >
              <InputLabel id="empRoleAorS-label" className={classes.leftOffset}>
                A/S
              </InputLabel>
              <Controller
                as={
                  <Select>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"A"}>Agent (A)</MenuItem>
                    <MenuItem value={"S"}>Support (S)</MenuItem>
                  </Select>
                }
                id="empData.empRoleAorS"
                name="empData.empRoleAorS"
                defaultValue=""
                labelId="empRoleAorS-helper-label"
                control={control}
                rules={{
                  required: true,
                }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name={"empData.empRoleAorS"}
                message={
                  <TextMessage
                    text="'Agent' or 'Support' role selection required"
                    error
                  />
                }
              />
            </FormControl>
          </div>
          <div className="form-row">
            <div className="col-md-3 col-4">
              <Controller
                as={<TextField inputProps={{ maxLength: 30 }} />}
                id="empData.empLastName"
                name="empData.empLastName"
                defaultValue=""
                label="Last Name"
                // InputLabelProps={{
                //   className: classes.leftOffset,
                // }}
                className={`${classes.formControl}`}
                control={control}
                rules={{ required: true }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="empData.empLastName"
                message={<TextMessage text="Last name required" error />}
              />
            </div>
            <div className="col-md-3 col-4">
              <Controller
                as={<TextField inputProps={{ maxLength: 30 }} />}
                id="empData.empFirstName"
                name="empData.empFirstName"
                defaultValue=""
                label="First Name"
                // InputLabelProps={{
                //   className: classes.leftOffset,
                // }}
                className={`${classes.formControl}`}
                control={control}
                rules={{ required: true }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="empData.empFirstName"
                message={<TextMessage text="First name required" error />}
              />
            </div>
            <div className="col-md-3 col-4">
              <Controller
                as={<TextField inputProps={{ maxLength: 50 }} />}
                id="empData.empHomeOfRecord"
                name="empData.empHomeOfRecord"
                defaultValue=""
                label="Home of Record"
                // InputLabelProps={{
                //   className: classes.leftOffset,
                // }}
                className={`${classes.formControl}`}
                control={control}
                rules={{ required: true }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="empData.empHomeOfRecord"
                message={<TextMessage text="Home of record required" error />}
              />
            </div>
            <OfficeSelector
              ctrlName="empData.empHomeOffice"
              ctrlLabel="Home Office"
              // addLabelClass='leftOffset'
              display="short"
              addClass={`${classes.formControl} col-md-3 col-6`}
              disabled={disabled}
              preset={
                (watchEmpSelected && watchEmpSelected.empHomeOffice) || null
              }
              required={true}
              // criteria={(o: IOffice) => (o.officeType === 'F' || o.officeType === 'H')}
              disableOpts={(o: IOffice) =>
                o.officeType !== "F" && o.officeType !== "H"
              }
            />
            <CallPrefixSelector
              ctrlName="empData.empDialPrefix"
              ctrlLabel="Dial Prefix (Ref)"
              addClass={`${classes.formControl} col-md-2 col-6`}
              disabled={true}
              // preset={
              //   (watchEmpSelected?.empDialPrefix &&
              //     watchEmpSelected.empDialPrefix) ||
              //   (watchEmpHomeOffice && watchEmpHomeOffice)
              // }
              preset={watchEmpSelected?.empDialPrefix}
              controller="empData.empHomeOffice"
            />
            <PhoneBlock
              ctrlName="empData.empPhone"
              ctrlLabel="Employee Phone"
              addClass="col-4"
              parentId={watchEmpSelected?.empId}
              preset={[
                ["Home", watchEmpSelected?.empPhone_1],
                ["Mobile", watchEmpSelected?.empPhone_2],
                ["Pager", watchEmpSelected?.empPhone_3],
                ["Other", watchEmpSelected?.empPhone_4],
              ]}
              disabled={disabled}
            />
            <StreetAddress
              ctrlName="empData.empOverseasAddress"
              ctrlLabel="Overseas Address"
              addClass="col-md-6 col-8"
              parentId={watchEmpSelected?.empId}
              preset={[
                watchEmpSelected?.empOverseasAddress_line_1,
                watchEmpSelected?.empOverseasAddress_line_2,
                watchEmpSelected?.empOverseasAddress_line_3,
                watchEmpSelected?.empOverseasAddress_line_4,
              ]}
              disabled={disabled}
            />
          </div>
          <StaffDependentInputRows
            parentId={watchEmpSelected?.empSSN}
            disabled={disabled}
          />
          <StaffTrainingInputRows
            parentId={watchEmpSelected?.empSSN}
            disabled={disabled}
          />
          <NavButtonBar title="Staff Record Form" addClass="btn-group-sm">
            <NavButton
              type="submit"
              title="Submit"
              dest=""
              isDisabled={!formState.dirty}
            />
            <NavButton
              type="button"
              title="Reset Form"
              dest={`/staff/${formAction}`}
              isDisabled={!formState.dirty}
              action={() => {
                resetEmployee();
              }}
            />
            <NavButton
              type="button"
              title="Cancel"
              action={() => {
                resetEmployee();
              }}
              dest="/staff"
            />
          </NavButtonBar>
        </form>
        <MessageBar {...msg} onClose={hideMessage} />
      </FormContext>
    </FormContainer>
  );
}
