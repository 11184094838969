import { IOffice } from "./interfaces";

export const ofcObjAsStr = (ofcObj: IOffice | string) => {
  // console.log("ofcObj: ", ofcObj);
  if (typeof ofcObj === "string") {
    return ofcObj;
  } else if (ofcObj.officeCode) {
    return ofcObj.officeCode;
  }
  return "";
};

export const convertStrToDate = (value: string) => {
  if (!value) return "";

  if (
    value.match(/\d{4}-\d{2}-\d{2}/) ||
    value.match(/\d{1,2}\/\d{1,2}\/\d{4}/)
  ) {
    return new Date(value).toISOString().split("T")[0];
  }
  return value;
};

const defaultDateStr = "01/01/1900";
// const timeStr = "05:00:00";
export const timeStrTo12Hr = (value: string) => {
  if (!value) return "";

  let valueAsStr: string = value;

  if (valueAsStr.match(/(\d{2}:)+/)) {
    valueAsStr = new Date(
      defaultDateStr + " " + valueAsStr
    ).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
    return valueAsStr;
  }
  return value;
};

export const timeStrTo24Hr = (value: string) => {
  if (!value) return "";
  // console.log("Time: " + value);

  let valueAsStr: string = value.toUpperCase();
  let valueAsArr: string[];

  let hh: string | number;
  let mm: string | number;
  let PM: boolean = false;

  if (valueAsStr.match(/\d+/)) {
    valueAsArr = valueAsStr.split(/([A-Z]+)/, 2);
    valueAsArr?.[1] && valueAsArr[1].match(/P+\w{0,1}/) && (PM = true);
    valueAsArr = valueAsArr[0].trim().split(":", 2);
    hh = (PM ? 12 + parseInt(valueAsArr[0], 10) : parseInt(valueAsArr[0], 10))
      .toString()
      .padStart(2, "0");
    mm = (valueAsArr[1] ? valueAsArr[1] : "00").padStart(2, "0");
    // console.log("HH:mm: " + hh + ":" + mm);

    valueAsStr = new Date(
      defaultDateStr + " " + hh + ":" + mm + ":00"
    ).toLocaleTimeString("en-US", { hour12: false });
    // console.log("valueAsStr: " + valueAsStr);
    return valueAsStr;
  }

  return value;
};

const trueVals: (string | boolean)[] = ["yes", "true", true];
export const boolYesNo = (value: string | boolean) => {
  return trueVals.includes(value) ? "yes" : "no";
};

export const yesNoBool = (value: string | boolean) => {
  return trueVals.includes(value) ? true : false;
};

export const arrIndexerName = (
  name: string,
  ndx: number,
  propertyName: string
) => {
  return `${name}[${ndx}].${propertyName}`;
};
