// import React, { useEffect, useContext } from "react";
import React from "react";
import Nav from "react-bootstrap/Nav";
// import { NavLink, useLocation, RouteComponentProps } from "react-router-dom";
// import { NavLink, useLocation, RouteChildrenProps } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";

// import { AppContext } from '../context';
// import { EmpActionTypes, OfcActionTypes } from '../reducers/action-types';

import NavIcon from './navIcon.component';

type NavItemProps = {
  name: string;
  icon: string;
  dest: string;
}

// type TParams = { action: string };
type TStateProps = { prev: string, isCtxReset: boolean };

// const NavItem = ({ name, icon, dest }: NavItemProps, { ...props }: RouteChildrenProps<TParams, TStateProps>) => {
//   const NavItem = ({ name, icon, dest }: NavItemProps, { ...props }: RouteComponentProps<TParams, any, TStateProps>) => {
const NavItem = ({ name, icon, dest }: NavItemProps) => {
  // const { location } = props;
  // console.log("props: ", props);
  // console.log("location: ", location);
  // console.log("location.state?.isCtxReset: ", location?.state?.isCtxReset);
  // console.log("location.state?.prev: ", location?.state?.prev);
  const location = useLocation<TStateProps>();
  // const { dispatch } = useContext(AppContext);
  // const isContextReset = useRef(false);

  // useEffect(() => {
  //   // console.log("location: ", location);
  //   if (location.state) {
  //     if (location.state.prev !== location.pathname) {
  //       if (!location.state.isCtxReset) {
  //         // console.log("location.state.prev: ", location.state.prev);
  //         // console.log("location.state.isCtxReset: ", location.state.isCtxReset);
  //         console.log("context being reset");
  //         dispatch({
  //           type: EmpActionTypes.GET_EMPLOYEE,
  //           payload: null,
  //         })
  //         dispatch({
  //           type: OfcActionTypes.GET_OFFICE,
  //           payload: null,
  //         })
  //         location.state.isCtxReset = true;
  //       }
  //       // else {
  //       //   console.log("context already reset");
  //       // }
  //     }
  //     else {
  //       // console.log("no need to reset context");
  //       location.state.isCtxReset = false;
  //     }
  //   }
  //   // else {
  //   //   console.log("no location state");
  //   // }
  // }, [location, dispatch])

  return (
    <Nav.Item>
      <NavLink
        to={{
          pathname: dest,
          state: {
            prev: location.pathname,
            isCtxReset: false
          }
        }}
        className="nav-link"
        title={name}
        activeClassName="active"
      >
        <NavIcon icon={icon} /> {name}
      </NavLink>
      <hr />
    </Nav.Item>
  );
};

export default NavItem;
