import React, { useEffect } from "react";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";

import { tripTypes } from "../lookups";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextMessage from "../components/textMessage.component";

import "../assets/sass/elements/travelTypeSelector.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      // minWidth: theme.spacing(15),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type TravelTypeSelectorProps = {
  ctrlName: string;
  ctrlLabel: string;
  addLabelClass?: string;
  addClass?: string;
  variant?: "filled" | "outlined" | "standard" | any;
  defaultValue?: string | undefined;
  required?: boolean;
};

export default function TravelTypeSelector({
  ctrlName,
  ctrlLabel,
  addLabelClass,
  addClass,
  variant,
  defaultValue,
  required,
}: TravelTypeSelectorProps) {
  const classes = useStyles();

  const { control, setValue, errors } = useFormContext();

  // const [travel] = useState<
  //   {
  //     id: number;
  //     travelDesc: string;
  //     travelCode: string;
  //   }[]
  // >([
  //   // { label: "Loading...", value: "" }
  //   { id: 1, travelDesc: "Resolution 6", travelCode: "6" },
  //   { id: 2, travelDesc: "Training", travelCode: "A" },
  //   { id: 3, travelDesc: "Reg. Rest Break", travelCode: "B" },
  //   { id: 4, travelDesc: "Consultation Travel", travelCode: "C" },
  //   { id: 5, travelDesc: "Educational", travelCode: "D" },
  //   { id: 6, travelDesc: "Emergency Visit/Medical", travelCode: "E" },
  //   { id: 7, travelDesc: "Home Leave", travelCode: "H" },
  //   { id: 8, travelDesc: "R & R Leave", travelCode: "R" },
  //   {
  //     id: 9,
  //     travelDesc: "Children of Separated Parents",
  //     travelCode: "S",
  //   },
  //   { id: 10, travelDesc: "Tour Renewal Travel", travelCode: "W" },
  // ]);

  // useEffect(() => {
  //   if (ctrlName && defaultValue) setValue(ctrlName, defaultValue);
  // }, [ctrlName, defaultValue, setValue]);

  return (
    <FormControl
      id="travel-selector"
      className={`${classes.formControl}${addClass ? " " + addClass : ""}`}
    >
      <InputLabel id={`${ctrlName}-label`} className={addLabelClass}>
        {ctrlLabel}
      </InputLabel>
      <Controller
        as={
          <Select>
            {tripTypes.map((type) => (
              <MenuItem key={type.id} value={type.value}>
                <span className="selValue selTravel-selValue">
                  {type.value}
                </span>
                <span className="selLabel selTravel-selLabel">
                  {type.label}
                </span>
              </MenuItem>
            ))}
          </Select>
        }
        // disabled={loading}
        variant={variant}
        id={ctrlName}
        name={ctrlName}
        control={control}
        defaultValue=""
        rules={{ required: required ? required : true }}
      />
      {required && (
        <ErrorMessage
          errors={errors}
          name={ctrlName}
          message={<TextMessage text={ctrlLabel + " required"} error />}
        />
      )}
    </FormControl>
  );
}
