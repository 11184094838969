import React from "react";
import { useFormContext, ErrorMessage } from "react-hook-form";

import { ITourOfDuty, IOffice, IEmployee, IJob } from "../interfaces";
import { arrIndexerName } from "../utilities";
import { Validation } from "../classes";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import OfficeSelector from "./officeSelector.component";
import JobSelector from "./jobSelector.component";
import TextMessage from "./textMessage.component";

import "../assets/sass/elements/staffTourOfDutyItem.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
  })
);

type StaffTourOfDutyItemProps = {
  parentId: string;
  name: string;
  index: number;
  addClass?: string;
  tourItem?: Partial<ITourOfDuty> | null;
  preset?: {
    officeVal: IOffice | null;
    jobsVal: IJob | null;
  };
};

const StaffTourOfDutyItem = ({
  parentId,
  name,
  index,
  addClass,
  tourItem,
  preset,
}: StaffTourOfDutyItemProps) => {
  const classes = useStyles();
  const { register, control, errors, clearError } = useFormContext();

  const arrIndexer = typeof index !== undefined ? `[${index}]` : "";

  return (
    <div className={addClass}>
      <div className="form-row">
        <OfficeSelector
          ctrlName={`${name}${arrIndexer}.empTourOffice`}
          ctrlLabel="Office"
          // addLabelClass="leftOffset"
          addClass={`${classes.formControl} col-lg-3 col-6`}
          display="short"
          preset={
            tourItem ? tourItem.currentOffice : preset ? preset.officeVal : null
          }
          required={true}
        />
        <JobSelector
          ctrlName={`${name}${arrIndexer}.empTourJob`}
          ctrlLabel="Job"
          addLabelClass="leftOffset"
          addClass={`${classes.formControl} col-lg-3 col-6`}
          defaultValue={
            tourItem ? tourItem.currentJob : preset ? preset.jobsVal : null
          }
          required
        />
        <div className="form-row col-lg-6 col-12">
          <div className="col-6">
            <TextField
              type="date"
              name={`${name}${arrIndexer}.empTourStart`}
              id={`${name}${arrIndexer}.empTourStart`}
              label="Tour Start"
              InputLabelProps={{
                shrink: true,
              }}
              className={`${classes.formControl}`}
              inputRef={register({
                required: true,
              })}
              defaultValue={tourItem && tourItem.empTourStart}
              onChange={(e) => {
                clearError(`${name}${arrIndexer}.empTourStart`);
                return e.currentTarget.value;
              }}
            />
          </div>
          <div className="col-6">
            <TextField
              name={`${name}${arrIndexer}.empTourEnd`}
              type="date"
              id={`${name}${arrIndexer}.empTourEnd`}
              label="Tour End"
              InputLabelProps={{
                shrink: true,
              }}
              className={`${classes.formControl}`}
              inputRef={register({
                validate: {
                  greaterThanStart: (value: string) => {
                    const record = control.getValues({ nest: true }) as {
                      empSelected: IEmployee;
                      newItems: Array<ITourOfDuty>;
                    };

                    if (record && !record.newItems) return true;

                    const currTour: Partial<ITourOfDuty> =
                      record && record.newItems ? record.newItems[index] : {};

                    const clear =
                      !currTour.empTourStart || !value
                        ? true
                        : Validation.isBeforeDate(currTour.empTourStart, value);

                    if (clear) return true;

                    return false;
                  },
                },
              })}
              defaultValue={tourItem && tourItem.empTourEnd}
              onChange={(f) => {
                return f.target.value;
              }}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTourStart")}
            message={<TextMessage text="Start date required" error />}
          />
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTourEnd")}
            message={
              <TextMessage
                text="'Tour End' must not precede 'Tour Start'"
                error
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StaffTourOfDutyItem;
