import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import '@fortawesome/fontawesome-free/css/all.css';
// import "@fortawesome/fontawesome-free/js/all.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import "jquery/dist/jquery.slim";
import 'typeface-roboto';

import Container from 'react-bootstrap/Container';

// import { AppProvider } from './context';

import Header from './components/appChrome/appHeader.component';
import SideNav from './components/appChrome/appSideNav.component';
import Footer from './components/appChrome/appFooter.component';
import Home from './views/Home';
import Staff from './views/Staff';
import Offices from './views/Offices';
import OfficeStaffing from './views/OfficeStaffing';
import Reports from './views/Reports';
import StaffRecord from './components/_forms/staffRecord.component';
import StaffTourOfDuty from './components/_forms/staffTourOfDuty.component';
import StaffTravel from './components/_forms/staffTravel.component';
import StaffTDY from './components/_forms/staffTDY.component';
import OfficeRecord from './components/_forms/officeRecord.component';
import ReportFormat1 from './components/_reports/rptFormat1';
import ReportFormat2 from './components/_reports/rptFormat2';
import ReportFormat3 from './components/_reports/rptFormat3';

import watermark from './assets/img/IOD_Seal.png';

export default function App() {
  return (
    // <AppProvider>
    <div className='App'>
      <CssBaseline />
      <Header />
      <div className='App-content'>
        <SideNav />
        <Container className='App-viewarea p-3'>
          <Switch>
            <Route exact path='/home' component={Home} />
            <Route exact path='/staff' component={Staff} />
            <Route
              exact
              path='/staff/tourOfDuty'
              component={StaffTourOfDuty}
            />
            <Route exact path='/staff/travel' component={StaffTravel} />
            <Route exact path='/staff/tdy' component={StaffTDY} />
            <Route path='/staff/:action' component={StaffRecord} />
            <Route exact path='/offices' component={Offices} />
            <Route path='/offices/staffing' component={OfficeStaffing} />
            <Route path='/offices/:action' component={OfficeRecord} />
            <Route
              path='/reports/profile/:action'
              component={ReportFormat1}
            />
            <Route
              path='/reports/preview/:action'
              component={ReportFormat2}
            />
            <Route
              path='/reports/filterSort/:action'
              component={ReportFormat3}
            />
            <Route path='/reports/:pivot/:action' component={ReportFormat3} />
            <Route path='/reports/:action' component={ReportFormat3} />
            <Route exact path='/reports' component={Reports} />
            <Route exact path='/' component={Home} />
          </Switch>
          <div className='watermark'>
            <img className='img-fluid' src={watermark} alt='IOD Logo' />
          </div>
        </Container>
        <div className='watermark-overlay'></div>
      </div>
      <Footer />
    </div>
    // </AppProvider>
  );
}
