import React, { useEffect, useContext } from "react";
import {
  useFormContext,
  Controller,
  useFieldArray,
  ErrorMessage,
} from "react-hook-form";
import { format } from "date-fns";

import { AppContext } from "../context";
import { IEmployee, IDependent, IEmployeeInfo } from "../interfaces";
import { depStations, depTypes } from "../lookups";
import { clientWrapper } from "../api";
import { Dependent, Validation } from "../classes";
import { EmpInfoActionTypes } from "../reducers/action-types";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import SectHeader from "./sectHeader.component";
import NavButtonBar from "./navButtonBar.component";
import NavButton from "./navButton.component";
import TextMessage from "./textMessage.component";

import "../assets/sass/elements/staffDependentInputRows.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    divider: {
      paddingTop: "4px !important",
      borderBottom: "1px inset rgba(52 107 169 / 0.3) !important",
    },
  })
);

type DependentRowsProps = {
  parentId: string | undefined;
  disabled?: boolean;
};

export default function StaffDependentInputRows({
  parentId,
  disabled,
}: DependentRowsProps) {
  const classes = useStyles();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "empDependents",
  });

  // const [curParentId, setCurParentId] = useState<string | undefined>();
  const { state, dispatch } = useContext(AppContext);

  const { watch, register, errors } = useFormContext<{
    empSelected?: IEmployee;
  }>();

  const selectedEmp = watch("empSelected");

  const resetDep = (employeeInfo: IEmployeeInfo) => {
    const { existingItems, deletedItems } = employeeInfo.dependents;
    if (existingItems.length > 0 || deletedItems.length > 0) {
      employeeInfo.dependents.reset();

      dispatch({
        type: EmpInfoActionTypes.SET_EMPLOYEE,
        payload: employeeInfo,
      });

      remove();
    }
  };

  useEffect(() => {
    if (!selectedEmp || !selectedEmp.empSSN) {
      state.employeeInfo && resetDep(state.employeeInfo);
      return;
    }

    async function getDependents(id: string) {
      const client = clientWrapper("aws-us-east-2");
      const dependentsDb = client.database("personnelDependents");

      try {
        const deps = (await dependentsDb
          .query()
          .match({ empSSN: id })
          .send()) as Array<IDependent>;

        if (deps && state.employeeInfo) {
          state.employeeInfo.dependents.existingItems.push(
            ...deps.map((d) => new Dependent(d))
          );
          dispatch({
            type: EmpInfoActionTypes.SET_EMPLOYEE,
            payload: state.employeeInfo,
          });

          const mappedDeps = state.employeeInfo.dependents.existingItems.map(
            (d) => {
              return {
                empDepId: d.empDepId,
                empDepFullName: d.empDepFullName,
                empDepDOB: d.empDepDOB,
                empDepRelationship: d.empDepRelationship,
                empDepStation: d.empDepStation,
              };
            }
          );
          append(mappedDeps);
        }
      } catch (error) {
        throw error;
      }
    }

    state.employeeInfo && resetDep(state.employeeInfo);

    getDependents(selectedEmp.empSSN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmp]);

  return (
    <span className="subform" id="sectDependents">
      <SectHeader title="Dependents" />
      {fields.map((inputField, index) => (
        <div className="form-row" key={`${inputField.id}~${index}`}>
          <input
            type="hidden"
            name={`empDependents[${index}].empDepId`}
            ref={register()}
            defaultValue={
              (fields[index].empDepId && fields[index].empDepId) || ""
            }
          />
          <input
            type="hidden"
            name={`empDependents[${index}].id`}
            ref={register()}
            defaultValue={fields[index].id}
          />
          <input
            type="hidden"
            name={`empDependents[${index}].empSSN`}
            ref={register()}
            defaultValue={selectedEmp && selectedEmp.empSSN}
          />
          <div className="col-lg-4 col-8">
            <Controller
              as={<TextField inputProps={{ maxLength: 60 }} />}
              name={`empDependents[${index}].empDepFullName`}
              id={`empDependents[${index}].empDepFullName`}
              type="text"
              label="Full Name"
              // InputLabelProps={{
              //   className: classes.leftOffset,
              // }}
              className={`${classes.formControl}`}
              defaultValue={
                (fields[index].empDepFullName &&
                  fields[index].empDepFullName) ||
                ""
              }
              // data-order={index}
              rules={{ required: true }}
              control={control}
            />
            <ErrorMessage
              errors={errors}
              name={`empDependents[${index}].empDepFullName`}
              message={
                <TextMessage text="Dependent's name is required" error />
              }
            />
          </div>
          <div className="col-lg-3 col-4">
            <Controller
              as={<TextField />}
              name={`empDependents[${index}].empDepDOB`}
              id={`empDependents[${index}].empDepDOB`}
              type="date"
              label="Date of Birth"
              InputLabelProps={{
                shrink: true,
                // className: classes.leftOffset,
              }}
              className={`${classes.formControl}`}
              defaultValue={
                fields[index].empDepDOB
                  ? format(new Date(fields[index].empDepDOB), "yyyy-MM-dd")
                  : ""
              }
              control={control}
              rules={{
                validate: {
                  lessThanToday: (value: string) => {
                    const today = new Date();
                    const clear = !value
                      ? true
                      : Validation.isBeforeDate(value, today);

                    if (clear) return true;

                    return false;
                  },
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`empDependents[${index}].empDepDOB`}
              message={<TextMessage text="DOB must be in the past" error />}
            />
          </div>
          <FormControl
            id="empDepRelationship-selector"
            className={`${classes.formControl} col-lg-2 col-5`}
          >
            <InputLabel id="empDepRelationship-label" className="leftOffset">
              Relationship
            </InputLabel>
            <Controller
              name={`empDependents[${index}].empDepRelationship`}
              id={`empDependents[${index}].empDepRelationship`}
              as={
                <Select>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {depTypes.map((type) => (
                    <MenuItem key={type.id} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              labelId="empDepRelationship-helper-label"
              defaultValue={
                fields[index].empDepRelationship
                  ? fields[index].empDepRelationship
                  : ""
              }
              control={control}
              rules={{ required: true }}
            />
            <ErrorMessage
              errors={errors}
              name={`empDependents[${index}].empDepRelationship`}
              message={
                <TextMessage
                  text="Relationship to employee is required"
                  error
                />
              }
            />
          </FormControl>
          <FormControl
            id="empDepStation-selector"
            className={`${classes.formControl} col-lg-2 col-6`}
          >
            <InputLabel id="empDepStation-label" className="leftOffset">
              Station
            </InputLabel>
            <Controller
              name={`empDependents[${index}].empDepStation`}
              id={`empDependents[${index}].empDepStation`}
              as={
                <Select>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {depStations.map((station) => (
                    <MenuItem key={station.id} value={station.value}>
                      {station.label}
                    </MenuItem>
                  ))}
                </Select>
              }
              labelId="empDepStation-helper-label"
              defaultValue={
                fields[index].empDepStation ? fields[index].empDepStation : ""
              }
              control={control}
              rules={{
                required: true,
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`empDependents[${index}].empDepStation`}
              message={
                <TextMessage text="Dependent's station is required" error />
              }
            />
          </FormControl>
          <div className="row-control col-1">
            <Tooltip title="Delete Dependent">
              <button
                className="item-btn btn btn-link"
                type="button"
                onClick={() => {
                  if (state.employeeInfo) {
                    const id = fields[index].empDepId;

                    if (id) {
                      state.employeeInfo.dependents.deleteItems([index]);

                      dispatch({
                        type: EmpInfoActionTypes.SET_EMPLOYEE,
                        payload: state.employeeInfo,
                      });
                    }
                  }
                  remove(index);
                }}
              >
                <i
                  className="item-icon fas fa-trash-alt"
                  aria-hidden="true"
                ></i>
              </button>
            </Tooltip>
          </div>
          <hr className={classes.divider} />
        </div>
      ))}
      <div className="form-row">
        <NavButtonBar
          title="Add Dependent"
          addClass="sect-toolbar btn-group-sm col-md-12 text-right"
        >
          <NavButton
            type="button"
            icon="fas fa-plus"
            title="Add Dependent"
            addClass="btn-secondary"
            dest="#"
            action={() => append({})}
            isDisabled={disabled}
          />
        </NavButtonBar>
      </div>
    </span>
  );
}
