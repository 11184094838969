export enum EmpActionTypes {
  GET_EMPLOYEE = "GET_EMPLOYEE",
  ADD_EMPLOYEE = "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
}

export enum OfcActionTypes {
  GET_OFFICE = "GET_OFFICE",
  ADD_OFFICE = "ADD_OFFICE",
  UPDATE_OFFICE = "UPDATE_OFFICE",
}
/* START EXPERIMENTAL LEARNING */

export enum EmpInfoActionTypes {
  INITIALIZE = "INITIALIZE",
  SET_EMPLOYEE = "SET_EMPLOYEE",
}
/* END EXPERIMENTAL LEARNING */

export enum ActionTypes {
  DELETE_TDY_ITEM = "DELETE_ITEM",
  UPDATE_TDY_ITEM = "UPDATE_ITEM",
  ADD_EXISTING_TDY = "ADD_EXISTING_TDY",
  SET_EMPLOYEE = "SET_EMPLOYEE",
  INITIALIZE = "INITIALIZE",
}
