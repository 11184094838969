import React, { useState, useEffect, useCallback } from "react";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";

import { clientWrapper } from "../api";
import { ICourse } from "../interfaces";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import TextMessage from "../components/textMessage.component";

import "../assets/sass/elements/courseSelector.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    popper: {
      minWidth: 350,
    },
  })
);

type CourseSelectorProps = {
  ctrlName: string;
  ctrlLabel: string;
  addClass?: string;
  addLabelClass?: string;
  variant?: "filled" | "outlined" | "standard" | any;
  disabled?: boolean;
  preset?: string | null;
  required?: boolean;
};

const CourseSelector = ({
  ctrlName,
  ctrlLabel,
  addClass,
  addLabelClass,
  variant,
  disabled,
  preset,
  required,
}: CourseSelectorProps) => {
  const classes = useStyles();

  const { control, setValue, errors } = useFormContext();

  const [courses, setCourses] = useState<ICourse[]>([]);
  const [open, setOpen] = useState(false);

  const client = clientWrapper("aws-us-east-2");
  const coursesDb = client.database("courses");

  const loading = open && courses.length === 0;

  // const getCourse = (courseListItem: ICourse): ICourse => {
  //     return {
  //         ...courseListItem,
  //         id: `${courseListItem.courseId}`
  //     } as ICourse;
  // }

  const getCourseObj = useCallback(
    (item: any) => {
      // console.log(item);
      let newObj = {} as ICourse;
      let checkObj = {} as ICourse | undefined;
      if (item) {
        if (!item.courseCode)
          checkObj = courses.find((op: ICourse) => op.courseCode === item);
        if (checkObj?.courseCode) {
          newObj = checkObj;
        } else newObj = item;
      }
      return newObj;
    },
    [courses]
  );

  useEffect(() => {
    let active = true;
    (async () => {
      try {
        // if (open || formState.isSubmitted) {
        const courses = (await coursesDb
          .query()
          .sort((o: ICourse) => o.courseCode)
          .send()) as ICourse[];
        if (courses && active) {
          setCourses(courses);
        }
      } catch (error) {
        console.log(error);
      }
    })();

    // async function getCourses() {
    //     // if (open) {
    //     const url = `https://aws-us-east-2.unbounded.cloud/databases/courses/match`;
    //     const username = 'ldrummondbruce@me.com';
    //     const password = 'LqF8sTtTP6UGKR8';
    //     const headers = new Headers();
    //     headers.append('Authorization', 'Basic ' + btoa(username + ":" + password))
    //     const response = await fetch(url, {
    //         method: 'GET',
    //         headers: headers
    //     });
    //     const body = await response.json();
    //     // console.log(body.results);
    //     if (active) {
    //         setCourses(Object.keys(body.results).map((key) => body.results[key] as ICourse));
    //     }

    //     // } else { setCourses([]); }
    // }
    // getCourses();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlName]);

  useEffect(() => {
    // console.log(ctrlName);
    // console.log(getCourseObj(preset));
    setValue(ctrlName, preset ? getCourseObj(preset) : null);
  }, [setValue, ctrlName, preset, getCourseObj]);

  return (
    <div className={`${addClass ? " " + addClass : ""}`}>
      <Controller
        name={ctrlName}
        as={
          <Autocomplete
            id={ctrlName}
            className={`${classes.formControl}`}
            classes={{ popper: classes.popper }}
            autoHighlight
            autoSelect
            clearOnBlur
            clearOnEscape
            disabled={disabled}
            loading={loading}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={courses}
            getOptionLabel={(option) => {
              const obj = getCourseObj(option);
              return obj.courseCode ? obj.courseCode : "";
            }}
            renderOption={(option) => (
              <React.Fragment key={option.courseCode}>
                <span className="selValue selCourse-selValue">
                  {option.courseCode}
                </span>
                <span className="selLabel selCourse-selLabel">
                  {option.courseTitle}
                </span>
              </React.Fragment>
            )}
            getOptionSelected={(option: ICourse, value: any) => {
              const obj = getCourseObj(value);
              return option.courseCode === (obj ? obj.courseCode : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={ctrlName}
                label={ctrlLabel}
                variant={variant}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        }
        defaultValue={null}
        onChange={([, newValue]) => {
          // console.log(newValue);
          return newValue;
        }}
        control={control}
        rules={{ required: required ? required : true }}
      />
      {required && (
        <ErrorMessage
          errors={errors}
          name={ctrlName}
          message={<TextMessage text={ctrlLabel + " required"} error />}
        />
      )}
    </div>
  );
};

export default CourseSelector;
