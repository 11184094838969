import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import MUIDataTable, {
  FilterType,
  Responsive,
  MUIDataTableMeta,
} from "mui-datatables";

import { ActionTypes } from "../reducers/action-types";
import { CollectionActions } from "../reducers/actions";
import { ITdy, TDYPayload, IEmployeeInfo } from "../interfaces";
import { Validation } from "../classes";
import { arrIndexerName } from "../utilities";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";

import StaffTDYItem from "./staffTDYItem.component";

import "../assets/sass/elements/staffTDYDispGrid.styles.scss";

const useStyles = makeStyles({
  editIcon: {
    fontSize: "1.2rem",
  },
});
type StaffTDYDispGridProps = {
  parentId: string;
  addClass?: string;
  reducer: [IEmployeeInfo, React.Dispatch<CollectionActions<ITdy>>];
};
const getMuiTheme = () =>
  (createMuiTheme as any)({
    overrides: {
      MUIDataTable: {
        paper: {
          backgroundColor: "rgba(243, 243, 243, 1)",
          "@media print": {
            backgroundColor: "white",
            paddingTop: "3cm",
            paddingBottom: "3cm",
          },
        },
      },
      MUIDataTableToolbar: {
        left: {
          display: "inline-flex",
          marginLeft: "-12px",
        },
        actions: {
          "@media print": {
            display: "none",
          },
        },
      },
      MuiTypography: {
        root: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      MUIDataTableViewCol: {
        checkboxRoot: {
          "&&$checked": {
            color: "rgba(1, 65, 137, 1)",
          },
        },
      },
      MuiTableCell: {
        root: {
          padding: "0",
        },
        body: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      MUIDataTableFilter: {
        checkbox: {
          "&&$checked": {
            color: "rgba(1, 65, 137, 1)",
          },
        },
      },
      MUIDataTableSelectCell: {
        root: {
          "@media print": {
            display: "none",
          },
        },
        headerCell: {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
        fixedHeaderCommon: {
          backgroundColor: "transparent",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeaderCommon: {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
      },
      MuiButton: {
        textPrimary: {
          color: "rgba(1, 65, 137, 1)",
          "&:focus": {
            outlineWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          "&:focus": {
            outlineWidth: 0,
          },
        },
      },
    },
  });
export default function StaffTDYDispGrid({
  addClass,
  reducer,
}: StaffTDYDispGridProps) {
  const classes = useStyles();
  const formNames = {
    tdy: "existingItems",
  };
  const DialogStyledButton = withStyles({
    root: {
      color: "#ffffff",
      backgroundColor: "#014189",
      opacity: 1,
      fontWeight: 400,
      fontSize: ".875rem",
      lineHeight: 1.5,
      display: "inline-flex",
      margin: "24px 10px 15px",
      "&:hover": {
        backgroundColor: "#346ba9",
      },
      "&:focus": {
        outlineWidth: 0,
      },
      padding: ".25rem .5rem",
      fontFamily: "inherit",
    },
    label: {
      textTransform: "capitalize",
    },
  })(Button);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (index: number, items?: any[]) => {
    if (items) {
      const tdy = existingItems[index];
      setTravelNdx(index);
      setSelectedItem(tdy);
      setOpen(true);
    }
  };

  const [employee, dispatch] = reducer;

  const { existingItems } = employee.tdyCollection;

  const handleDelete = (u: any) => {
    const rows = u as {
      data: Array<{ index: number; dataIndex: number }>;
      lookup: { [property: number]: boolean };
    };

    const indicies = rows.data.map((d) => d.dataIndex);

    const sorted = indicies.sort();

    employee.tdyCollection.deleteItems(sorted);

    dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employee });
  };

  const handleSave = () => {
    const record = control.getValues({ nest: true }) as TDYPayload;

    const cr = record.existingItems[tdyNdx];

    if (Validation.hasNoValue(cr.empTripOffice)) {
      setError(arrIndexerName("existingItems", tdyNdx, "empTripOffice"), {
        type: "manual",
        message: "Office is required",
      });
      return;
    }
    if (Validation.hasNoValue(cr.empTripStart)) {
      setError(arrIndexerName("existingItems", tdyNdx, "empTripStart"), {
        type: "manual",
        message: "Start date required.",
      });
      return;
    }
    if (
      cr.empTripEnd !== "" &&
      !Validation.isBeforeDate(cr.empTripStart, cr.empTripEnd)
    ) {
      setError(arrIndexerName("existingItems", tdyNdx, "empTripEnd"), {
        type: "manual",
        message: "cannot precede start date.",
      });
      return;
    }

    cr.empTripId = existingItems[tdyNdx].empTripId;

    employee.tdyCollection.updateItem("empTripId", cr);

    dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employee });

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [tdyNdx, setTravelNdx] = useState<number>(0);

  const [selectedItem, setSelectedItem] = useState<ITdy | undefined>(undefined);

  const { control, setError } = useFormContext();

  const columns = [
    {
      name: "empTripOffice",
      label: "Legat",
      options: {},
    },
    {
      name: "USStartDate",
      label: "Start Date",
      options: {},
    },
    {
      name: "USEndDate",
      label: "End Date",
      options: {},
    },
    {
      name: "empTDYDutyIsSimilar",
      label: "Recommended",
      options: {
        setCellHeaderProps: (value: any) => ({
          style: { justifyContent: "center", textAlign: "center" },
        }),
        setCellProps: (value: any) => ({ style: { textAlign: "center" } }),
      },
    },
    {
      name: "empTripCost",
      label: "Cost",
      options: {},
    },
    {
      name: "empTripEAN",
      label: "EAN",
      options: {},
    },
    {
      name: "empTripReason",
      label: "Reason",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
        searchable: true,
        print: true,
      },
    },
    {
      name: "Edit",
      label: "...",
      options: {
        filter: false,
        sort: false,
        print: false,
        customBodyRender: (
          _value: any,
          tableMeta: MUIDataTableMeta,
          updateValue: any
        ) => (
          <React.Fragment>
            <IconButton
              color="primary"
              onClick={() => {
                handleClickOpen(tableMeta.rowIndex, tableMeta.rowData);
              }}
            >
              <Tooltip title="Click to Edit...">
                <EditIcon className={classes.editIcon} />
              </Tooltip>
            </IconButton>
          </React.Fragment>
        ),
        viewColumns: false,
        searchable: false,
        setCellHeaderProps: (value: any) => ({
          style: { justifyContent: "center", textAlign: "center" },
        }),
        setCellProps: (value: any) => ({ style: { textAlign: "center" } }),
      },
    },
  ] as any;
  const options = {
    filterType: "multiselect" as FilterType,
    responsive: "scrollFullHeight" as Responsive,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 100],
  };
  return (
    <React.Fragment>
      <TableContainer
        id="staffTDYGrid"
        component={Paper}
        className={`${addClass}`}
      >
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={
              <Typography variant="h6" id="staffTDYGrid-title" component="div">
                TDY Assignments
              </Typography>
            }
            data={existingItems}
            columns={columns}
            options={{ ...options, onRowsDelete: (rows) => handleDelete(rows) }}
          />
        </MuiThemeProvider>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{ variant: "outlined" }}
        className="border border-primary"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title" className="text-primary">
          Edit TDY
        </DialogTitle>
        <DialogContent>
          <StaffTDYItem
            parentId=""
            name={formNames.tdy}
            index={tdyNdx}
            item={selectedItem}
          />
        </DialogContent>
        <DialogActions>
          <DialogStyledButton onClick={handleClose}>Cancel</DialogStyledButton>
          <DialogStyledButton onClick={handleSave}>Save</DialogStyledButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
