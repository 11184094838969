import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type severity = "success" | "info" | "warning" | "error" | undefined;

export default function MessageBar({
  show,
  message,
  severity,
  onClose,
}: {
  show: boolean;
  message: string;
  severity: severity;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={show} autoHideDuration={5000} onClose={onClose}>
        <Alert onClose={onClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
