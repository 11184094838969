import React from "react";
import Nav from "react-bootstrap/Nav";

import NavItem from "../navLinkItem.component";
import NavDropdown from "../navDropdown.component";

import '../../assets/sass/elements/appSideNav.styles.scss';

function SideNav() {
  return (
    <Nav fill className="App-sidebar flex-column unselectable">
      <NavItem dest="/home" name="Home" icon="fas fa-home" />
      <NavDropdown name="Employees" icon="fas fa-users" grpId="staff">
        <NavItem
          dest="/staff/addRecord"
          // name="Add Staff Record"
          name="Add Employee"
          icon="fas fa-plus"
        />
        <NavItem
          dest="/staff/updRecord"
          // name="Update Staff Record"
          name="Update Employee"
          icon="fas fa-user-edit"
        />
        <NavItem
          dest="/staff/tourOfDuty"
          name="Tours of Duty"
          icon="fas fa-map-marker"
        />
        <NavItem
          dest="/staff/tdy"
          name="TDY Assignments"
          icon="fas fa-map-marker-alt"
        />
        <NavItem
          dest="/staff/travel"
          name="Travel"
          icon="fas fa-globe-americas"
        />
      </NavDropdown>
      <NavDropdown name="Offices" icon="far fa-building" grpId="offices">
        <NavItem
          dest="/offices/addRecord"
          name="Add an Office"
          icon="fas fa-plus"
        />
        <NavItem
          dest="/offices/updRecord"
          name="Update Office Info"
          icon="fas fa-edit"
        />
      </NavDropdown>
      <NavDropdown name="Office Staffing (TBD)" icon="fas fa-users-cog" grpId="staffing">
        <NavItem
          dest="/offices/staffing/hiring"
          name="Hiring (TBD)"
          icon="fas fa-id-badge"
        />
        <NavItem
          dest="/offices/staffing/transfers"
          name="Transfers (TBD)"
          icon="fas fa-people-arrows"
        />
        <NavItem
          dest="/offices/staffing/nsdd38"
          name="NSDD38 (TBD)"
          icon="fas fa-user-tie"
        />
      </NavDropdown>
      <NavDropdown name="Reports" icon="fas fa-database" grpId="reports">
        {/* <NavDropdown name="Employee Pivot" icon="fas fa-layer-group" grpId="rptByStaff"> */}
        <NavDropdown
          name="...by Employee..."
          icon="fas fa-layer-group"
          grpId="rptByStaff">
          <NavItem
            dest="/reports/profile/toursByEmployee"
            name="Tour History/Profile"
            icon="fas fa-file-invoice"
          />
          <NavItem
            dest="/reports/profile/tdyByEmployee"
            name="TDY History/Profile"
            icon="fas fa-file-invoice"
          />
          <NavItem
            dest="/reports/profile/travelByEmployee"
            icon="fas fa-file-invoice"
            name="Travel History/Profile"
          />
        </NavDropdown>
        {/* <NavDropdown name="Office Pivot" icon="fas fa-layer-group" grpId="rptByOffice"> */}
        <NavItem
          dest="/reports/preview/staffDir"
          name="Personnel Directory"
          icon="fas fa-address-book"
        />
        <NavItem
          dest="/reports/preview/officeDir"
          name="Legat/Office Directory"
          icon="fas fa-atlas"
        />
        <NavItem
          dest="/reports/preview/officeProfile"
          name="Legat/Office Profile"
          icon="far fa-list-alt"
        />
        <NavItem
          dest="/reports/preview/staffSnapshot"
          name="Legat Staff Snapshot"
          icon="far fa-list-alt"
        />
        <NavItem
          dest="/reports/preview/bdayList"
          name="Birthday List"
          icon="far fa-list-alt"
        />
        <NavItem
          dest="/reports/preview/staffLvls"
          name="Funded Staff Levels"
          icon="far fa-list-alt"
        />
        <NavItem
          dest="/reports/preview/dependents"
          name="Dependents Summary"
          icon="far fa-list-alt"
        />
        <NavItem
          dest="/reports/preview/homeLeave"
          name="Home Leave Eligibility"
          icon="far fa-list-alt"
        />
        <NavItem
          dest="/reports/filterSort/training"
          name="Training Summary"
          icon="fas fa-th-list"
        />
        <NavItem
          dest="/reports/filterSort/consecutiveTours"
          name="Consecutive Tours"
          icon="fas fa-th-list"
        />
        <NavItem
          dest="/reports/filterSort/toursOfDuty"
          name="Tours of Duty"
          icon="fas fa-th-list"
        />
        <NavItem
          dest="/reports/filterSort/tdy"
          name="TDY Assignments"
          icon="fas fa-th-list"
        />
        {/* </NavDropdown> */}
      </NavDropdown>
      <NavItem icon="far fa-question-circle" name="Help" dest="/help" />
    </Nav>
  );
}

export default SideNav;
