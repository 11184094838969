import Unbounded from "@unbounded/unbounded";

import { isNumber, isString, isArray, isNullOrUndefined } from "util";
import {
  boolYesNo,
  yesNoBool,
  timeStrTo12Hr,
  timeStrTo24Hr,
} from "./utilities";

import {
  IEmployee,
  IOffice,
  IEmployeeInfo,
  ITdy,
  ICollection,
  ITourOfDuty,
  ITravel,
  IJob,
  ICourse,
  IDependent,
  ITraining,
  ISave,
} from "./interfaces";
import { clientWrapper } from "./api";

export class Save<
  T extends object & { [key: string]: any } & { [key in keyof T]: T[key] }
> implements ISave<T> {
  private _client: Unbounded;
  constructor() {
    this._client = clientWrapper("aws-us-east-2");
  }
  async addAction(db: string, objects: Array<T>): Promise<Array<string>> {
    const { inserted } = await this._client.database(db).add(objects);
    return inserted;
  }
  async updateAction(
    db: string,
    key: string | Array<string>,
    data: Array<T>,
    setCallback: (newData: T, oldData: T) => void
  ): Promise<number> {
    try {
      let number = 0;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const match: { [property: string]: any } = {};
        if (isArray(key)) {
          key.forEach((key) => (match[key] = element[key]));
        } else {
          match[key] = element[key];
        }
        const { updated } = (await this._client
          .database(db)
          .update()
          .match(match)
          .set(setCallback)
          .bind(element)
          .single()
          .send()) as { updated: number };

        number += updated;
      }
      return number;
    } catch (error) {
      throw error;
    }
  }
  async deleteAction(db: string, key: string, data: Array<T>): Promise<number> {
    try {
      let number = 0;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const match: { [property: string]: any } = {};
        match[key] = element[key];
        const { updated } = (await this._client
          .database(db)
          .delete()
          .match(match)
          .single()
          .send()) as { updated: number };

        number += updated;
      }
      return number;
    } catch (error) {
      throw error;
    }
  }
}
export abstract class PropertySetter {
  [property: string]: any;
  abstract setValue(property: string, value: any): void;
}

export class Collection<T extends { [key: string]: any }> extends Save<T>
  implements ICollection<T> {
  private _deletedItems: Array<T>;
  private _existingItems: Array<T>;
  private _newItems: Array<T>;
  constructor() {
    super();
    this._deletedItems = [];
    this._existingItems = [];
    this._newItems = [];
  }

  public get deletedItems(): Array<T> {
    return this._deletedItems;
  }
  public get existingItems(): Array<T> {
    return this._existingItems;
  }
  public get newItems(): Array<T> {
    return this._newItems;
  }

  public reset(): void {
    if (this._existingItems.length > 0) {
      this._existingItems.splice(0);
    }
    if (this._newItems.length > 0) {
      this._newItems.splice(0);
    }
    if (this._deletedItems.length > 0) {
      this._deletedItems.splice(0);
    }
  }

  public prepend(items: Array<T>, callback?: (items: Array<T>) => Array<T>) {
    if (this._existingItems.length > 0) {
      const newArray = [...items, ...this._existingItems];

      if (callback) {
        this._existingItems = callback(newArray);

        return;
      }
      this._existingItems = newArray;
      return;
    }

    this._existingItems.push(...items);
  }

  public deleteItems(indices: Array<number>) {
    for (let index = indices.length - 1; index >= 0; index--) {
      const removedTour = this.existingItems.splice(indices[index], 1);

      this.deletedItems.push(...removedTour);
    }
  }

  public updateItem(key: string, item: T) {
    const foundIndex = this._existingItems.findIndex(
      (itm) => itm[key] === item[key]
    );
    if (foundIndex >= 0) {
      for (const property in item) {
        if (key === property) continue;
        if (Object.prototype.hasOwnProperty.call(item, property)) {
          const element = item[property];
          this._existingItems[foundIndex][property] = element;
        }
      }
    }
  }
}

export class BaseClass extends PropertySetter {
  private _updated: boolean;

  constructor() {
    super();
    this._updated = false;
  }
  public setValue(property: string, value: any) {
    this[property] = value;
  }

  public get updated(): boolean {
    return this._updated;
  }
  protected shouldBeUpdated(value: boolean) {
    if (!this._updated) this._updated = value;
  }

  protected formatDate(date: string) {
    if (!date) return "";

    return new Date(date).toISOString().split("T")[0];
  }
  protected convertToUSDate(date: string) {
    if (/\d{4}-\d{2}-\d{2}/.test(date)) {
      const dtArray = date.split("-");
      return `${dtArray[1]}/${dtArray[2]}/${dtArray[0]}`;
    }
    return date;
  }
}

export class Employee extends BaseClass implements IEmployee {
  private _empId: string;
  private _empStatus: string;
  private _empSSN: string;
  private _empDOB: string;
  private _empRoleAorS: string;
  private _empLastName: string;
  private _empFirstName: string;
  private _empHomeOffice: string;
  private _empHomeOfRecord: string;
  private _empOverseasAddress_line_1: string;
  private _empOverseasAddress_line_2: string;
  private _empOverseasAddress_line_3: string;
  private _empOverseasAddress_line_4: string;
  private _empDialPrefix: string;
  private _empPhone_1: string;
  private _empPhone_2: string;
  private _empPhone_3: string;
  private _empPhone_4: string;
  private _empLastFirst: string;
  constructor(employee?: IEmployee) {
    super();

    this._empId = (employee && employee.empId) || "";
    this._empStatus = (employee && employee.empStatus) || "";
    this._empSSN = (employee && employee.empSSN) || "";
    this._empDOB = (employee && employee.empDOB) || "";
    this._empRoleAorS = (employee && employee.empRoleAorS) || "";
    this._empLastName = (employee && employee.empLastName) || "";
    this._empFirstName = (employee && employee.empFirstName) || "";
    this._empHomeOffice = (employee && employee.empHomeOffice) || "";
    this._empHomeOfRecord = (employee && employee.empHomeOfRecord) || "";
    this._empOverseasAddress_line_1 =
      (employee && employee.empOverseasAddress_line_1) || "";
    this._empOverseasAddress_line_2 =
      (employee && employee.empOverseasAddress_line_2) || "";
    this._empOverseasAddress_line_3 =
      (employee && employee.empOverseasAddress_line_3) || "";
    this._empOverseasAddress_line_4 =
      (employee && employee.empOverseasAddress_line_4) || "";
    this._empDialPrefix = (employee && employee.empDialPrefix) || "";
    this._empPhone_1 = (employee && employee.empPhone_1) || "";
    this._empPhone_2 = (employee && employee.empPhone_2) || "";
    this._empPhone_3 = (employee && employee.empPhone_3) || "";
    this._empPhone_4 = (employee && employee.empPhone_4) || "";
    this._empLastFirst = (employee && employee.empLastFirst) || "";
  }

  get empId(): string {
    return this._empId;
  }
  set empId(value: string) {
    this._empId = value;
    super.shouldBeUpdated(true);
  }

  get empStatus(): string {
    return this._empStatus;
  }
  set empStatus(value: string) {
    this._empStatus = value;
    super.shouldBeUpdated(true);
  }

  get empSSN(): string {
    return this._empSSN;
  }
  set empSSN(value: string) {
    this._empSSN = value;
    super.shouldBeUpdated(true);
  }

  get empDOB(): string {
    return super.formatDate(this._empDOB);
  }
  set empDOB(value: string) {
    this._empDOB = value;
    super.shouldBeUpdated(true);
  }

  get empRoleAorS(): string {
    return this._empRoleAorS;
  }
  set empRoleAorS(value: string) {
    this._empRoleAorS = value;
    super.shouldBeUpdated(true);
  }

  get empLastName(): string {
    return this._empLastName;
  }
  set empLastName(value: string) {
    this._empLastName = value;
    super.shouldBeUpdated(true);
  }

  get empFirstName(): string {
    return this._empFirstName;
  }
  set empFirstName(value: string) {
    this._empFirstName = value;
    super.shouldBeUpdated(true);
  }

  get empHomeOffice(): string {
    return this._empHomeOffice;
  }
  set empHomeOffice(value: string) {
    this._empHomeOffice = value;
    super.shouldBeUpdated(true);
  }

  get empHomeOfRecord(): string {
    return this._empHomeOfRecord;
  }
  set empHomeOfRecord(value: string) {
    this._empHomeOfRecord = value;
    super.shouldBeUpdated(true);
  }

  get empOverseasAddress_line_1(): string {
    return this._empOverseasAddress_line_1;
  }
  set empOverseasAddress_line_1(value: string) {
    this._empOverseasAddress_line_1 = value;
    super.shouldBeUpdated(true);
  }

  get empOverseasAddress_line_2(): string {
    return this._empOverseasAddress_line_2;
  }
  set empOverseasAddress_line_2(value: string) {
    this._empOverseasAddress_line_2 = value;
    super.shouldBeUpdated(true);
  }

  get empOverseasAddress_line_3(): string {
    return this._empOverseasAddress_line_3;
  }
  set empOverseasAddress_line_3(value: string) {
    this._empOverseasAddress_line_3 = value;
    super.shouldBeUpdated(true);
  }

  get empOverseasAddress_line_4(): string {
    return this._empOverseasAddress_line_4;
  }
  set empOverseasAddress_line_4(value: string) {
    this._empOverseasAddress_line_4 = value;
    super.shouldBeUpdated(true);
  }

  get empDialPrefix(): string {
    return this._empDialPrefix;
  }
  set empDialPrefix(value: string) {
    this._empDialPrefix = value;
    super.shouldBeUpdated(true);
  }

  get empPhone_1(): string {
    return this._empPhone_1;
  }
  set empPhone_1(value: string) {
    this._empPhone_1 = value;
    super.shouldBeUpdated(true);
  }

  get empPhone_2(): string {
    return this._empPhone_2;
  }
  set empPhone_2(value: string) {
    this._empPhone_2 = value;
    super.shouldBeUpdated(true);
  }

  get empPhone_3(): string {
    return this._empPhone_3;
  }
  set empPhone_3(value: string) {
    this._empPhone_3 = value;
    super.shouldBeUpdated(true);
  }

  get empPhone_4(): string {
    return this._empPhone_4;
  }
  set empPhone_4(value: string) {
    this._empPhone_4 = value;
    super.shouldBeUpdated(true);
  }

  get empLastFirst(): string {
    return this._empLastFirst;
  }
  set empLastFirst(value: string) {
    this._empLastFirst = value;
    super.shouldBeUpdated(true);
  }
}

export class Dependent extends BaseClass implements IDependent {
  private _empSSN: string;
  private _empDepId: string;
  private _empDepFullName: string;
  private _empDepDOB: string;
  private _empDepRelationship: string;
  private _empDepStation: string;
  constructor(dependent?: IDependent) {
    super();

    this._empSSN = (dependent && dependent.empSSN) || "";
    this._empDepId = (dependent && dependent.empDepId) || "";
    this._empDepFullName = (dependent && dependent.empDepFullName) || "";
    this._empDepDOB = (dependent && dependent.empDepDOB) || "";
    this._empDepRelationship =
      (dependent && dependent.empDepRelationship) || "";
    this._empDepStation = (dependent && dependent.empDepStation) || "";
  }

  get empDepId(): string {
    return this._empDepId;
  }
  set empDepId(value: string) {
    this._empDepId = value;
    super.shouldBeUpdated(true);
  }

  get empSSN(): string {
    return this._empSSN;
  }

  get empDepFullName(): string {
    return this._empDepFullName;
  }
  set empDepFullName(value: string) {
    this._empDepFullName = value;
    super.shouldBeUpdated(true);
  }

  get empDepDOB(): string {
    return super.formatDate(this._empDepDOB);
  }
  set empDepDOB(value: string) {
    this._empDepDOB = value;
    super.shouldBeUpdated(true);
  }

  get empDepRelationship(): string {
    return this._empDepRelationship;
  }
  set empDepRelationship(value: string) {
    this._empDepRelationship = value;
    super.shouldBeUpdated(true);
  }

  get empDepStation(): string {
    return this._empDepStation;
  }
  set empDepStation(value: string) {
    this._empDepStation = value;
    super.shouldBeUpdated(true);
  }
}

export class Training extends BaseClass implements ITraining {
  private _empSSN: string;
  private _empTrnId: string;
  private _empTrnOffice: string;
  private _empTrnCourse: string | ICourse | null;
  private _empTrnStart: string;
  private _empTrnEnd: string;
  private _empTrnCost: string | number;
  private _empTrnNote: string;
  constructor(training?: ITraining) {
    super();

    this._empTrnId = (training && training.empTrnId) || "";
    this._empSSN = (training && training.empSSN) || "";
    this._empTrnOffice = (training && training.empTrnOffice) || "";
    this._empTrnCourse = (training && training.empTrnCourse) || null;
    this._empTrnStart = (training && training.empTrnStart) || "";
    this._empTrnEnd = (training && training.empTrnEnd) || "";
    this._empTrnCost = (training && training.empTrnCost) || "";
    this._empTrnNote = (training && training.empTrnNote) || "";
  }

  get empTrnId(): string {
    return this._empTrnId;
  }
  set empTrnId(value: string) {
    this._empTrnId = value;
    super.shouldBeUpdated(true);
  }

  get empSSN(): string {
    return this._empSSN;
  }

  get empTrnOffice(): string {
    return this._empTrnOffice;
  }
  set empTrnOffice(value: string) {
    this._empTrnOffice = value;
    super.shouldBeUpdated(true);
  }

  get empTrnCourse(): string | ICourse | null {
    return this._empTrnCourse;
  }
  set empTrnCourse(value: string | ICourse | null) {
    if (value && !isString(value)) {
      this._empTrnCourse = value.officeCode;
      // return;
    } else {
      this._empTrnCourse = value;
    }
    super.shouldBeUpdated(true);
  }

  get empTrnStart(): string {
    return super.formatDate(this._empTrnStart);
  }
  set empTrnStart(value: string) {
    this._empTrnStart = value;
    super.shouldBeUpdated(true);
  }

  get empTrnEnd(): string {
    return super.formatDate(this._empTrnEnd);
  }
  set empTrnEnd(value: string) {
    this._empTrnEnd = value;
    super.shouldBeUpdated(true);
  }

  get empTrnCost(): string {
    const nf = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return isNumber(this._empTrnCost)
      ? nf.format(this._empTrnCost)
      : this._empTrnCost;
  }
  set empTrnCost(value: string) {
    debugger;
    const c = value.match(/(?<=\$)[\d.,]+/);

    if (c) {
      const matched = c[0].replace(/,/g, "");
      this._empTrnCost = parseFloat(matched);
    }
  }

  get empTrnNote(): string {
    return this._empTrnNote;
  }
  set empTrnNote(value: string) {
    this._empTrnNote = value;
    super.shouldBeUpdated(true);
  }
}

export class TDY extends BaseClass implements ITdy {
  private _id: string;
  private _empSSN: string;
  private _empTripId: string;
  private _empTripOffice: string;
  private _empTripStart: string;
  private _empTripEnd: string;
  private _empTripEAN: string;
  private _empTripType: string;
  private _empTripCost: string | number;
  private _empTDYDutyIsSimilar: string;
  private _empTDYReason: string;
  private _empTDYEval: string;
  private _empTDYEvalDate: string;
  private _currentOffice: IOffice | undefined;
  constructor(tdy: ITdy) {
    super();

    this._id = tdy.id;
    this._empSSN = tdy.empSSN;
    this._empTripId = tdy.empTripId;
    // this._currentOffice = undefined;
    this._empTripOffice = "";
    this.empTripOffice = tdy.empTripOffice;
    this._empTripStart = tdy.empTripStart;
    this._empTripEnd = tdy.empTripEnd;
    this._empTripEAN = tdy.empTripEAN;
    this._empTripType = tdy.empTripType;
    this._empTripCost = tdy.empTripCost;
    this._empTDYDutyIsSimilar = tdy.empTDYDutyIsSimilar;
    this._empTDYReason = tdy.empTDYReason;
    this._empTDYEval = tdy.empTDYEval;
    this._empTDYEvalDate = tdy.empTDYEvalDate;
    this._currentOffice = tdy.currentOffice;
  }

  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }

  get empSSN(): string {
    return this._empSSN;
  }

  get empTripId(): string {
    return this._empTripId;
  }
  set empTripId(value: string) {
    this._empTripId = value;
  }

  get currentOffice(): IOffice | undefined {
    return this._currentOffice;
  }
  set currentOffice(value: IOffice | undefined) {
    this._currentOffice = value;
  }

  get empTripOffice(): string | IOffice {
    return this._empTripOffice;
  }
  set empTripOffice(value: string | IOffice) {
    if (value !== undefined && !isString(value)) {
      this._currentOffice = value;
      this._empTripOffice = value.officeCode;
      super.shouldBeUpdated(true);
      return;
    }
    this._empTripOffice = value;
  }

  get USStartDate(): string {
    return super.convertToUSDate(this._empTripStart);
  }
  get empTripStart(): string {
    return super.formatDate(this._empTripStart);
  }
  set empTripStart(value: string) {
    this._empTripStart = value;
    super.shouldBeUpdated(true);
  }

  get USEndDate(): string {
    return super.convertToUSDate(this._empTripEnd);
  }
  get empTripEnd(): string {
    return super.formatDate(this._empTripEnd);
  }
  set empTripEnd(value: string) {
    this._empTripEnd = value;
    super.shouldBeUpdated(true);
  }

  get empTripEAN(): string {
    return this._empTripEAN;
  }
  set empTripEAN(value: string) {
    this._empTripEAN = value;
    super.shouldBeUpdated(true);
  }

  get empTripType(): string {
    return this._empTripType;
  }
  set empTripType(value: string) {
    this._empTripType = value;
  }

  get empTripCost(): string | number {
    const nf = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return isNumber(this._empTripCost)
      ? nf.format(this._empTripCost)
      : this._empTripCost;
  }
  set empTripCost(value: string | number) {
    if (isString(value)) {
      const c = value.match(/(?<=\$)[\d.,]+/);

      if (c) {
        const matched = c[0].replace(/,/g, "");
        this._empTripCost = parseFloat(matched);
        super.shouldBeUpdated(true);
      }

      return;
    }

    this._empTripCost = value;
    super.shouldBeUpdated(true);
  }

  get empTDYDutyIsSimilar(): string {
    return this._empTDYDutyIsSimilar;
  }
  set empTDYDutyIsSimilar(value: string) {
    this._empTDYDutyIsSimilar = value;
    super.shouldBeUpdated(true);
  }

  get empTDYReason(): string {
    return this._empTDYReason;
  }
  set empTDYReason(value: string) {
    this._empTDYReason = value;
    super.shouldBeUpdated(true);
  }

  get empTDYEval(): string {
    return this._empTDYEval;
  }
  set empTDYEval(value: string) {
    this._empTDYEval = value;
    super.shouldBeUpdated(true);
  }

  get empTDYEvalDate(): string {
    return super.formatDate(this._empTDYEvalDate);
  }
  set empTDYEvalDate(value: string) {
    this._empTDYEvalDate = value;
    super.shouldBeUpdated(true);
  }

  public get NewSave() {
    return {
      empSSN: this.empSSN,
      empTripOffice: this.empTripOffice,
      empTripStart: this.empTripStart,
      empTripEnd: this.empTripEnd,
      empTripEAN: this.empTripEAN,
      empTripType: this.empTripType,
      empTripCost: this.empTripCost,
    };
  }

  public get DataToSave() {
    return {
      empSSN: this.empSSN,
      empTripId: this.empTripId,
      empTripOffice: this._empTripOffice,
      empTripStart: this.empTripStart,
      empTripEnd: this.empTripEnd,
      empTripEAN: this.empTripEAN,
      empTripCost: this.empTripCost,
      empTDYDutyIsSimilar: this.empTDYDutyIsSimilar,
      empTDYReason: this.empTDYReason,
      empTDYEval: this.empTDYEval,
      empTDYEvalDate: this.empTDYEvalDate,
    };
  }
}

export class TourOfDuty extends BaseClass implements ITourOfDuty {
  private _id: string;
  private _empSSN: string;
  private _empTourId: string;
  private _empTourStart: string;
  private _empTourEnd: string;
  private _empTourOffice: string;
  private _empTourJob: string;
  private _currentOffice: IOffice | undefined;
  private _currentJob: IJob | undefined;

  constructor(tour: ITourOfDuty) {
    super();

    this._id = tour.id;
    this._empTourId = tour.empTourId;
    this._empSSN = tour.empSSN;
    this._empTourOffice = !tour.empTourOffice
      ? ""
      : isString(tour.empTourOffice)
      ? tour.empTourOffice
      : tour.empTourOffice.officeCode;
    this._empTourStart = tour.empTourStart;
    this._empTourEnd = tour.empTourEnd;
    this._empTourJob = !tour.empTourJob
      ? ""
      : isString(tour.empTourJob)
      ? tour.empTourJob
      : tour.empTourJob.jobCode;
    this._currentOffice = tour.currentOffice;
    this._currentJob = tour.currentJob;
  }

  get id(): string {
    return this._id;
  }

  get empTourId(): string {
    return this._empTourId;
  }
  set empTourId(value: string) {
    this._empTourId = value;
  }

  get empSSN(): string {
    return this._empSSN;
  }

  get currentOffice(): IOffice | undefined {
    return this._currentOffice;
  }
  set currentOffice(value: IOffice | undefined) {
    this._currentOffice = value;
  }

  get empTourOffice(): string | IOffice {
    return this._empTourOffice;
  }
  set empTourOffice(value: string | IOffice) {
    if (value && !isString(value)) {
      this._currentOffice = value;
      this._empTourOffice = value.officeCode;
      super.shouldBeUpdated(true);
      return;
    }

    this._empTourOffice = value;
  }

  get USStartDate(): string {
    return this.convertToUSDate(this._empTourStart);
  }

  get empTourStart(): string {
    return this.formatDate(this._empTourStart);
  }
  set empTourStart(value: string) {
    this._empTourStart = value;
    super.shouldBeUpdated(true);
  }

  get USEndDate(): string {
    return this.convertToUSDate(this._empTourEnd);
  }

  get empTourEnd(): string {
    return this.formatDate(this._empTourEnd);
  }
  set empTourEnd(value: string) {
    this._empTourEnd = value;
    super.shouldBeUpdated(true);
  }

  get currentJob(): IJob | undefined {
    return this._currentJob;
  }
  set currentJob(value: IJob | undefined) {
    this._currentJob = value;
  }

  get empTourJob(): string | IJob {
    return this._empTourJob;
  }
  set empTourJob(value: string | IJob) {
    if (value && !isString(value)) {
      this._currentJob = value;
      this._empTourJob = value.jobCode;
      super.shouldBeUpdated(true);
      return;
    }

    this._empTourJob = value;
  }

  get NewSave() {
    return {
      empSSN: this._empSSN,
      empTourOffice: this._empTourOffice,
      empTourStart: this._empTourStart,
      empTourEnd: this._empTourEnd,
      empTourJob: this._empTourJob,
      empTourId: this._empTourId,
    };
  }

  get DataToSave() {
    return {
      empSSN: this._empSSN,
      empTourOffice: this._empTourOffice,
      empTourStart: this._empTourStart,
      empTourEnd: this._empTourEnd,
      empTourJob: this._empTourJob,
      empTourId: this._empTourId,
      id: this._empTourId,
    };
  }
}

export class Travel extends BaseClass implements ITravel {
  private _id: string;
  private _empTripId: string;
  private _empTripOffice: string;
  private _empSSN: string;
  private _empTripStart: string;
  private _empTripEnd: string;
  private _empTripEAN: string;
  private _empTripType: string;
  private _empTripCost: string | number;
  private _empTripCostIsActual: string;
  private _empTripNotes: string;
  // private _empTDYEval: string;
  // private _empTDYEvalDate: string;
  // private _empTDYReason: string;
  private _currentOffice: IOffice | undefined;
  constructor(travel: ITravel) {
    super();

    this._empSSN = travel.empSSN;
    this._id = travel.id;
    this._empTripId = travel.empTripId;
    this._empTripOffice = "";
    this._currentOffice = undefined;
    this.empTripOffice = travel.empTripOffice;
    this._empTripStart = travel.empTripStart;
    this._empTripEnd = travel.empTripEnd;
    this._empTripEAN = travel.empTripEAN;
    this._empTripType = travel.empTripType;
    this._empTripCost = travel.empTripCost;
    this._empTripCostIsActual = travel.empTripCostIsActual;
    this._empTripNotes = travel.empTripNotes;
    // this._empTDYDutyIsSimilar = travel.empTDYDutyIsSimilar;
    // this._empTDYReason = travel.empTDYReason;
    // this._empTDYEval = travel.empTDYEval;
    // this._empTDYEvalDate = travel.empTDYEvalDate;
    this._currentJob = travel.currentJob;
    this._currentOffice = travel.currentOffice;
  }

  get empSSN(): string {
    return this._empSSN;
  }

  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }

  get empTripId(): string {
    return this._empTripId;
  }
  set empTripId(value: string) {
    this._empTripId = value;
  }

  get currentOffice(): IOffice | undefined {
    return this._currentOffice;
  }
  set currentOffice(value: IOffice | undefined) {
    this._currentOffice = value;
  }

  get empTripOffice(): string | IOffice {
    return this._empTripOffice;
  }
  set empTripOffice(value: string | IOffice) {
    if (!isString(value)) {
      this._currentOffice = value;
      this._empTripOffice = value.officeCode;
      super.shouldBeUpdated(true);
      return;
    }

    this._empTripOffice = value;
  }

  get USStartDate(): string {
    return super.convertToUSDate(this._empTripStart);
  }

  get empTripStart(): string {
    return super.formatDate(this._empTripStart);
  }
  set empTripStart(value: string) {
    this._empTripStart = value;
    super.shouldBeUpdated(true);
  }

  get USEndDate(): string {
    return super.convertToUSDate(this._empTripEnd);
  }

  get empTripEnd(): string {
    return super.formatDate(this._empTripEnd);
  }
  set empTripEnd(value: string) {
    this._empTripEnd = value;
    super.shouldBeUpdated(true);
  }

  get empTripEAN(): string {
    return this._empTripEAN;
  }
  set empTripEAN(value: string) {
    this._empTripEAN = value;
    super.shouldBeUpdated(true);
  }

  get empTripType(): string {
    return this._empTripType;
  }
  set empTripType(value: string) {
    this._empTripType = value;
  }

  get empTripCost(): string | number {
    const nf = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return isNumber(this._empTripCost)
      ? nf.format(this._empTripCost)
      : this._empTripCost;
  }
  set empTripCost(value: string | number) {
    if (isString(value)) {
      const c = value.match(/(?<=\$)[\d.,]+/);

      if (c) {
        const matched = c[0].replace(/,/g, "");
        this._empTripCost = parseFloat(matched);
        super.shouldBeUpdated(true);
      }

      return;
    }

    this._empTripCost = value;
    super.shouldBeUpdated(true);
  }

  // get empTripCostIsActual(): string {
  //   return this._empTripCostIsActual;
  // }
  get empTripCostIsActual(): string {
    if (!this._empTripCostIsActual) return "";

    const lowerActual = this._empTripCostIsActual.toLowerCase();

    switch (lowerActual) {
      case "yes":
        return "Actual";
      case "no":
        return "Estimate";
      default:
        return "";
    }
  }
  set empTripCostIsActual(value: string) {
    this._empTripCostIsActual =
      value === "Actual" ? "yes" : value === "Estimate" ? "no" : "";
  }

  get empTripNotes(): string {
    return this._empTripNotes;
  }

  set empTripNotes(value: string) {
    this._empTripNotes = value;
  }

  // get empTDYDutyIsSimilar(): string {
  //   return this._empTDYDutyIsSimilar;
  // }
  // set empTDYDutyIsSimilar(value: string) {
  //   this._empTDYDutyIsSimilar = value;
  //   super.shouldBeUpdated(true);
  // }

  // get empTDYReason(): string {
  //   return this._empTDYReason;
  // }
  // set empTDYReason(value: string) {
  //   this._empTDYReason = value;
  //   super.shouldBeUpdated(true);
  // }

  // get empTDYEval(): string {
  //   return this._empTDYEval;
  // }
  // set empTDYEval(value: string) {
  //   this._empTDYEval = value;
  //   super.shouldBeUpdated(true);
  // }

  // get empTDYEvalDate(): string {
  //   return super.formatDate(this._empTDYEvalDate);
  // }
  // set empTDYEvalDate(value: string) {
  //   this._empTDYEvalDate = value;
  //   super.shouldBeUpdated(true);
  // }

  public get NewSave() {
    return {
      empSSN: this.empSSN,
      empTripOffice: this.empTripOffice,
      empTripStart: this.empTripStart,
      empTripEnd: this.empTripEnd,
      empTripEAN: this.empTripEAN,
      empTripType: this.empTripType,
      empTripCost: this.empTripCost,
    };
  }
  public get DataToSave() {
    return {
      empSSN: this._empSSN,
      empTripId: this.empTripId,
      empTripOffice: this._empTripOffice,
      empTripStart: this.empTripStart,
      empTripEnd: this.empTripEnd,
      empTripEAN: this.empTripEAN,
      empTripType: this.empTripType,
      empTripCost: this.empTripCost,
      empTripCostIsActual: this.empTripCostIsActual,
      empTripNotes: this.empTripNotes,
    };
  }
}

export class EmployeeInfo extends Employee implements IEmployeeInfo {
  [property: string]: any;
  private _tdyCollection: ICollection<ITdy>;
  private _toursOfDuty: ICollection<ITourOfDuty>;
  private _travelCollection: ICollection<ITravel>;
  private _dependents: ICollection<IDependent>;
  private _trainingCollection: ICollection<ITraining>;
  constructor(employeeData?: IEmployee) {
    super(employeeData);

    this._tdyCollection = this.createCollection();
    this._toursOfDuty = this.createCollection();
    this._travelCollection = this.createCollection();
    this._dependents = this.createCollection();
    this._trainingCollection = this.createCollection();
  }

  public get tdyCollection(): ICollection<ITdy> {
    return this._tdyCollection;
  }

  public get toursOfDuty(): ICollection<ITourOfDuty> {
    return this._toursOfDuty;
  }
  public get travelCollection(): ICollection<ITravel> {
    return this._travelCollection;
  }

  public get dependents(): ICollection<IDependent> {
    return this._dependents;
  }

  public get trainingCollection(): ICollection<ITraining> {
    return this._trainingCollection;
  }

  private createCollection<T>(): ICollection<T> {
    return new Collection<T>();
  }
}

export class Office extends BaseClass implements IOffice {
  private _officeId: string;
  private _officeCode: string;
  private _officeName: string;
  private _isExpansion: string | boolean;
  private _officeHQ: string;
  private _officeCountry: string;
  private _officeOpenedDate: string;
  private _officeClosedDate: string;
  private _officeType: string;
  private _officeAgentFSL: number;
  private _officeSupportFSL: number;
  private _officeDaySchedule: string;
  private _officeHrsStart: string;
  private _officeHrsEnd: string;
  private _officeHrsOffset: number;
  private _officeMailingAddress_line_1: string;
  private _officeMailingAddress_line_2: string;
  private _officeMailingAddress_line_3: string;
  private _officeMailingAddress_line_4: string;
  private _officeStreetAddress_line_1: string;
  private _officeStreetAddress_line_2: string;
  private _officeStreetAddress_line_3: string;
  private _officeStreetAddress_line_4: string;
  private _officeDialPrefix: string;
  private _officeDirectDial1: string;
  private _officeDirectDial2: string;
  private _embassyLine1: string;
  private _embassyLine2: string;
  private _officeSpeedDial: string;
  private _officeVIGIT: string;
  private _officeCell: string;
  private _officeSecureVoice: string;
  private _officeSecureData: string;
  private _officeUnclassFax: string;
  private _officeSecureFax: string;
  private _officeDirectoryNote: string;
  constructor(office: IOffice) {
    super();

    this._officeId = office.officeId;
    this._officeCode = office.officeCode;
    this._officeName = office.officeName;
    this._isExpansion = office.isExpansion;
    this._officeHQ = office.officeHQ;
    this._officeCountry = office.officeCountry;
    this._officeOpenedDate = office.officeOpenedDate;
    this._officeClosedDate = office.officeClosedDate;
    this._officeType = office.officeType;
    this._officeAgentFSL = office.officeAgentFSL;
    this._officeSupportFSL = office.officeSupportFSL;
    this._officeDaySchedule = office.officeDaySchedule;
    this._officeHrsStart = office.officeHrsStart;
    this._officeHrsEnd = office.officeHrsEnd;
    this._officeHrsOffset = office.officeHrsOffset;
    this._officeMailingAddress_line_1 = office.officeMailingAddress_line_1;
    this._officeMailingAddress_line_2 = office.officeMailingAddress_line_2;
    this._officeMailingAddress_line_3 = office.officeMailingAddress_line_3;
    this._officeMailingAddress_line_4 = office.officeMailingAddress_line_4;
    this._officeStreetAddress_line_1 = office.officeStreetAddress_line_1;
    this._officeStreetAddress_line_2 = office.officeStreetAddress_line_2;
    this._officeStreetAddress_line_3 = office.officeStreetAddress_line_3;
    this._officeStreetAddress_line_4 = office.officeStreetAddress_line_4;
    this._officeDialPrefix = office.officeDialPrefix;
    this._officeDirectDial1 = office.officeDirectDial1;
    this._officeDirectDial2 = office.officeDirectDial2;
    this._embassyLine1 = office.embassyLine1;
    this._embassyLine2 = office.embassyLine2;
    this._officeSpeedDial = office.officeSpeedDial;
    this._officeVIGIT = office.officeVIGIT;
    this._officeCell = office.officeCell;
    this._officeSecureVoice = office.officeSecureVoice;
    this._officeSecureData = office.officeSecureData;
    this._officeUnclassFax = office.officeUnclassFax;
    this._officeSecureFax = office.officeSecureFax;
    this._officeDirectoryNote = office.officeDirectoryNote;
  }

  get officeId(): string {
    return this._officeId;
  }
  set officeId(value: string) {
    this._officeId = value;
  }

  get officeCode(): string {
    return this._officeCode;
  }
  set officeCode(value: string) {
    this._officeCode = value;
    super.shouldBeUpdated(true);
  }

  get officeName(): string {
    return this._officeName;
  }
  set officeName(value: string) {
    this._officeName = value;
    super.shouldBeUpdated(true);
  }

  get isExpansion(): string | boolean {
    return yesNoBool(this._isExpansion);
  }
  set isExpansion(value: string | boolean) {
    this._isExpansion = boolYesNo(value);
    super.shouldBeUpdated(true);
  }

  get officeHQ(): string {
    return this._officeHQ;
  }
  set officeHQ(value: string) {
    this._officeHQ = value;
    super.shouldBeUpdated(true);
  }

  get officeCountry(): string {
    return this._officeCountry;
  }
  set officeCountry(value: string) {
    this._officeCountry = value;
    super.shouldBeUpdated(true);
  }

  get officeType(): string {
    return this._officeType;
  }
  set officeType(value: string) {
    this._officeType = value;
    super.shouldBeUpdated(true);
  }

  get officeOpenedDate(): string {
    return super.formatDate(this._officeOpenedDate);
  }
  set officeOpenedDate(value: string) {
    this._officeOpenedDate = value;
    super.shouldBeUpdated(true);
  }

  get officeClosedDate(): string {
    return super.formatDate(this._officeClosedDate);
  }
  set officeClosedDate(value: string) {
    this._officeClosedDate = value;
    super.shouldBeUpdated(true);
  }

  get officeAgentFSL(): number {
    return this._officeAgentFSL;
  }
  set officeAgentFSL(value: number) {
    this._officeAgentFSL = value;
    super.shouldBeUpdated(true);
  }

  get officeSupportFSL(): number {
    return this._officeSupportFSL;
  }
  set officeSupportFSL(value: number) {
    this._officeSupportFSL = value;
    super.shouldBeUpdated(true);
  }

  get officeDaySchedule(): string {
    return this._officeDaySchedule;
  }
  set officeDaySchedule(value: string) {
    this._officeDaySchedule = value;
    super.shouldBeUpdated(true);
  }

  get officeHrsStart(): string {
    return timeStrTo24Hr(this._officeHrsStart);
  }
  set officeHrsStart(value: string) {
    this._officeHrsStart = timeStrTo12Hr(value);
    super.shouldBeUpdated(true);
  }

  get officeHrsEnd(): string {
    return timeStrTo24Hr(this._officeHrsEnd);
  }
  set officeHrsEnd(value: string) {
    this._officeHrsEnd = timeStrTo12Hr(value);
    super.shouldBeUpdated(true);
  }

  get officeHrsOffset(): number {
    return this._officeHrsOffset;
  }
  set officeHrsOffset(value: number) {
    this._officeHrsOffset = value;
    super.shouldBeUpdated(true);
  }

  get officeMailingAddress_line_1(): string {
    return this._officeMailingAddress_line_1;
  }
  set officeMailingAddress_line_1(value: string) {
    this._officeMailingAddress_line_1 = value;
    super.shouldBeUpdated(true);
  }

  get officeMailingAddress_line_2(): string {
    return this._officeMailingAddress_line_2;
  }
  set officeMailingAddress_line_2(value: string) {
    this._officeMailingAddress_line_2 = value;
    super.shouldBeUpdated(true);
  }

  get officeMailingAddress_line_3(): string {
    return this._officeMailingAddress_line_3;
  }
  set officeMailingAddress_line_3(value: string) {
    this._officeMailingAddress_line_3 = value;
    super.shouldBeUpdated(true);
  }

  get officeMailingAddress_line_4(): string {
    return this._officeMailingAddress_line_4;
  }
  set officeMailingAddress_line_4(value: string) {
    this._officeMailingAddress_line_4 = value;
    super.shouldBeUpdated(true);
  }

  get officeStreetAddress_line_1(): string {
    return this._officeStreetAddress_line_1;
  }
  set officeStreetAddress_line_1(value: string) {
    this._officeStreetAddress_line_1 = value;
    super.shouldBeUpdated(true);
  }

  get officeStreetAddress_line_2(): string {
    return this._officeStreetAddress_line_2;
  }
  set officeStreetAddress_line_2(value: string) {
    this._officeStreetAddress_line_2 = value;
    super.shouldBeUpdated(true);
  }

  get officeStreetAddress_line_3(): string {
    return this._officeStreetAddress_line_3;
  }
  set officeStreetAddress_line_3(value: string) {
    this._officeStreetAddress_line_3 = value;
    super.shouldBeUpdated(true);
  }

  get officeStreetAddress_line_4(): string {
    return this._officeStreetAddress_line_4;
  }
  set officeStreetAddress_line_4(value: string) {
    this._officeStreetAddress_line_4 = value;
    super.shouldBeUpdated(true);
  }

  get officeDialPrefix(): string {
    return this._officeDialPrefix;
  }
  set officeDialPrefix(value: string) {
    this._officeDialPrefix = value;
    super.shouldBeUpdated(true);
  }

  get officeDirectDial1(): string {
    return this._officeDirectDial1;
  }
  set officeDirectDial1(value: string) {
    this._officeDirectDial1 = value;
    super.shouldBeUpdated(true);
  }

  get officeDirectDial2(): string {
    return this._officeDirectDial2;
  }
  set officeDirectDial2(value: string) {
    this._officeDirectDial2 = value;
    super.shouldBeUpdated(true);
  }

  get embassyLine1(): string {
    return this._embassyLine1;
  }
  set embassyLine1(value: string) {
    this._embassyLine1 = value;
    super.shouldBeUpdated(true);
  }

  get embassyLine2(): string {
    return this._embassyLine2;
  }
  set embassyLine2(value: string) {
    this._embassyLine2 = value;
    super.shouldBeUpdated(true);
  }

  get officeSpeedDial(): string {
    return this._officeSpeedDial;
  }
  set officeSpeedDial(value: string) {
    this._officeSpeedDial = value;
    super.shouldBeUpdated(true);
  }

  get officeVIGIT(): string {
    return this._officeVIGIT;
  }
  set officeVIGIT(value: string) {
    this._officeVIGIT = value;
    super.shouldBeUpdated(true);
  }

  get officeCell(): string {
    return this._officeCell;
  }
  set officeCell(value: string) {
    this._officeCell = value;
    super.shouldBeUpdated(true);
  }

  get officeSecureVoice(): string {
    return this._officeSecureVoice;
  }
  set officeSecureVoice(value: string) {
    this._officeSecureVoice = value;
    super.shouldBeUpdated(true);
  }

  get officeSecureData(): string {
    return this._officeSecureData;
  }
  set officeSecureData(value: string) {
    this._officeSecureData = value;
    super.shouldBeUpdated(true);
  }

  get officeUnclassFax(): string {
    return this._officeUnclassFax;
  }
  set officeUnclassFax(value: string) {
    this._officeUnclassFax = value;
    super.shouldBeUpdated(true);
  }

  get officeSecureFax(): string {
    return this._officeSecureFax;
  }
  set officeSecureFax(value: string) {
    this._officeSecureFax = value;
    super.shouldBeUpdated(true);
  }

  get officeDirectoryNote(): string {
    return this._officeDirectoryNote;
  }
  set officeDirectoryNote(value: string) {
    this._officeDirectoryNote = value;
    super.shouldBeUpdated(true);
  }
}

export class Validation {
  static hasNoValue(value: any) {
    return isNullOrUndefined(value) || (isString(value) && value === "");
  }

  static isBeforeDate(startValue: string | Date, endValue: string | Date) {
    let tempStartDate: Date;
    let tempEndDate: Date;
    if (!startValue || !endValue) {
      throw new Error("Missing parameters provided.");
    }
    if (isString(startValue)) {
      try {
        tempStartDate = new Date(startValue);
      } catch (error) {
        throw error;
      }
    } else {
      tempStartDate = startValue;
    }
    if (isString(endValue)) {
      try {
        tempEndDate = new Date(endValue);
      } catch (error) {
        throw error;
      }
    } else {
      tempEndDate = endValue;
    }

    return tempEndDate >= tempStartDate;
  }
}
