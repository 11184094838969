import { useState, useEffect } from "react";
import Unbounded from "@unbounded/unbounded";

const userName = "ldrummondbruce@me.com";
const passWord = "LqF8sTtTP6UGKR8";

function useFetch<T>(uri: string, options?: RequestInit) {
  const [data, setData] = useState<T>();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!uri) return;
    let unmounting = false;

    const wrapper = async () => {
      try {
        const response = await fetch(
          uri,
          options || {
            headers: {
              Authorization: `Basic ${btoa(`${userName}:${passWord}`)}`,
            },
          }
        );

        if (response.status < 200 || response.status >= 400) throw response;

        const data = await response.json();

        setData(data.results || data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (!unmounting) wrapper();

    return () => {
      unmounting = true;
    };
  }, [uri, options]);

  return {
    loading,
    data,
    error,
  };
}

function clientWrapper(region: string) {
  let client: Unbounded = new Unbounded(region, userName, passWord);
  return client;
}

async function fetchAPI<T>(
  uri: string,
  options?: RequestInit,
  callback?: () => void
) {
  try {
    if (options && !options.headers) {
      options.headers = {
        Authorization: `Basic ${btoa(`${userName}:${passWord}`)}`,
        "Content-Type": "application/json",
      };
    }

    if (options && options.body) {
      options.method = "POST";
    }

    const response = await fetch(
      uri,
      options || {
        headers: {
          Authorization: `Basic ${btoa(`${userName}:${passWord}`)}`,
        },
      }
    );

    if (response.status < 200 || response.status >= 400) throw response;

    const data = await response.json();

    return (data.results || data) as T;
  } catch (error) {
    throw error;
  }
}
export { useFetch, fetchAPI, clientWrapper };
