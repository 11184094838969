import React from "react";
import { useFormContext, ErrorMessage } from "react-hook-form";
import NumberFormat from "react-number-format";

import { ITdy, IOffice, IEmployee } from "../interfaces";
import { arrIndexerName } from "../utilities";
import { Validation } from "../classes";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import OfficeSelector from "./officeSelector.component";
import TextMessage from "./textMessage.component";

import "../assets/sass/elements/staffTDYItem.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
    topOffset: {
      marginTop: theme.spacing(0.75),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type StaffTDYItemProps = {
  parentId: string;
  name: string;
  index: number;
  addClass?: string;
  item?: Partial<ITdy>;
  preset?: IOffice | string | null;
};
const StaffTDYItem = ({
  parentId,
  name,
  index,
  addClass,
  item,
  preset,
}: StaffTDYItemProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const { register, errors, control } = useFormContext();

  const arrIndexer = typeof index !== undefined ? `[${index}]` : "";

  return (
    <div className={addClass}>
      <div className="form-row">
        <OfficeSelector
          ctrlName={`${name}${arrIndexer}.empTripOffice`}
          ctrlLabel="Legat"
          // addLabelClass="leftOffset"
          addClass={`${classes.formControl} col-xl-2 col-3`}
          display="short"
          preset={item ? item.currentOffice : preset ? preset : null}
          required
        />
        <div className="form-row col-xl-5 col-9">
          <div className="col-6">
            <TextField
              type="date"
              name={`${name}${arrIndexer}.empTripStart`}
              id={`${name}${arrIndexer}.empTripStart`}
              label="TDY Start"
              InputLabelProps={{
                shrink: true,
              }}
              className={`${classes.formControl}`}
              inputRef={register({ required: true })}
              defaultValue={item && item.empTripStart}
            />
          </div>
          <div className="col-6">
            <TextField
              type="date"
              name={`${name}${arrIndexer}.empTripEnd`}
              id={`${name}${arrIndexer}.empTripEnd`}
              label="TDY End"
              InputLabelProps={{
                shrink: true,
              }}
              className={`${classes.formControl}`}
              inputRef={register({
                validate: {
                  greaterThanStart: (value: string) => {
                    const record = control.getValues({ nest: true }) as {
                      empSelected: IEmployee;
                      newItems: Array<ITdy>;
                    };

                    if (record && !record.newItems) return true;

                    const currTour: Partial<ITdy> =
                      record && record.newItems ? record.newItems[index] : {};

                    const clear =
                      !currTour.empTripStart || !value
                        ? true
                        : Validation.isBeforeDate(currTour.empTripStart, value);

                    if (clear) return true;

                    return false;
                  },
                },
              })}
              defaultValue={item && item.empTripEnd}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTripStart")}
            message={<TextMessage text="Start date required" error />}
          />
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTripEnd")}
            message={
              <TextMessage
                text="TDY End must not precede TDY Start date"
                error
              />
            }
          />
        </div>
        <FormControl
          className={`${classes.formControl} col-xl-2 col-md-4 col-5`}
          component="fieldset"
        >
          <FormLabel component="legend">Recommend Similar Duties?</FormLabel>
          <RadioGroup
            name={`${name}${arrIndexer}.empTDYDutyIsSimilar`}
            id={`${name}${arrIndexer}.empTDYDutyIsSimilar`}
            className="d-inline-block text-nowrap"
            aria-label="tdy-similar-duty"
            defaultValue={
              item && item.empTDYDutyIsSimilar?.toUpperCase() === "YES"
                ? "Yes"
                : "No"
            }
          >
            <FormControlLabel
              value="Yes"
              control={<Radio inputRef={register()} color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              value="No"
              control={<Radio inputRef={register()} color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        <div className="form-row col-xl-3 col-md-8 col-7">
          <NumberFormat
            customInput={TextField}
            id={`${name}${arrIndexer}.empTripCost`}
            name={`${name}${arrIndexer}.empTripCost`}
            label="Cost"
            thousandSeparator={true}
            decimalSeparator={false}
            prefix={"$"}
            InputLabelProps={{
              className: classes.leftOffset,
            }}
            className={`${classes.formControl} col-5`}
            inputRef={register()}
            defaultValue={item && item.empTripCost}
          />
          <TextField
            id={`${name}${arrIndexer}.empTripEAN`}
            name={`${name}${arrIndexer}.empTripEAN`}
            label="EAN"
            inputProps={{ maxLength: 20 }}
            InputLabelProps={{
              className: classes.leftOffset,
            }}
            className={`${classes.formControl} col-7`}
            inputRef={register()}
            defaultValue={item && item.empTripEAN}
          />
        </div>
        <div className="form-row col-12">
          <TextField
            id={`${name}${arrIndexer}.empTDYReason`}
            name={`${name}${arrIndexer}.empTDYReason`}
            label="Comments/Reason for Assignment"
            // InputLabelProps={{
            //   className: classes.leftOffset,
            // }}
            multiline
            rows={2}
            inputProps={{ maxLength: 1500 }}
            className={`${classes.formControl}`}
            inputRef={register({
              required: true,
            })}
            defaultValue={item && item.empTDYReason}
          />
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTDYReason")}
            message={
              <TextMessage text="Reason for assignment is required" error />
            }
          />
        </div>
        <Collapse
          in={open}
          timeout={600}
          className={`${classes.formControl} col-12`}
        >
          <div className="form-row col-xl-3 col-md-5 col-6">
            <TextField
              type="date"
              name={`${name}${arrIndexer}.empTDYEvalDate`}
              id={`${name}${arrIndexer}.empTDYEvalDate`}
              label="Evaluation Date"
              InputLabelProps={{
                shrink: true,
                className: classes.leftOffset,
              }}
              className={`${classes.formControl}`}
              inputRef={register()}
              defaultValue={
                (item &&
                  item.empTDYEvalDate &&
                  new Date(item.empTDYEvalDate).toISOString().split("T")[0]) ||
                undefined
              }
            />
          </div>
          <div className="form-row col-12">
            <TextField
              id={`${name}${arrIndexer}.empTDYEval`}
              name={`${name}${arrIndexer}.empTDYEval`}
              label="Performance Evaluation"
              InputLabelProps={{
                className: `${classes.topOffset} ${classes.leftOffset}`,
              }}
              multiline
              rowsMax={10}
              rows={3}
              inputProps={{ maxLength: 1500 }}
              variant="outlined"
              className={`${classes.formControl}`}
              inputRef={register()}
              defaultValue={item && item.empTDYEval}
            />
          </div>
        </Collapse>
      </div>
      <div className="form-row justify-content-center">
        <Tooltip
          title="Expand for Evaluation..."
          className={`${classes.formControl}`}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default StaffTDYItem;
