import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function ReportFormat2() {
  return (
    <Jumbotron as="div" className="App-reportpage" id="pgRptFormat2">
      <h1>Report Format 2</h1>
    </Jumbotron>
  );
}
