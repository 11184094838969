import React, { useEffect, useState, useReducer } from "react";
import { useForm, FormContext, useFieldArray } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { ActionTypes } from "../../reducers/action-types";
import { IEmployee, ITdy, IOffice } from "../../interfaces";
import { employeeInfoReducer } from "../../reducers/reducers";
import { clientWrapper } from "../../api";
import MessageBar, { severity } from "../messageBar.component";
import { EmployeeInfo, TDY, Save } from "../../classes";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FormContainer from "../formContainer.component";
import StaffSelector from "../staffSelector.component";
import StaffTDYDispGrid from "../staffTDYDispGrid.component";
import StaffTDYItem from "../staffTDYItem.component";
import NavButtonBar from "../navButtonBar.component";
import NavButton from "../navButton.component";
import "../../assets/sass/elements/staffTDY.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
      // minWidth: theme.spacing(15)
    },
    leftOffset: {
      left: "5px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    divider: {
      marginBottom: "4px !important",
      paddingTop: "4px !important",
      borderBottom: "1px inset rgba(52 107 169 / 0.3) !important",
    },
  })
);

const itemMapCallback = (item: ITdy) => {
  return new TDY(item);
};

type TParams = { action: string };

export default function StaffTDY({
  match,
  ...props
}: RouteComponentProps<TParams>) {
  const classes = useStyles();

  // API Client
  const client = clientWrapper("aws-us-east-2");
  const tripsDb = client.database("trips");
  const officesDb = client.database("offices");
  const save = new Save<Partial<ITdy>>();
  // Reducer Initializer
  const initializer: EmployeeInfo = new EmployeeInfo();

  // State
  const [employeeInfo, dispatch] = useReducer(employeeInfoReducer, initializer);

  const [saveObject, showMessage] = useState<{
    show: boolean;
    message: string;
    severity: severity;
  }>({ show: false, message: "", severity: undefined });

  // Form State
  const form = useForm<{
    mode: "onBlur";
    empSelected: IEmployee | null;
    newItems: Array<ITdy>;
  }>({
    defaultValues: {
      empSelected: null,
      newItems: [],
    },
  });

  const { control, watch, handleSubmit, reset } = form;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "newItems",
  });

  const record = watch<string, IEmployee>("empSelected");

  const onSubmit = async (data: {
    empSelected: IEmployee | null;
    newItems?: Array<ITdy>;
  }) => {
    const { tdyCollection } = employeeInfo;
    const { newItems } = data;

    if (!employeeInfo) return;

    try {
      const mappedItems =
        newItems &&
        newItems.map((item) => {
          const { empTripOffice: office } = item;
          item.empTripOffice = (office as IOffice).officeCode || office;
          item.empSSN = record.empSSN;
          item.empTripType = "T";
          return new TDY({
            ...item,
            empTripOffice: office ? office : "",
            currentOffice: office as IOffice,
            empSSN: record.empSSN,
            empTripType: "T",
          });
        });

      if (mappedItems) {
        const data = mappedItems.map((tdy) => tdy.NewSave);
        const numbers = await save.addAction("trips", data);
        numbers.forEach((id, ndx) => (mappedItems[ndx].empTripId = id));
        employeeInfo.tdyCollection.prepend(mappedItems);
      }

      const itemsToUpdate = tdyCollection.existingItems
        .filter((item) => item.updated)
        .map((tdy) => {
          return { ...tdy.DataToSave, id: tdy.empTripId };
        });

      if (itemsToUpdate.length > 0) {
        await save.updateAction(
          "trips",
          "empTripId",
          itemsToUpdate,
          (newData, oldData) => {
            oldData.empTripCost = newData.empTripCost;
            oldData.empTripOffice = newData.empTripOffice;
            oldData.empTripEAN = newData.empTripEAN;
            oldData.empTDYDutyIsSimilar = newData.empTDYDutyIsSimilar;
            oldData.empTripStart = newData.empTripStart;
            oldData.empTripEnd = newData.empTripEnd;
            oldData.empTDYEval = newData.empTDYEval;
            oldData.empTDYEvalDate = newData.empTDYEvalDate;
            oldData.empTDYReason = newData.empTDYReason;
            return oldData;
          }
        );
      }

      const itemsToDelete = tdyCollection.deletedItems;
      if (itemsToDelete.length > 0) {
        save.deleteAction("trips", "empTripId", itemsToDelete);

        tdyCollection.deletedItems.splice(0);
      }

      if (fields.length > 0) remove();

      dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employeeInfo });

      showMessage({ show: true, message: "Saved!", severity: "success" });
    } catch (error) {
      console.log(error);
      showMessage({
        show: true,
        message: "An error has Occurred!",
        severity: "error",
      });
    }
  };

  const hideMessage = () => {
    showMessage({ show: false, message: "", severity: undefined });
  };

  // useEffects
  useEffect(() => {
    if (!record) {
      dispatch({
        type: ActionTypes.INITIALIZE,
        payload: new EmployeeInfo(),
      });

      if (fields.length > 0) remove();

      return;
    }

    const selectedEmployee: EmployeeInfo = new EmployeeInfo(record);

    (async () => {
      try {
        const items = (await tripsDb
          .query()
          .match({ empSSN: record.empSSN })
          .where((o: ITdy) => o.empTripType === "T")
          .sort({
            func: (o: ITdy) => new Date(o.empTripStart).toISOString(),
            reverse: true,
          })
          .send()) as Array<ITdy>;

        if (items.length < 1) {
          if (fields.length > 1) remove();
          dispatch({ type: ActionTypes.INITIALIZE, payload: selectedEmployee });
          return;
        }

        selectedEmployee.tdyCollection.existingItems.push(
          ...items.map(itemMapCallback)
        );

        const officeQueryMatch = selectedEmployee.tdyCollection.existingItems
          .map((item) => `o.officeCode === '${item.empTripOffice}'`)
          .join(" || ");

        const offices = (await officesDb
          .query()
          .where(`o => ${officeQueryMatch}`)
          .send()) as Array<IOffice>;

        selectedEmployee.tdyCollection.existingItems.forEach((tdy, ndx) => {
          const currentOffice = offices.find(
            (office) => office.officeCode === tdy.empTripOffice
          );

          if (currentOffice) {
            tdy.currentOffice = currentOffice;
          }
        });

        if (fields.length > 1) remove();
        dispatch({ type: ActionTypes.INITIALIZE, payload: selectedEmployee });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <FormContainer
      id="pgStaffTDY"
      title="Add/Update TDY Assignment"
      subText="(to a Legat)"
    >
      <FormContext {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="formStaffTDY">
          <div className="form-row">
            <StaffSelector
              ctrlName="empSelected"
              ctrlLabel="Select Employee"
              variant="filled"
              addClass="leftOffset col-xl-4 col-lg-5 col-6"
            />
          </div>
          {fields.map((item, index) => {
            return (
              <div className="form-row align-top" key={item.id}>
                <StaffTDYItem
                  parentId="newItems"
                  name="newItems"
                  index={index}
                  addClass="col-md-11 col-12 align-top"
                  preset={item.empTripOffice}
                />
                <div className="row-control col-md-1 col-12 align-bottom text-center">
                  <Tooltip title="Delete Assignment">
                    <button
                      className="item-btn btn btn-link"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <i
                        className="item-icon fas fa-trash-alt"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </Tooltip>
                </div>
                <hr className={classes.divider} />
              </div>
            );
          })}
          <div className="btn-add-tdy form-row">
            <NavButtonBar
              title="Add Assignment"
              addClass="sect-toolbar btn-group-sm col-md-12 col-8 text-right"
            >
              <NavButton
                type="button"
                icon="fas fa-plus"
                title="Add Assignment"
                addClass="btn-secondary"
                dest="#"
                isDisabled={!record}
                action={() => {
                  append({});
                }}
              />
            </NavButtonBar>
          </div>

          <StaffTDYDispGrid
            parentId={"parentId"}
            addClass="mt-3"
            reducer={[employeeInfo, dispatch]}
          />

          <NavButtonBar title="Staff TDY Record Form" addClass="btn-group-sm">
            <NavButton
              type="submit"
              title="Submit"
              dest=""
              isDisabled={!record}
            />
            <NavButton
              type="button"
              title="Reset Form"
              dest="/staff/tdy"
              isDisabled={!record}
              action={() => {
                dispatch({
                  type: ActionTypes.INITIALIZE,
                  payload: new EmployeeInfo(),
                });
                reset();
              }}
            />
            <NavButton
              type="button"
              // icon="fas fa-users"
              title="Cancel"
              dest="/staff"
            />
          </NavButtonBar>
        </form>
        <MessageBar {...saveObject} onClose={hideMessage} />
      </FormContext>
    </FormContainer>
  );
}
