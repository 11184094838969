import {
  IEmployee,
  IOffice,
  IEmployeeInfo,
  ITdy,
  ITourOfDuty,
  ITravel,
  IDependent,
  ITraining,
} from "../interfaces";
import {
  EmpActionTypes,
  OfcActionTypes,
  ActionTypes,
  EmpInfoActionTypes,
} from "./action-types";
import {
  EmpActions,
  OfcActions,
  EmpInfoActions,
  CollectionActions,
} from "./actions";

export const employeeReducer = (
  // state: IEmployee | null | undefined,
  state: Partial<IEmployee> | null,
  action: EmpActions | OfcActions | EmpInfoActions<IDependent | ITraining>
) => {
  switch (action.type) {
    case EmpActionTypes.GET_EMPLOYEE:
      return action.payload;
    case EmpActionTypes.UPDATE_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
};

export const officeReducer = (
  // state: IOffice | null | undefined,
  state: Partial<IOffice> | null,
  action: EmpActions | OfcActions | EmpInfoActions<IDependent | ITraining>
) => {
  switch (action.type) {
    case OfcActionTypes.GET_OFFICE:
      return action.payload;
    default:
      return state;
  }
};

/* START EXPERIMENTAL LEARNING */
export const employeeItemsReducer = (
  state: IEmployeeInfo | null,
  action: EmpActions | OfcActions | EmpInfoActions<IDependent | ITraining>
) => {
  // working on it
  switch (action.type) {
    case EmpInfoActionTypes.INITIALIZE:
      return action.payload;
    case EmpInfoActionTypes.SET_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
};
/* END EXPERIMENTAL LEARNING */

export const employeeInfoReducer = (
  state: IEmployeeInfo,
  action: CollectionActions<
    ITdy | ITourOfDuty | ITravel | IDependent | ITraining
  >
) => {
  if (
    action.type === ActionTypes.INITIALIZE ||
    action.type === ActionTypes.SET_EMPLOYEE
  ) {
    return action.payload;
  }

  return state;
};
