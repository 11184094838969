import React from "react";
import SplashPage from "../components/splashPage.component";
import NavButtonBar from "../components/navButtonBar.component";
import NavButton from "../components/navButton.component";

export default function Staff() {
  return (
    <SplashPage pageId="pgStaff" title="Legat Staff">
      <div className="bodyText">
        {/* <h2 className="header">Legat Staff</h2> */}
        <NavButtonBar title="Staff Options" addClass="btn-group-med">
          <NavButton
            type="button"
            title="Add Employee"
            dest="/staff/addRecord"
          />
          <NavButton
            type="button"
            title="Update Employee"
            dest="/staff/updRecord"
          />
          <NavButton
            type="button"
            title="Tours of Duty"
            dest="/staff/tourOfDuty"
          />
          <NavButton type="button" title="TDY Assigments" dest="/staff/tdy" />
          <NavButton type="button" title="Travel" dest="/staff/travel" />
        </NavButtonBar>
      </div>
    </SplashPage>
  );
}
