import React from "react";
import SplashPage from "../components/splashPage.component";
import NavButtonBar from "../components/navButtonBar.component";
import NavButton from "../components/navButton.component";

export default function OfficeStaffing() {
    return (
        <SplashPage pageId="pgOfficeStaffing" title="Staffing (TBD)">
            <div className="bodyText">
                {/* <h2 className="header">Legat Offices</h2> */}
                <NavButtonBar title="Office Staffing Options" addClass="btn-group-med">
                    <NavButton
                        type="button"
                        title="Hiring"
                        dest="/offices/staffing/hiring"
                        isDisabled={true}
                    />
                    <NavButton
                        type="button"
                        title="Transfers"
                        dest="/offices/staffing/transfers"
                        isDisabled={true}
                    />
                    <NavButton
                        type="button"
                        title="NSDD38"
                        dest="/offices/staffing/nsdd38"
                        isDisabled={true}
                    />
                </NavButtonBar>
            </div>
        </SplashPage>
    );
}
