import React from "react";
import { useFetch } from "../api";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loader from "react-loader-spinner";
import "../assets/sass/elements/staffDependentsDispGrid.styles.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const useStyles = makeStyles({
  table: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
  },
});

type DependentsDispGridProps = {
  parentId: string;
  addClass?: string;
};

type DependentRow = {
  parentId: string;
  empDepFullName: string;
  empDepDOB: string;
  empDepRelationship: string;
  empDepStation: string;
};

export default function DependentsDispGrid({
  parentId,
  addClass,
}: Partial<DependentsDispGridProps>) {
  const url = `https://aws-us-east-2.unbounded.cloud/databases/personnelDependents/match?empSSN=${parentId}`;

  const classes = useStyles();

  const { loading, data, error } = useFetch<Array<DependentRow>>(url);

  if (loading)
    return (
      <div style={{ textAlign: "center", width: "100%" }}>
        <Loader
          color="rgba(0, 65, 137, 1)"
          height={50}
          width={50}
          type="ThreeDots"
        />
      </div>
    );

  if (error) return <h4>Error loading content {error ? `,${error}` : ""}</h4>;

  function dispDependent({
    empDepFullName: depFullName,
    empDepDOB: depDOB,
    empDepRelationship: depRelationship,
    empDepStation: depStation,
  }: DependentRow) {
    return (
      <>
        <TableCell component="th" scope="row">
          {depFullName}
        </TableCell>
        <TableCell align="center">{depDOB}</TableCell>
        <TableCell align="center">{depRelationship}</TableCell>
        <TableCell align="left">{depStation}</TableCell>
      </>
    );
  }

  return (
    <TableContainer
      id="staffDependentsGrid"
      className={`${addClass}`}
      component={Paper}
    >
      <Table
        className={classes.table}
        size="small"
        aria-label="employee's dependents"
      >
        <TableHead>
          <TableRow>
            <TableCell>Dependent</TableCell>
            <TableCell align="center">DOB</TableCell>
            <TableCell align="center">Relationship</TableCell>
            <TableCell align="left">Station</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data || (data && data.length < 1) ? (
            <TableRow key="No_Results">
              <TableCell colSpan={4} align="center">
                No dependents on file
              </TableCell>
            </TableRow>
          ) : (
            data.map((row) => (
              <TableRow key={row.empDepFullName}>{dispDependent(row)}</TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
