import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

import AppTitle from "./appTitle.component";

import splashBanner from "../assets/img/IOD_MapBkgrd.jpg";
import logo from "../assets/img/IOD_Seal_Transparent_140px.png";

import '../assets/sass/elements/splashPage.styles.scss';

type SplashPageProps = {
  addClass?: string;
  pageId: string;
  title: string;
};

const SplashPage = (props: React.PropsWithChildren<SplashPageProps>) => {
  return (
    <Jumbotron as="div" className="App-splashPage" id={props.pageId}>
      <div className="splashBanner">
        <img
          className="img-bkgrd img-fluid"
          src={splashBanner}
          alt="IOD Splash Banner"
        />
        <img className="img-logo" src={logo} alt="IOD Seal Logo" />
      </div>
      <AppTitle title={props.title} />

      {props.children}
    </Jumbotron>
  );
};
export default SplashPage;
