import React from "react";

import '../assets/sass/elements/appTitle.styles.scss';

type AppTitleProps = {
  title: string | React.ReactNode;
  subText?: string | React.ReactNode;
};

export default function AppTitle({ title, subText }: AppTitleProps) {
  // const subTitle = { subText } ? <h3>{subText}</h3> : null;

  return (
    <div className="App-title">
      <h2>{title}</h2>
      {{ subText } ? <h3>{subText}</h3> : null}
    </div>
  );
}
