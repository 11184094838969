import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      minWidth: theme.spacing(15),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type StreetAddressProps = {
  ctrlLabel: string;
  ctrlName?: string;
  addClass?: string;
  variant?: "filled" | "outlined" | "standard" | any;
  disabled?: boolean;
  preset?: (string | undefined)[] | undefined;
  parentId?: string | undefined;
};

const StreetAddress = ({
  ctrlName,
  ctrlLabel,
  addClass,
  variant,
  disabled,
  preset,
  parentId,
}: StreetAddressProps) => {
  const classes = useStyles();
  const idBase = `${ctrlLabel.toLowerCase().replace(" ", "-")}`;

  const { control, setValue } = useFormContext();

  const [curParentId, setCurParentId] = useState<string | undefined>();

  const ctrlNames = [
    `${ctrlName ? ctrlName : idBase}_line_1`,
    `${ctrlName ? ctrlName : idBase}_line_2`,
    `${ctrlName ? ctrlName : idBase}_line_3`,
    `${ctrlName ? ctrlName : idBase}_line_4`,
  ];
  useEffect(() => {
    if (parentId !== curParentId) {
      setValue(ctrlNames[0], preset?.[0] ? preset[0] : "");
      setValue(ctrlNames[1], preset?.[1] ? preset[1] : "");
      setValue(ctrlNames[2], preset?.[2] ? preset[2] : "");
      setValue(ctrlNames[3], preset?.[3] ? preset[3] : "");
      setCurParentId(parentId);
    }
    // }
    // console.log('parentId: ', parentId, '; curParentId: ', curParentId, "preset: ", preset);
  }, [setValue, ctrlNames, preset, parentId, curParentId]);

  return (
    <FormGroup
      className={`${classes.formControl} ${idBase}${
        addClass ? " " + addClass : ""
      }`}
    >
      <Controller
        as={<TextField inputProps={{ maxLength: 75 }} />}
        id={ctrlNames[0]}
        name={ctrlNames[0]}
        placeholder={`${ctrlLabel} Line 1`}
        label={ctrlLabel}
        variant={variant}
        disabled={disabled}
        control={control}
        defaultValue=""
      />
      <Controller
        as={<TextField inputProps={{ maxLength: 75 }} />}
        id={ctrlNames[1]}
        name={ctrlNames[1]}
        placeholder={`${ctrlLabel} Line 2`}
        variant={variant}
        disabled={disabled}
        control={control}
        defaultValue=""
      />
      <Controller
        as={<TextField inputProps={{ maxLength: 75 }} />}
        id={ctrlNames[2]}
        name={ctrlNames[2]}
        placeholder={`${ctrlLabel} Line 3`}
        variant={variant}
        disabled={disabled}
        control={control}
        defaultValue=""
      />
      <Controller
        as={<TextField inputProps={{ maxLength: 75 }} />}
        id={ctrlNames[3]}
        name={ctrlNames[3]}
        placeholder={`${ctrlLabel} Line 4`}
        variant={variant}
        disabled={disabled}
        control={control}
        defaultValue=""
      />
    </FormGroup>
  );
};

export default StreetAddress;
