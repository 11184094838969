import React from 'react';
import Nav from "react-bootstrap/Nav";

import NavIcon from './navIcon.component';

type NavDropdownProps = {
    name: string;
    icon: string;
    grpId: string;
}
type NavDropdownState = {
    isToggleOn: boolean;
}

class NavDropdown extends React.Component<NavDropdownProps, NavDropdownState> {
    constructor(props: Readonly<NavDropdownProps>) {
        super(props);
        this.state = { isToggleOn: false };
    }
    render() {
        const { name, icon, grpId, children } = this.props;
        const classDropdownMenu = 'collapse' + (this.state.isToggleOn ? ' show' : '');
        const idBase = `${grpId.toLowerCase().replace(" ", "-")}`;
        return (
            <Nav.Item>
                <Nav.Link
                    href={`#sect-${idBase}`}
                    role="NavLink"
                    as="div"
                    className="dropdown-toggle"
                    data-toggle="collapse"
                    aria-haspopup="true"
                    aria-expanded="false"
                    title={name}
                    active={false}
                >
                    <NavIcon icon={icon} />
                    {name}
                </Nav.Link>
                <hr />
                <Nav fill className={classDropdownMenu} id={`sect-${idBase}`}>
                    {children}
                </Nav>
            </Nav.Item>
        )
    }
}

export default NavDropdown;