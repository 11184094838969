import React, { useState, useEffect, useContext, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  useForm,
  FormContext,
  Controller,
  ErrorMessage,
} from "react-hook-form";

import { AppContext } from "../../context";
import {
  EmpActionTypes,
  EmpInfoActionTypes,
  OfcActionTypes,
} from "../../reducers/action-types";
import { IOffice } from "../../interfaces";
import { EmployeeInfo, Validation } from "../../classes";
import { ofcTypes } from "../../lookups";
import { clientWrapper } from "../../api";
import {
  boolYesNo,
  yesNoBool,
  timeStrTo12Hr,
  timeStrTo24Hr,
  convertStrToDate,
  ofcObjAsStr,
} from "../../utilities";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

import NavButtonBar from "../navButtonBar.component";
import NavButton from "../navButton.component";
import FormContainer from "../formContainer.component";
import StreetAddress from "../streetAddress.component";
import OfficeSelector from "../officeSelector.component";
import MessageBar, { severity } from "../messageBar.component";
import TextMessage from "../textMessage.component";

import "../../assets/sass/elements/officeRecord.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    formLabelOffset: {
      marginLeft: "-11px",
    },
    leftOffset: {
      left: "5px",
    },
    altLeftOffset: {
      left: "-10px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    noPaddingTop: {
      paddingTop: theme.spacing(0),
    },
    noPaddingBottom: {
      paddingBottom: theme.spacing(0),
    },
    numPadLeft: {
      paddingLeft: theme.spacing(3),
    },
  })
);

type TParams = { action: string };
type TStateProps = { prev: string; isCtxReset: boolean };

export default function OfficeRecord({
  match,
  ...props
}: RouteComponentProps<TParams, any, TStateProps>) {
  const classes = useStyles();

  const { location } = props;
  const formAction = match.params.action;
  const isExisting = formAction !== "addRecord";
  const formTitle = !isExisting ? "New Office" : "Update Office";

  const client = clientWrapper("aws-us-east-2");
  const officesDb = client.database("offices");

  const { dispatch } = useContext(AppContext);

  const methods = useForm<{
    mode: "onBlur";
    // shouldFocusError: true;
    ofcSelected: IOffice | null;
    ofcData: IOffice;
  }>({ defaultValues: { ofcSelected: null, ofcData: {} } });
  const {
    formState,
    setValue,
    getValues,
    control,
    handleSubmit,
    errors,
    reset,
    watch,
  } = methods;

  const [disabled, setDisabled] = useState(isExisting ? true : false);
  const watchOfcSelected = watch<string, IOffice>("ofcSelected");
  const [msg, setMsg] = useState<{
    show: boolean;
    message: string;
    severity: severity;
  }>({ show: false, message: "", severity: undefined });

  const resetOffice = useCallback(() => {
    setValue("ofcSelected", null);
    dispatch({
      type: OfcActionTypes.GET_OFFICE,
      payload: null,
    });
    reset(
      {},
      {
        errors: false, // errors will not be reset
        dirtyFields: false, // dirtyFields will not be reset
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  }, [dispatch, setValue, reset]);

  const resetContext = useCallback(() => {
    setValue("ofcSelected", null);
    dispatch({
      type: OfcActionTypes.GET_OFFICE,
      payload: null,
    });
    dispatch({
      type: EmpActionTypes.GET_EMPLOYEE,
      payload: null,
    });
    dispatch({
      type: EmpInfoActionTypes.INITIALIZE,
      payload: new EmployeeInfo(),
    });
    reset(
      {},
      {
        errors: false,
        dirtyFields: false,
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
    console.log("supposedly reset");
  }, [dispatch, setValue, reset]);

  const hideMessage = () => {
    setMsg({ show: false, message: "", severity: undefined });
  };

  // useEffect(() => {
  //   console.log(control);
  //   if (Object.keys(errors).length > 0) {
  //     console.log(errors);
  //   }
  // });

  useEffect(() => {
    if (location) {
      if (location.state) {
        if (location.state.prev !== location.pathname) {
          if (!location.state.isCtxReset) {
            // console.log("location.state.isCtxReset: ", location.state.isCtxReset)
            resetContext();
            location.state.isCtxReset = true;
          }
        } else {
          // console.log("location.state.isCtxReset: ", location.state.isCtxReset)
          location.state.isCtxReset = false;
        }
      }
    }

    return () => {
      // console.log("unmount effect1 (officeRecord)");
    };
  }, [location, resetContext]);

  useEffect(() => {
    // console.log("isExisting: ", isExisting);
    // console.log("watchOfcSelected: ", watchOfcSelected);
    setDisabled(!isExisting || watchOfcSelected ? false : true);
    if (watchOfcSelected !== undefined) {
      setValue("ofcData.officeName", watchOfcSelected?.officeName || "");
      setValue("ofcData.officeCode", watchOfcSelected?.officeCode || "");
      setValue(
        "ofcData.isExpansion",
        yesNoBool(watchOfcSelected?.isExpansion) || false
      );
      setValue(
        "ofcData.officeOpenedDate",
        convertStrToDate(watchOfcSelected?.officeOpenedDate) || ""
      );
      setValue(
        "ofcData.officeClosedDate",
        convertStrToDate(watchOfcSelected?.officeClosedDate) || ""
      );
      setValue("ofcData.officeType", watchOfcSelected?.officeType || "");
      setValue("ofcData.officeAgentFSL", watchOfcSelected?.officeAgentFSL || 0);
      setValue(
        "ofcData.officeSupportFSL",
        watchOfcSelected?.officeSupportFSL || 0
      );
      setValue(
        "ofcData.officeDaySchedule",
        watchOfcSelected?.officeDaySchedule || ""
      );
      setValue(
        "ofcData.officeHrsStart",
        timeStrTo24Hr(watchOfcSelected?.officeHrsStart) || ""
      );
      setValue(
        "ofcData.officeHrsEnd",
        timeStrTo24Hr(watchOfcSelected?.officeHrsEnd) || ""
      );
      setValue(
        "ofcData.officeHrsOffset",
        watchOfcSelected?.officeHrsOffset || 0
      );
      setValue(
        "ofcData.officeDialPrefix",
        watchOfcSelected?.officeDialPrefix || ""
      );
      setValue(
        "ofcData.officeDirectDial1",
        watchOfcSelected?.officeDirectDial1 || ""
      );
      setValue(
        "ofcData.officeDirectDial2",
        watchOfcSelected?.officeDirectDial2 || ""
      );
      setValue("ofcData.embassyLine1", watchOfcSelected?.embassyLine1 || "");
      setValue("ofcData.embassyLine2", watchOfcSelected?.embassyLine2 || "");
      setValue(
        "ofcData.officeSpeedDial",
        watchOfcSelected?.officeSpeedDial || ""
      );
      setValue("ofcData.officeCell", watchOfcSelected?.officeCell || "");
      setValue(
        "ofcData.officeSecureVoice",
        watchOfcSelected?.officeSecureVoice || ""
      );
      setValue(
        "ofcData.officeUnclassFax",
        watchOfcSelected?.officeUnclassFax || ""
      );
      setValue(
        "ofcData.officeSecureFax",
        watchOfcSelected?.officeSecureFax || ""
      );
      setValue("ofcData.officeVIGIT", watchOfcSelected?.officeVIGIT || "");
      setValue(
        "ofcData.officeDirectoryNote",
        watchOfcSelected?.officeDirectoryNote || ""
      );
    } else {
      resetOffice();
    }
    return () => {
      // console.log("unmount effect2 (officeRecord)");
    };
  }, [isExisting, watchOfcSelected, setValue, resetOffice]);

  const onSubmit = async (data: {
    ofcSelected: IOffice | null;
    ofcData: IOffice;
  }) => {
    const { ofcData, ofcSelected } = data;
    // console.log("ofcSelected: ", ofcSelected);

    if (!ofcData) return;
    let ofcId: string = "";
    try {
      ofcData.isExpansion = boolYesNo(ofcData.isExpansion);
      ofcData.officeHrsStart = timeStrTo12Hr(ofcData.officeHrsStart);
      ofcData.officeHrsEnd = timeStrTo12Hr(ofcData.officeHrsEnd);
      ofcData.officeHQ = ofcObjAsStr(ofcData.officeHQ);
      if (!ofcSelected) {
        // console.log("ofcData: ", ofcData);
        const { inserted } = await officesDb.add(ofcData);
        ofcId = inserted[0];
      } else {
        ofcId = ofcSelected.officeId;
        const { updated } = await officesDb
          .update()
          .match({ officeId: ofcId })
          .set((data: IOffice, obj: IOffice) => {
            obj.officeCode = data.officeCode;
            obj.officeName = data.officeName;
            obj.isExpansion = data.isExpansion;
            obj.officeHQ = data.officeHQ;
            obj.officeOpenedDate = data.officeOpenedDate;
            obj.officeClosedDate = data.officeClosedDate;
            obj.officeType = data.officeType;
            obj.officeAgentFSL = data.officeAgentFSL;
            obj.officeSupportFSL = data.officeSupportFSL;
            obj.officeDaySchedule = data.officeDaySchedule;
            obj.officeHrsStart = data.officeHrsStart;
            obj.officeHrsEnd = data.officeHrsEnd;
            obj.officeHrsOffset = data.officeHrsOffset;
            obj.officeMailingAddress_line_1 = data.officeMailingAddress_line_1;
            obj.officeMailingAddress_line_2 = data.officeMailingAddress_line_2;
            obj.officeMailingAddress_line_3 = data.officeMailingAddress_line_3;
            obj.officeMailingAddress_line_4 = data.officeMailingAddress_line_4;
            obj.officeStreetAddress_line_1 = data.officeStreetAddress_line_1;
            obj.officeStreetAddress_line_2 = data.officeStreetAddress_line_2;
            obj.officeStreetAddress_line_3 = data.officeStreetAddress_line_3;
            obj.officeStreetAddress_line_4 = data.officeStreetAddress_line_4;
            obj.officeDialPrefix = data.officeDialPrefix;
            obj.officeDirectDial1 = data.officeDirectDial1;
            obj.officeDirectDial2 = data.officeDirectDial2;
            obj.embassyLine1 = data.embassyLine1;
            obj.embassyLine2 = data.embassyLine2;
            obj.officeSpeedDial = data.officeSpeedDial;
            obj.officeVIGIT = data.officeVIGIT;
            obj.officeCell = data.officeCell;
            obj.officeSecureVoice = data.officeSecureVoice;
            obj.officeSecureData = data.officeSecureData;
            obj.officeUnclassFax = data.officeUnclassFax;
            obj.officeSecureFax = data.officeSecureFax;
            obj.officeCountry = data.officeCountry;
            obj.officeDirectoryNote = data.officeDirectoryNote;
            return obj;
          })
          .bind(ofcData)
          .single()
          .send();
        console.log("updated: ", updated);
      }
      // console.log("ofcId: ", ofcId);
      setMsg({ show: true, message: "Saved!", severity: "success" });
    } catch (error) {
      setMsg({ show: true, message: "Error!", severity: "error" });
      throw error;
    }
    resetOffice();
  };

  return (
    <FormContainer id="pgOfficeRecord" title={formTitle}>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="formOfficeRecord">
          {isExisting && (
            <div className="form-row">
              <OfficeSelector
                primary
                ctrlName="ofcSelected"
                ctrlLabel="Select Office"
                // addLabelClass="altLeftOffset"
                addClass={`${classes.formControl} col-lg-4 col-md-6`}
                display="full"
                variant="filled"
              />
            </div>
          )}
          <div className="form-row">
            <div className="col-lg-4 col-6">
              <Controller
                as={<TextField inputProps={{ maxLength: 35 }} />}
                type="text"
                id="ofcData.officeName"
                name="ofcData.officeName"
                defaultValue=""
                label="Office Name"
                // InputLabelProps={{
                //   className: classes.leftOffset,
                // }}
                className={`${classes.formControl}`}
                control={control}
                rules={{ required: true }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="ofcData.officeName"
                message={<TextMessage text="Office name required" error />}
              />
            </div>
            <div className="col-lg-2 col-4">
              <Controller
                as={<TextField inputProps={{ maxLength: 2 }} />}
                type="text"
                id="ofcData.officeCode"
                name="ofcData.officeCode"
                defaultValue=""
                label="Office Code"
                // InputLabelProps={{
                //   className: classes.leftOffset,
                // }}
                className={`${classes.formControl}`}
                control={control}
                rules={{ required: true }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="ofcData.officeCode"
                message={<TextMessage text="Office code required" error />}
              />
            </div>
            <div className="col-lg-1 col-2">
              <FormControlLabel
                className={`${classes.formControl} ${classes.formLabelOffset}`}
                control={
                  <Controller
                    as={<Checkbox />}
                    id="ofcData.isExpansion"
                    name="ofcData.isExpansion"
                    color="primary"
                    defaultValue={false}
                    control={control}
                    disabled={disabled}
                  />
                }
                label="Expansion?"
                labelPlacement="top"
              />
            </div>
            <div className="form-row col-lg-5 col-sm-9">
              <div className="col-6">
                <Controller
                  as={<TextField />}
                  type="date"
                  id="ofcData.officeOpenedDate"
                  name="ofcData.officeOpenedDate"
                  defaultValue=""
                  label="Date Office Opened"
                  InputLabelProps={{
                    shrink: true,
                    // className: classes.leftOffset,
                  }}
                  className={`${classes.formControl}`}
                  control={control}
                  rules={{ required: true }}
                  disabled={disabled}
                />
              </div>
              <div className="col-6">
                <Controller
                  as={<TextField />}
                  type="date"
                  id="ofcData.officeClosedDate"
                  name="ofcData.officeClosedDate"
                  defaultValue=""
                  label="Date Office Closed"
                  InputLabelProps={{
                    shrink: true,
                    // className: classes.leftOffset,
                  }}
                  className={`${classes.formControl}`}
                  control={control}
                  rules={{
                    validate: {
                      greaterThanStart: (value: string) => {
                        const startDate: string = getValues(
                          "ofcData.officeOpenedDate"
                        );
                        const clear =
                          !value || !startDate
                            ? true
                            : Validation.isBeforeDate(startDate, value);

                        if (clear) return true;

                        return false;
                      },
                    },
                  }}
                  disabled={disabled}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name={`ofcData.officeOpenedDate`}
                message={<TextMessage text="Opening date required" error />}
              />
              <ErrorMessage
                errors={errors}
                name={`ofcData.officeClosedDate`}
                message={
                  <TextMessage
                    text="Office closing date must not precede opening"
                    error
                  />
                }
              />
            </div>
            <FormControl
              id="officeType-selector"
              className={`${classes.formControl} col-3`}
            >
              <InputLabel id="officeType-label" className={classes.leftOffset}>
                Office Type
              </InputLabel>
              <Controller
                id="ofcData.officeType"
                name="ofcData.officeType"
                labelId="officeType-helper-label"
                defaultValue=""
                as={
                  <Select>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {ofcTypes.map((type) => (
                      <MenuItem key={type.id} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
                rules={{ required: true }}
                disabled={disabled}
              />
              <ErrorMessage
                errors={errors}
                name="ofcData.officeType"
                message={<TextMessage text="Office type required" error />}
              />
            </FormControl>
            <OfficeSelector
              ctrlName="ofcData.officeHQ"
              ctrlLabel="HQ Office"
              addLabelClass="altLeftOffset"
              display="short"
              addClass={`${classes.formControl} col-lg-3 col-sm-4 col-3`}
              disabled={disabled}
              preset={(watchOfcSelected && watchOfcSelected.officeHQ) || null}
            />
            <Controller
              as={<TextField inputProps={{ min: 0 }} />}
              type="number"
              id="ofcData.officeAgentFSL"
              name="ofcData.officeAgentFSL"
              defaultValue={0}
              label="Agent FSL"
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-lg-3 col-sm-4 col-3`}
              control={control}
              disabled={disabled}
            />
            <Controller
              as={<TextField inputProps={{ min: 0 }} />}
              type="number"
              min={0}
              id="ofcData.officeSupportFSL"
              name="ofcData.officeSupportFSL"
              defaultValue={0}
              label="Support FSL"
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-lg-3 col-sm-4 col-3`}
              control={control}
              disabled={disabled}
            />
          </div>
          <div className="form-row">
            <Controller
              as={<TextField />}
              type="text"
              id="ofcData.officeDaySchedule"
              name="ofcData.officeDaySchedule"
              defaultValue=""
              label="Schedule"
              placeholder="e.g., Mon-Fri"
              InputLabelProps={{
                shrink: true,
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-3`}
              control={control}
              disabled={disabled}
            />
            <Controller
              as={<TextField />}
              type="time"
              id="ofcData.officeHrsStart"
              name="ofcData.officeHrsStart"
              defaultValue=""
              label="From:"
              InputLabelProps={{
                shrink: true,
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-3`}
              control={control}
              disabled={disabled}
            />
            <Controller
              as={<TextField />}
              type="time"
              id="ofcData.officeHrsEnd"
              name="ofcData.officeHrsEnd"
              defaultValue=""
              label="To:"
              InputLabelProps={{
                shrink: true,
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-3`}
              control={control}
              disabled={disabled}
            />
            <Controller
              as={<TextField inputProps={{ min: -17, max: 17 }} />}
              type="number"
              id="ofcData.officeHrsOffset"
              name="ofcData.officeHrsOffset"
              defaultValue={0}
              label="Hrs Offset"
              InputLabelProps={{
                shrink: true,
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-3`}
              control={control}
              disabled={disabled}
            />
          </div>
          <div className="form-row">
            <StreetAddress
              ctrlName="ofcData.officeMailingAddress"
              ctrlLabel="Mailing Address"
              addClass="col-md-6"
              parentId={watchOfcSelected?.officeId}
              preset={[
                watchOfcSelected?.officeMailingAddress_line_1,
                watchOfcSelected?.officeMailingAddress_line_2,
                watchOfcSelected?.officeMailingAddress_line_3,
                watchOfcSelected?.officeMailingAddress_line_4,
              ]}
              disabled={disabled}
            />
            <StreetAddress
              ctrlName="ofcData.officeStreetAddress"
              ctrlLabel="Street Address"
              addClass="col-md-6"
              parentId={watchOfcSelected?.officeId}
              preset={[
                watchOfcSelected?.officeStreetAddress_line_1,
                watchOfcSelected?.officeStreetAddress_line_2,
                watchOfcSelected?.officeStreetAddress_line_3,
                watchOfcSelected?.officeStreetAddress_line_4,
              ]}
              disabled={disabled}
            />
          </div>
          <div className="form-row">
            <Controller
              as={<TextField inputProps={{ maxLength: 12 }} />}
              type="text"
              id="ofcData.officeDialPrefix"
              name="ofcData.officeDialPrefix"
              defaultValue=""
              label="Dial Prefix"
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-2`}
              control={control}
              disabled={disabled}
            />
            <FormGroup className={`${classes.formControl} col-sm-4 col-5`}>
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeDirectDial1"
                name="ofcData.officeDirectDial1"
                defaultValue=""
                label="Direct Dial"
                placeholder="Direct Dial 1"
                control={control}
                disabled={disabled}
              />
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeDirectDial2"
                name="ofcData.officeDirectDial2"
                defaultValue=""
                placeholder="Direct Dial 2"
                control={control}
                disabled={disabled}
              />
            </FormGroup>
            <FormGroup className={`${classes.formControl} col-sm-4 col-5`}>
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.embassyLine1"
                name="ofcData.embassyLine1"
                defaultValue=""
                label="Embassy Line"
                placeholder="Embassy Line 1"
                control={control}
                disabled={disabled}
              />
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.embassyLine2"
                name="ofcData.embassyLine2"
                defaultValue=""
                placeholder="Embassy Line 2"
                control={control}
                disabled={disabled}
              />
            </FormGroup>
            <FormGroup className={`${classes.formControl} col-2`}>
              <Controller
                as={<TextField inputProps={{ maxLength: 7 }} />}
                id="ofcData.officeSpeedDial"
                name="ofcData.officeSpeedDial"
                defaultValue=""
                label="Speed Dial"
                control={control}
                disabled={disabled}
              />
            </FormGroup>
            <div className="col-sm-2 col-0"></div>
            <FormGroup className={`${classes.formControl} col-sm-4 col-5`}>
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeCell"
                name="ofcData.officeCell"
                defaultValue=""
                label="Office Cell"
                control={control}
                disabled={disabled}
              />
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeSecureVoice"
                name="ofcData.officeSecureVoice"
                defaultValue=""
                label="Secure Voice"
                control={control}
                disabled={disabled}
              />
            </FormGroup>
            <FormGroup className={`${classes.formControl} col-sm-4 col-5`}>
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeUnclassFax"
                name="ofcData.officeUnclassFax"
                defaultValue=""
                label="Unclass Fax"
                control={control}
                disabled={disabled}
              />
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeSecureFax"
                name="ofcData.officeSecureFax"
                defaultValue=""
                label="Secure Fax"
                control={control}
                disabled={disabled}
              />
            </FormGroup>
            <FormGroup className={`${classes.formControl} col-2`}>
              <Controller
                as={<TextField inputProps={{ maxLength: 32 }} />}
                id="ofcData.officeVIGIT"
                name="ofcData.officeVIGIT"
                defaultValue=""
                label="VIGIT"
                control={control}
                disabled={disabled}
              />
            </FormGroup>
            <div className="col-md-2 col-0"></div>
            <Controller
              as={<TextField inputProps={{ maxLength: 1500 }} />}
              id="ofcData.officeDirectoryNote"
              name="ofcData.officeDirectoryNote"
              defaultValue=""
              label="Directory Note"
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              multiline
              rows={4}
              className={`${classes.formControl} col-md-8`}
              control={control}
              disabled={disabled}
            />
          </div>
          <NavButtonBar title="Office Record Form" addClass="btn-group-sm">
            <NavButton
              type="submit"
              title="Submit"
              dest=""
              isDisabled={!formState.dirty}
            />
            <NavButton
              type="button"
              title="Reset Form"
              dest={`/offices/${formAction}`}
              isDisabled={!formState.dirty}
              action={() => {
                resetOffice();
              }}
            />
            <NavButton
              type="button"
              title="Cancel"
              action={() => {
                resetOffice();
              }}
              dest="/offices"
            />
          </NavButtonBar>
        </form>
        <MessageBar {...msg} onClose={hideMessage} />
      </FormContext>
    </FormContainer>
  );
}
