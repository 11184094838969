import React from "react";

import '../assets/sass/elements/sectHeader.styles.scss';

type SectHeaderProps = {
  title: string | React.ReactNode;
};
function SectHeader({ title }: SectHeaderProps) {
  return <h4 className="sect-header">{title}</h4>;
}

export default SectHeader;
