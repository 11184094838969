import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';

import { StylesProvider } from '@material-ui/core/styles';

import { AppProvider } from './context';

// import { Provider } from 'react-redux';

// import store from './redux/store';
import App from './App';

import './assets/css/styles.css';

ReactDOM.render(
  <AppProvider>
    {/* <Provider store={store}> */}
    <StylesProvider injectFirst>
      <Router>
        <App />
      </Router>
    </StylesProvider>,
  {/* </Provider> */}
  </AppProvider>,
  document.getElementById('root')
);
