import React, { useState, useCallback, useContext, useEffect } from "react";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";

import { clientWrapper } from "../api";
import { AppContext } from "../context";
import { OfcActionTypes } from "../reducers/action-types";
import { IOffice } from "../interfaces";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete"; // createFilterOptions,
import CircularProgress from "@material-ui/core/CircularProgress";

import TextMessage from "../components/textMessage.component";

import "../assets/sass/elements/officeSelector.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    popper: {
      minWidth: 200,
    },
  })
);

type OfficeSelectorProps = {
  primary?: boolean;
  required?: boolean;
  display: "full" | "short";
  ctrlLabel: string;
  ctrlName: string;
  addClass?: string;
  addLabelClass?: string;
  variant?: "filled" | "outlined" | "standard" | any;
  disabled?: boolean;
  preset?: IOffice | string | null;
  disableOpts?: ((o: IOffice) => boolean) | undefined;
};

export default function OfficeSelector({
  primary,
  required,
  display,
  ctrlName,
  ctrlLabel,
  addClass,
  addLabelClass,
  variant,
  disabled,
  preset,
  disableOpts,
}: OfficeSelectorProps) {
  const classes = useStyles();

  const { dispatch } = useContext(AppContext);
  const {
    control,
    setValue,
    errors,
    clearError,
    formState,
    watch,
  } = useFormContext();

  const [open, setOpen] = useState(false);
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [presetValObj, setPresetValObj] = useState<IOffice | null>();
  const [newPreset, setNewPreset] = useState(true);
  const watchThisOffice = watch<string, IOffice>(ctrlName);

  const client = clientWrapper("aws-us-east-2");
  const officesDb = client.database("offices");

  const loading = open && offices.length === 0;

  // const filterOptions = createFilterOptions({
  //   matchFrom: "start",
  //   stringify: (option: IOffice) => {
  //     return `${option.officeCode + " " + option.officeName}`;
  //   },
  // });

  const getOfficeObj = useCallback(
    (item: string | IOffice | null) => {
      let newObj: IOffice | null = null;
      let checkObj: IOffice | undefined;
      if (item && offices.length !== 0) {
        if (
          typeof item === "string" ||
          (typeof item === "object" && item.hasOwnProperty("officeCode"))
        ) {
          checkObj = offices.find(
            (op: IOffice) =>
              (typeof item === "string" && op.officeCode === item) ||
              (typeof item === "object" &&
                item.hasOwnProperty("officeCode") &&
                op.officeCode === item?.officeCode)
          );
        }
        checkObj?.officeCode && (newObj = checkObj);
      }
      return newObj;
    },
    [offices]
  );

  useEffect(() => {
    let active = true;

    if (Object.keys(errors).length === 0) {
      (async () => {
        try {
          // if (open || formState.isSubmitted) {
          const offices = (await officesDb
            .query()
            .sort((o: IOffice) => o.officeCode + " " + o.officeName)
            .send()) as IOffice[];
          if (offices && active) {
            setOffices(offices);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    // console.log("do something in effect1");
    return () => {
      // console.log("unmount effect1 (" + ctrlName + ")");
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlName, formState.isSubmitted]);

  useEffect(() => {
    if (!primary) {
      if (offices.length !== 0 && preset !== undefined) {
        // console.log("offices (" + ctrlName + "): ", offices);
        setPresetValObj((preset && getOfficeObj(preset)) || null);
        setNewPreset(true);
      }

      return () => {
        // console.log("unmount effect2 (" + ctrlName + ")");
      };
    }
  }, [ctrlName, primary, offices, preset, getOfficeObj]);

  useEffect(() => {
    if (!primary) {
      console.log(
        "formState.isSubmitted (" + ctrlName + "): ",
        formState.isSubmitted
      );
      console.log(
        "formState.isSubmitting (" + ctrlName + "): ",
        formState.isSubmitting
      );
      console.log("watchThisOffice (" + ctrlName + "): ", watchThisOffice);
      if (presetValObj !== undefined && newPreset) {
        console.log("presetValObj (" + ctrlName + "): ", presetValObj);
        console.log("newPreset (" + ctrlName + "): ", newPreset);
        setValue(ctrlName, presetValObj);
        setNewPreset(false);
      }

      return () => {
        // console.log("unmount effect3 (" + ctrlName + ")");
      };
    }
  }, [ctrlName, primary, newPreset, presetValObj, setValue]);

  return (
    <div className={`${addClass ? " " + addClass : ""}`}>
      <Controller
        name={ctrlName}
        as={
          <Autocomplete
            id={ctrlName}
            className={`${classes.formControl}`}
            classes={{ popper: classes.popper }}
            autoHighlight
            autoSelect
            clearOnBlur
            clearOnEscape
            disabled={disabled}
            loading={loading}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={offices}
            // filterOptions={filterOptions}
            getOptionDisabled={disableOpts}
            getOptionLabel={(option) => {
              return display === "full"
                ? option.officeCode
                  ? `${option.officeName} (${option.officeCode})`
                  : ""
                : option.officeCode
                ? option.officeCode
                : "";
            }}
            renderOption={(option) => (
              <React.Fragment key={option.officeCode}>
                <span className="selValue selOffice-selValue">
                  {option.officeCode}
                </span>
                <span className="selLabel selOffice-selLabel">
                  {option.officeName}
                </span>
              </React.Fragment>
            )}
            getOptionSelected={(option: IOffice, value: IOffice) => {
              return option.officeCode === value.officeCode;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={ctrlName}
                label={ctrlLabel}
                variant={variant}
                fullWidth
                InputLabelProps={{
                  className: addLabelClass ? addLabelClass : "",
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        }
        control={control}
        defaultValue={null}
        rules={{ required: required ? required : true }}
        onChange={([, newValue]) => {
          primary &&
            dispatch({
              type: OfcActionTypes.GET_OFFICE,
              payload: newValue,
            });
          ctrlName && clearError(ctrlName);
          return newValue;
        }}
      />
      {required && (
        <ErrorMessage
          errors={errors}
          name={ctrlName}
          message={<TextMessage text={ctrlLabel + " required"} error />}
        />
      )}
    </div>
  );
}
