import React from "react";

import '../assets/sass/elements/formHeader.styles.scss';

type FormHeaderProps = {
  title: string | React.ReactNode;
  subText?: string | React.ReactNode;
}
function FormHeader({ title, subText }: FormHeaderProps) {
  return (
    <div className="form-header card-header bg-secondary text-center py-3">
      <h3>{title}</h3>
      {{ subText } ? <h5>{subText}</h5> : null}
    </div>
  );
}

export default FormHeader;
