import React, { createContext, useReducer, Dispatch, useMemo } from "react";
import { IEmployee, IOffice, IEmployeeInfo, IDependent, ITraining } from "./interfaces";
import { EmployeeInfo } from './classes';
import { EmpActions, OfcActions, EmpInfoActions } from "./reducers/actions";
import { employeeReducer, officeReducer, employeeItemsReducer } from "./reducers/reducers";

export type InitialStateType = {
  // employee?: IEmployee | null | undefined;
  employee: Partial<IEmployee> | null;
  office: Partial<IOffice> | null;
  employeeInfo: IEmployeeInfo | null;
};

const initialState = {
  // employee: {} as Partial<IEmployee>,
  // office: {} as Partial<IOffice>,
  employee: null,
  office: null,
  employeeInfo: new EmployeeInfo()
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<EmpActions | OfcActions | EmpInfoActions<IDependent | ITraining>>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { employee, office, employeeInfo }: InitialStateType,
  action: EmpActions | OfcActions | EmpInfoActions<IDependent | ITraining>
) => ({
  office: officeReducer(office, action),
  employee: employeeReducer(employee, action),
  employeeInfo: employeeItemsReducer(employeeInfo, action)
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { AppProvider, AppContext };
