import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function ReportFormat3() {
  return (
    <Jumbotron as="div" className="App-reportpage" id="pgRptFormat3">
      <h1>Report Format 3</h1>
    </Jumbotron>
  );
}
