import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

// import { AppContext } from '../context';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      minWidth: theme.spacing(15),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

type PhoneBlockProps = {
  ctrlName: string;
  ctrlLabel: string;
  addClass?: string;
  disabled?: boolean;
  preset?: ((string | undefined)[] | undefined)[] | undefined;
  parentId?: string | undefined;
};

export default function PhoneBlock({
  ctrlName,
  ctrlLabel,
  addClass,
  disabled,
  preset,
  parentId,
}: PhoneBlockProps) {
  const classes = useStyles();

  const { control, setValue } = useFormContext();

  const [curParentId, setCurParentId] = useState<string | undefined>();

  const ctrlNames = [
    `${ctrlName}_1`,
    `${ctrlName}_2`,
    `${ctrlName}_3`,
    `${ctrlName}_4`,
  ];

  useEffect(() => {
    // if (parentId) {
    if (parentId !== curParentId) {
      setValue(ctrlNames[0], preset?.[0]?.[1] ? preset[0][1] : "");
      setValue(ctrlNames[1], preset?.[1]?.[1] ? preset[1][1] : "");
      setValue(ctrlNames[2], preset?.[2]?.[1] ? preset[2][1] : "");
      setValue(ctrlNames[3], preset?.[3]?.[1] ? preset[3][1] : "");
      setCurParentId(parentId);
    }
    // }
    // console.log('parentId: ', parentId, '; curParentId: ', curParentId, "preset: ", preset);
  }, [setValue, ctrlNames, preset, parentId, curParentId]);

  return (
    <FormGroup
      className={`${classes.formControl} ${ctrlName}${
        addClass ? " " + addClass : ""
      }`}
    >
      <Controller
        as={<TextField inputProps={{ maxLength: 32 }} />}
        id={ctrlNames[0]}
        name={ctrlNames[0]}
        label={ctrlLabel}
        placeholder={preset?.[0]?.[0] ? preset[0][0] : "Phone"}
        disabled={disabled}
        control={control}
        defaultValue=""
      />
      {preset?.[1] ? (
        <Controller
          as={<TextField inputProps={{ maxLength: 32 }} />}
          id={ctrlNames[1]}
          name={ctrlNames[1]}
          placeholder={preset[1]?.[0] ? preset[1][0] : "Alt Phone 1"}
          disabled={disabled}
          control={control}
          defaultValue=""
        />
      ) : null}
      {preset?.[2] ? (
        <Controller
          as={<TextField inputProps={{ maxLength: 32 }} />}
          id={ctrlNames[2]}
          name={ctrlNames[2]}
          placeholder={preset[2]?.[0] ? preset[2][0] : "Alt Phone 2"}
          disabled={disabled}
          control={control}
          defaultValue=""
        />
      ) : null}
      {preset?.[3] ? (
        <Controller
          as={<TextField inputProps={{ maxLength: 32 }} />}
          id={ctrlNames[3]}
          name={ctrlNames[3]}
          placeholder={preset[3]?.[0] ? preset[3][0] : "Alt Phone 3"}
          disabled={disabled}
          control={control}
          defaultValue=""
        />
      ) : null}
    </FormGroup>
  );
}
