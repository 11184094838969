export const depTypes = [
  { id: 1, value: "B", label: "Son" },
  { id: 2, value: "F", label: "Father" },
  { id: 3, value: "G", label: "Daughter" },
  { id: 4, value: "M", label: "Mother" },
  { id: 5, value: "S", label: "Spouse" },
  { id: 6, value: "X", label: "Other" },
];

export const depStations = [
  { id: 1, value: "At Post", label: "At Post" },
  { id: 2, value: "College", label: "College" },
  { id: 3, value: "Domestic", label: "Domestic" },
];

export const ofcTypes = [
  { id: 1, value: "F", label: "Field Office" },
  { id: 2, value: "H", label: "HQ Division" },
  { id: 3, value: "L", label: "Legat" },
  { id: 4, value: "S", label: "Legat Suboffice" },
  { id: 5, value: "X", label: "Other" },
];

export const tripTypes = [
  { id: 1, value: "6", label: "Resolution 6" },
  { id: 2, value: "A", label: "Training" },
  { id: 3, value: "B", label: "Reg. Rest Break" },
  { id: 4, value: "C", label: "Consultation Travel" },
  { id: 5, value: "E", label: "Emer. Visit/Medical" },
  { id: 6, value: "H", label: "Home Leave" },
  { id: 7, value: "D", label: "Educational" },
  { id: 8, value: "R", label: "R & R Leave" },
  { id: 9, value: "S", label: "Children of Separated Parents" },
  { id: 10, value: "T", label: "Tour Renewal Travel" },
];
