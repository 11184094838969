import React from "react";
import SplashPage from "../components/splashPage.component";
import NavButtonBar from "../components/navButtonBar.component";
import NavButton from "../components/navButton.component";

export default function Offices() {
  return (
    <SplashPage pageId="pgOffices" title="Legat Offices">
      <div className="bodyText">
        {/* <h2 className="header">Legat Offices</h2> */}
        <NavButtonBar title="Office Options" addClass="btn-group-med">
          <NavButton
            type="button"
            title="Add Office"
            dest="/offices/addRecord"
          />
          <NavButton
            type="button"
            title="Update Office"
            dest="/offices/updRecord"
          />
          <NavButton
            type="button"
            title="Staffing (TBD)"
            dest="/offices/staffing"
          // isDisabled={true}
          />
        </NavButtonBar>
      </div>
    </SplashPage>
  );
}
