import React from 'react';

import '../assets/sass/elements/navIcon.styles.scss';

type NavIconProps = {
    icon: string;
}


const NavIcon = ({ icon }: NavIconProps) => {
    return (
        <i className={`nav-icon ${icon}`}></i>
    );
}

export default NavIcon;