import React, { useEffect, useState, useReducer } from "react";
import { IEmployee, ITourOfDuty, IOffice, IJob } from "../../interfaces";
import { useForm, FormContext, useFieldArray } from "react-hook-form";

import { clientWrapper } from "../../api";
import { ActionTypes } from "../../reducers/action-types";
import { employeeInfoReducer } from "../../reducers/reducers";
import { EmployeeInfo, TourOfDuty, Save } from "../../classes";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Tooltip } from "@material-ui/core";

import MessageBar, { severity } from "../messageBar.component";
import NavButton from "../navButton.component";
import NavButtonBar from "../navButtonBar.component";
import StaffSelector from "../staffSelector.component";
import FormContainer from "../formContainer.component";
import DependentsDispGrid from "../staffDependentsDispGrid.component";
import StreetAddress from "../streetAddress.component";
import PhoneBlock from "../phoneBlock.component";
import StaffTourOfDutyItem from "../staffTourOfDutyItem.component";
import StaffTourOfDutyDispGrid from "../staffTourOfDutyDispGrid.component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
    divider: {
      paddingTop: "4px !important",
      borderBottom: "1px inset rgba(52 107 169 / 0.3) !important",
    },
  })
);

const itemMapCallback = (item: ITourOfDuty) => {
  return new TourOfDuty(item);
};

export default function StaffTourOfDuty() {
  const classes = useStyles();

  // API Client

  const save = new Save<Partial<ITourOfDuty>>();
  const employeeSave = new Save<Partial<IEmployee>>();
  // Reducer Initializer
  const initializer: EmployeeInfo = new EmployeeInfo();

  // State
  const [employeeInfo, dispatch] = useReducer(employeeInfoReducer, initializer);
  const [disable, setDisable] = useState<boolean>(true);
  const [saveObject, showMessage] = useState<{
    show: boolean;
    message: string;
    severity: severity;
  }>({ show: false, message: "", severity: undefined });

  // Form State
  const form = useForm<{
    mode: "onBlur";
    empSelected: IEmployee | null;
    empOverseasAddress_line_1: string;
    empOverseasAddress_line_2: string;
    empOverseasAddress_line_3: string;
    empOverseasAddress_line_4: string;
    empPhone_1: string;
    empPhone_2: string;
    empPhone_3: string;
    empPhone_4: string;
    newItems?: Array<ITourOfDuty>;
  }>({
    defaultValues: {
      empSelected: null,
    },
  });

  const { handleSubmit, control, watch, reset } = form;

  const record = watch<string, IEmployee>("empSelected");

  const { fields, remove, append } = useFieldArray({
    control,
    name: "newItems",
  });

  const onSubmit = async (data: {
    empSelected: IEmployee | null;
    empOverseasAddress_line_1: string;
    empOverseasAddress_line_2: string;
    empOverseasAddress_line_3: string;
    empOverseasAddress_line_4: string;
    empPhone_1: string;
    empPhone_2: string;
    empPhone_3: string;
    empPhone_4: string;
    newItems?: Array<ITourOfDuty>;
  }) => {
    const { toursOfDuty } = employeeInfo;
    const {
      newItems,
      empOverseasAddress_line_1,
      empOverseasAddress_line_3,
      empOverseasAddress_line_2,
      empOverseasAddress_line_4,
      empPhone_1,
      empPhone_2,
      empPhone_3,
      empPhone_4,
    } = data;

    if (!employeeInfo) return;

    const updatedRecord: Partial<IEmployee> = {
      empId: employeeInfo.empId,
      empSSN: employeeInfo.empSSN,
      empOverseasAddress_line_1: empOverseasAddress_line_1,
      empOverseasAddress_line_2: empOverseasAddress_line_2,
      empOverseasAddress_line_3: empOverseasAddress_line_3,
      empOverseasAddress_line_4: empOverseasAddress_line_4,
      empPhone_1: empPhone_1,
      empPhone_2: empPhone_2,
      empPhone_3: empPhone_3,
      empPhone_4: empPhone_4,
    };

    try {
      employeeSave.updateAction(
        "personnel",
        "empId",
        [updatedRecord],
        (newData, oldData) => {
          const data = { ...oldData };
          data.empOverseasAddress_line_1 = newData.empOverseasAddress_line_1;
          data.empOverseasAddress_line_2 = newData.empOverseasAddress_line_2;
          data.empOverseasAddress_line_3 = newData.empOverseasAddress_line_3;
          data.empOverseasAddress_line_4 = newData.empOverseasAddress_line_4;
          data.empPhone_1 = newData.empPhone_1;
          data.empPhone_2 = newData.empPhone_2;
          data.empPhone_3 = newData.empPhone_3;
          data.empPhone_4 = newData.empPhone_4;

          return data;
        }
      );

      const mappedItems =
        newItems &&
        newItems.map((item) => {
          const { empTourOffice: office, empTourJob: job } = item;
          return new TourOfDuty({
            ...item,
            empSSN: record.empSSN,
            empTourId: item.empTourId,
            empTourOffice: office ? office : "",
            empTourStart: item.empTourStart,
            empTourEnd: item.empTourEnd,
            empTourJob: job ? job : "",
            currentJob: job ? (job as IJob) : undefined,
            currentOffice: office ? (office as IOffice) : undefined,
          });
        });

      const itemsToUpdate = toursOfDuty.existingItems
        .filter((item) => item.updated)
        .map((tour) => {
          return { ...tour.DataToSave };
        });

      const itemsToDelete = toursOfDuty.deletedItems;

      if (mappedItems) {
        const data = mappedItems.map((tour) => tour.NewSave);
        const numbers = await save.addAction("tours", data);
        numbers.forEach((id, ndx) => (mappedItems[ndx].empTourId = id));
        employeeInfo.toursOfDuty.prepend(mappedItems);
        remove();
      }

      if (itemsToUpdate.length > 0) {
        await save.updateAction(
          "tours",
          "empTourId",
          itemsToUpdate,
          (newData, oldData) => {
            const saveData = { ...oldData };

            saveData.empTourOffice = newData.empTourOffice;
            saveData.empTourStart = newData.empTourStart;
            saveData.empTourEnd = newData.empTourEnd;
            saveData.empTourJob = newData.empTourJob;

            return saveData;
          }
        );
      }

      if (itemsToDelete.length > 0) {
        const itemsDeleted = await save.deleteAction(
          "tours",
          "empTourId",
          itemsToDelete
        );

        if (itemsDeleted) toursOfDuty.deletedItems.splice(0);
      }

      dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employeeInfo });

      showMessage({ show: true, message: "Saved!", severity: "success" });
    } catch (error) {
      console.log(error);
      showMessage({
        show: true,
        message: "An error has Occurred!",
        severity: "error",
      });
    }
  };

  const hideMessage = () => {
    showMessage({ show: false, message: "", severity: undefined });
  };

  // useEffects
  useEffect(() => {
    if (!record) {
      dispatch({
        type: ActionTypes.INITIALIZE,
        payload: new EmployeeInfo(),
      });

      if (fields.length > 0) remove();
      setDisable(true);
      return;
    }

    const selectedEmployee: EmployeeInfo = new EmployeeInfo(record);

    (async () => {
      const client = clientWrapper("aws-us-east-2");
      const toursDb = client.database("tours");
      const officesDb = client.database("offices");
      const jobsDb = client.database("jobs");
      try {
        const tours = (await toursDb
          .query()
          .match({ empSSN: record.empSSN })
          .sort({
            func: (o: ITourOfDuty) => new Date(o.empTourStart).toISOString(),
            reverse: true,
          })
          .send()) as Array<ITourOfDuty>;

        if (tours.length < 1) {
          if (fields.length > 0) remove();
          dispatch({ type: ActionTypes.INITIALIZE, payload: selectedEmployee });
          return;
        }

        selectedEmployee.toursOfDuty.existingItems.push(
          ...tours.map(itemMapCallback)
        );

        const officeQueryMatch = selectedEmployee.toursOfDuty.existingItems
          .map((item) => `o.officeCode === '${item.empTourOffice}'`)
          .join(" || ");

        const offices = (await officesDb
          .query()
          .where(`o => ${officeQueryMatch}`)
          .send()) as Array<IOffice>;

        selectedEmployee.toursOfDuty.existingItems.forEach((tour, ndx) => {
          const currentOffice = offices.find(
            (office) => office.officeCode === tour.empTourOffice
          );

          if (currentOffice) {
            tour.currentOffice = currentOffice;
          }
        });

        const jobQueryMatch = selectedEmployee.toursOfDuty.existingItems
          .map((item) => `o.jobCode === '${item.empTourJob}'`)
          .join(" || ");

        const jobs = (await jobsDb
          .query()
          .where(`o => ${jobQueryMatch}`)
          .send()) as Array<IJob>;

        selectedEmployee.toursOfDuty.existingItems.forEach((tour, ndx) => {
          const code = (tour.empTourJob as IJob).jobCode || tour.empTourJob;
          const currentJob = jobs.find((job) => job.jobCode === code);

          if (currentJob) {
            tour.currentJob = currentJob;
          }
        });

        if (fields.length > 0) remove();

        dispatch({ type: ActionTypes.INITIALIZE, payload: selectedEmployee });
      } catch (error) {
        console.log(error);
      }
    })();

    setDisable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <FormContainer id="pgStaffTourOfDuty" title="Add/Update Tour-of-Duty">
      <FormContext {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="formStaffTourOfDuty">
          <div className="form-row">
            <StaffSelector
              ctrlName="empSelected"
              ctrlLabel="Select Employee"
              variant="filled"
              addClass="leftOffset col-xl-4 col-lg-5 col-6 mb-2"
              preset={null}
            />
          </div>
          {employeeInfo.empSSN && (
            <>
              <Box
                border={1}
                borderRadius="borderRadius"
                borderColor="text.disabled"
                p={2}
              >
                <div className="form-row">
                  <DependentsDispGrid
                    parentId={employeeInfo.empSSN}
                    addClass="mt-1 mx-1"
                  />
                </div>
                <div className="form-row">
                  <StreetAddress
                    ctrlName="empOverseasAddress"
                    ctrlLabel="Current Address"
                    addClass="leftOffset col-7"
                    parentId={employeeInfo?.empId}
                    preset={[
                      employeeInfo?.empOverseasAddress_line_1,
                      employeeInfo?.empOverseasAddress_line_2,
                      employeeInfo?.empOverseasAddress_line_3,
                      employeeInfo?.empOverseasAddress_line_4,
                    ]}
                  />
                  <PhoneBlock
                    ctrlName="empPhone"
                    ctrlLabel="Phone"
                    addClass="col-sm-5"
                    parentId={employeeInfo.empId}
                    preset={[
                      ["Home", employeeInfo.empPhone_1],
                      ["Mobile", employeeInfo.empPhone_2],
                      ["Pager", employeeInfo.empPhone_3],
                      ["Other", employeeInfo.empPhone_4],
                    ]}
                  />
                </div>
              </Box>
            </>
          )}
          {fields.map((item, index) => {
            return (
              <div className="form-row align-top" key={item.id}>
                <StaffTourOfDutyItem
                  parentId="thisParentId"
                  name="newItems"
                  index={index}
                  addClass="col-md-11 align-top"
                  preset={item.empTourOffice}
                />
                <div className="row-control col-md-1 align-top text-center">
                  <Tooltip title="Delete Tour">
                    <button
                      className="item-btn btn btn-link"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <i
                        className="item-icon fas fa-trash-alt"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </Tooltip>
                </div>
                <hr className={classes.divider} />
              </div>
            );
          })}
          <div className="btn-add-tour form-row">
            <NavButtonBar
              title="Add Tour"
              addClass="sect-toolbar btn-group-sm col-md-12 col-7 text-right"
            >
              <NavButton
                type="button"
                icon="fas fa-plus"
                title="Add Tour"
                addClass="btn-secondary m-1 mt-2"
                dest="#"
                action={() => {
                  append({});
                }}
                isDisabled={disable}
              />
            </NavButtonBar>
          </div>

          <StaffTourOfDutyDispGrid
            parentId={"parentId"}
            addClass="mt-3"
            reducer={[employeeInfo, dispatch]}
          />
          <NavButtonBar
            title="Staff Tour-of-Duty Record Form"
            addClass="btn-group-sm"
          >
            <NavButton
              type="submit"
              title="Submit"
              dest=""
              isDisabled={!record}
            />
            <NavButton
              type="button"
              title="Reset Form"
              dest="/staff/tourOfDuty"
              isDisabled={!record}
              action={() => {
                dispatch({
                  type: ActionTypes.INITIALIZE,
                  payload: new EmployeeInfo(),
                });
                reset();
              }}
            />
            <NavButton
              type="button"
              // icon="fas fa-users"
              title="Cancel"
              dest="/staff"
            />
          </NavButtonBar>
        </form>
        <MessageBar {...saveObject} onClose={hideMessage} />
      </FormContext>
    </FormContainer>
  );
}
