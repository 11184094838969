import React from "react";

import AppTitle from "../appTitle.component";

import logo from "../../assets/img/IOD_Seal_Transparent_140px.png";

import '../../assets/sass/elements/appHeader.styles.scss';

function Header() {
  return (
    <header className="App-header">
      <div className="App-logo">
        <a href="index.html">
          <img className="img-fluid" src={logo} alt="IOD Logo" />
        </a>
      </div>

      <AppTitle
        title="Legat Register"
        subText="Legat Personnel Management System"
      />
    </header>
  );
}

export default Header;
