import React from "react";
import { useFormContext, ErrorMessage } from "react-hook-form";
import NumberFormat from "react-number-format";

import { ITravel, IOffice, IEmployee } from "../interfaces";
import { arrIndexerName } from "../utilities";
import { Validation } from "../classes";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";

import OfficeSelector from "./officeSelector.component";
import TravelTypeSelector from "./travelTypeSelector.component";
import TextMessage from "../components/textMessage.component";

import "../assets/sass/elements/staffTravelItem.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
  })
);

type StaffTravelItemProps = {
  parentId: string;
  name: string;
  index: number;
  addClass?: string;
  item?: Partial<ITravel>;
  preset?: IOffice | string | null;
};

const StaffTravelItem = ({
  parentId,
  name,
  index,
  addClass,
  item,
  preset,
}: StaffTravelItemProps) => {
  const classes = useStyles();

  const { register, errors, control } = useFormContext();

  const arrIndexer = typeof index !== undefined ? `[${index}]` : "";

  return (
    <div className={addClass}>
      <div className="form-row">
        <>
          <OfficeSelector
            ctrlName={`${name}${arrIndexer}.empTripOffice`}
            ctrlLabel="Legat"
            // addLabelClass="leftOffset"
            addClass={`${classes.formControl} col-xl-3 col-4`}
            display="short"
            preset={item ? item.currentOffice : preset ? preset : null}
            required
          />
        </>
        <div className="form-row col-xl-6 col-8">
          <div className={"col-6"}>
            <TextField
              type="date"
              name={`${name}${arrIndexer}.empTripStart`}
              id={`${name}${arrIndexer}.empTripStart`}
              label="Trip Start"
              InputLabelProps={{
                shrink: true,
                // className: classes.leftOffset,
              }}
              className={`${classes.formControl}`}
              inputRef={register({ required: true })}
              defaultValue={item && item.empTripStart}
            />
          </div>
          <div className={"col-6"}>
            <TextField
              type="date"
              name={`${name}${arrIndexer}.empTripEnd`}
              id={`${name}${arrIndexer}.empTripEnd`}
              label="Trip End"
              InputLabelProps={{
                shrink: true,
                // className: classes.leftOffset,
              }}
              className={`${classes.formControl}`}
              inputRef={register({
                validate: {
                  greaterThanStart: (value: string) => {
                    const record = control.getValues({ nest: true }) as {
                      empSelected: IEmployee;
                      newItems: Array<ITravel>;
                    };

                    if (record && !record.newItems) return true;

                    const currTour: Partial<ITravel> =
                      record && record.newItems ? record.newItems[index] : {};

                    const clear =
                      !currTour.empTripStart || !value
                        ? true
                        : Validation.isBeforeDate(currTour.empTripStart, value);

                    if (clear) return true;

                    return false;
                  },
                },
              })}
              defaultValue={item && item.empTripEnd}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTripStart")}
            message={<TextMessage text="Start date required" error />}
          />
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTripEnd")}
            message={
              <TextMessage
                text="Trip end date must not precede start date"
                error
              />
            }
          />
        </div>
        {/* <div className="form-row col-xl-4 col-6"> */}
        <TextField
          id={`${name}${arrIndexer}.empTripEAN`}
          name={`${name}${arrIndexer}.empTripEAN`}
          label="EAN"
          InputLabelProps={{
            className: classes.leftOffset,
          }}
          className={`${classes.formControl} col-md-3 col-4`}
          inputRef={register()}
          defaultValue={item && item.empTripEAN}
        />
        <NumberFormat
          customInput={TextField}
          id={`${name}${arrIndexer}.empTripCost`}
          name={`${name}${arrIndexer}.empTripCost`}
          label="Cost"
          thousandSeparator={true}
          prefix={"$"}
          InputLabelProps={{
            className: classes.leftOffset,
          }}
          className={`${classes.formControl} col-md-3 col-4`}
          inputRef={register()}
          defaultValue={(item && item.empTripCost) || undefined}
        />
        {/* </div> */}
        <FormControl
          className={`${classes.formControl} col-md-3 col-4`}
          component="fieldset"
        >
          <FormLabel component="legend">Cost Calculation</FormLabel>
          <RadioGroup
            name={`${name}${arrIndexer}.empTripCostIsActual`}
            id={`${name}${arrIndexer}.empTripCostIsActual`}
            className="d-inline-block text-nowrap"
            aria-label="trip-cost-calc"
            defaultValue={(item && item.IsActual) || undefined}
          >
            <FormControlLabel
              value="Estimate"
              control={<Radio inputRef={register()} color="primary" />}
              label="Est."
            />
            <FormControlLabel
              value="Actual"
              control={<Radio inputRef={register()} color="primary" />}
              label="Actual"
            />
          </RadioGroup>
        </FormControl>
        <TravelTypeSelector
          ctrlName={`${name}${arrIndexer}.empTripType`}
          ctrlLabel="Travel Type"
          addLabelClass="leftOffset"
          addClass={`${classes.formControl} col-md-3 col-4`}
          defaultValue={item && item.empTripType}
          required
        />
        <div className="col-12">
          <TextField
            id={`${name}${arrIndexer}.empTripNotes`}
            name={`${name}${arrIndexer}.empTripNotes`}
            label="Notes"
            // InputLabelProps={{
            //   className: classes.leftOffset,
            // }}
            multiline
            rows={2}
            autoFocus
            margin={"none"}
            // className={`${classes.formControl}`}
            inputRef={register({
              required: true,
            })}
            inputProps={{ maxLength: 1500 }}
            defaultValue={item && item.empTripNotes}
          />
          <ErrorMessage
            errors={errors}
            name={arrIndexerName(name, index, "empTripNotes")}
            message={<TextMessage text="Reason for travel is required" error />}
          />
        </div>
      </div>
    </div>
  );
};

export default StaffTravelItem;
