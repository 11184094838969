import React from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import '../assets/sass/elements/navButtonBar.styles.scss';

type NavButtonBarProps = {
    addClass: string;
    title: string;
}

const NavButtonBar = (props: React.PropsWithChildren<NavButtonBarProps>) => {
    return (
        <ButtonToolbar
            className={`nav-btn-toolbar ${props.addClass}`}
            role="group"
            aria-label={`${props.title} Button Toolbar`}
        >
            {props.children}
        </ButtonToolbar>
    );
};
export default NavButtonBar;
