import React from "react";
// import { NavLink, useLocation } from "react-router-dom";

// import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import NavIcon from './navIcon.component';

import '../assets/sass/elements/navButton.styles.scss';
import '../assets/sass/elements/navIcon.styles.scss';

type NavButtonProps = {
  type: "button" | "submit" | "reset" | undefined;
  title: string;
  addClass?: string;
  icon?: string;
  dest: string;
  isDisabled?: boolean;
  action?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const NavButton = ({ type, title, addClass, icon, dest, isDisabled, action }: NavButtonProps) => {
  return (
    <React.Fragment>
      {(type === "submit") ? <Button
        type={type}
        className={`btn btn-fill${addClass ? " " + addClass : ""}`}
        disabled={isDisabled}
        onClick={action}
      >
        {icon ? <NavIcon icon={icon} /> : null} {title}
        {/* </Button> : <NavLink to={dest} title={title} activeClassName=""> */}
      </Button> : <LinkContainer to={dest} title={title} activeClassName="">
          <Button
            type={type}
            className={`btn btn-fill${addClass ? " " + addClass : ""}`}
            disabled={isDisabled}
            onClick={action}
          >
            {icon ? <NavIcon icon={icon} /> : null} {title}
          </Button>
        </LinkContainer>}
      {/* </NavLink>} */}
    </React.Fragment>
  );
};

export default NavButton;
