import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function ReportFormat1() {
  return (
    <Jumbotron as="div" className="App-reportpage" id="pgRptFormat1">
      <h1>Report Format 1</h1>
    </Jumbotron>
  );
}
