import React, { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete"; // createFilterOptions,
import CircularProgress from "@material-ui/core/CircularProgress";

import { IOffice } from "../interfaces";
import { clientWrapper } from "../api";

import "../assets/sass/elements/callPrefixSelector.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    popper: {
      minWidth: 200,
    },
  })
);
type CallPrefixSelectorProps = {
  ctrlName: string;
  ctrlLabel: string;
  addClass?: string;
  disabled?: boolean;
  preset?: IOffice | string | null;
  controller?: string;
};

export default function CallPrefixSelector({
  ctrlName,
  ctrlLabel,
  addClass,
  disabled,
  preset,
  controller,
}: CallPrefixSelectorProps) {
  const classes = useStyles();

  const { control, setValue, watch } = useFormContext();
  const watchController = watch<string, IOffice>(
    (controller && controller) || ""
  );

  const [open, setOpen] = useState(false);
  const [prefixes, setPrefixes] = useState<Partial<IOffice>[]>([]);
  const [newPreset, setNewPreset] = useState(true);

  const client = clientWrapper("aws-us-east-2");
  const officesDb = client.database("offices");

  const loading = open && prefixes.length === 0;

  // const filterOptions = createFilterOptions({
  //   matchFrom: "start",
  //   stringify: (option: Partial<IOffice>) => {
  //     return "";
  //   },
  // });

  useEffect(() => {
    // let active = true;
    (async () => {
      // if (open) {
      try {
        const items = (await officesDb
          .query()
          .where((o: IOffice) => o.officeDialPrefix)
          .sort((o: IOffice) => o.officeName)
          .map((o: IOffice) => {
            return {
              officeId: o.officeId,
              officeDialPrefix: o.officeDialPrefix,
              officeName: o.officeName,
            };
          })
          .send()) as Array<IOffice>;
        // if (items && active) {
        if (items) {
          const ofcPrefixes: Partial<IOffice>[] = items;

          // Uncomment to limit list to 'distinct' dial prefix entries
          // const ofcPrefixes: Partial<IOffice>[] = Array.from(
          //   new Set(items.map((s) => s.officeDialPrefix))
          // ).map((prefix) => {
          //   return {
          //     officeDialPrefix: prefix,
          //     officeId: items.find((s) => s.officeDialPrefix === prefix)?.officeId,
          //     officeName: items.find((s) => s.officeDialPrefix === prefix ?.officeName,
          //   };
          // });
          // console.log(ofcPrefixes);
          setPrefixes(ofcPrefixes);
        }
      } catch (error) {
        console.log(error);
      }
      // } else {
      // }
    })();
    // console.log("do something in effect1 (" + ctrlName + ")");
    return () => {
      //   console.log("unmount effect1 (" + ctrlName + ")");
      setPrefixes([]);
      // active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrlName]);

  useEffect(() => {
    if (prefixes.length !== 0 && preset !== undefined) {
      setNewPreset(true);
    }
    // console.log("do something in effect2 (" + ctrlName + ")");
    return () => {
      // console.log("unmount effect2 (" + ctrlName + ")");
    };
  }, [ctrlName, preset, prefixes]);

  useEffect(() => {
    console.log("watchController: ", watchController);
    if (prefixes.length !== 0) {
      let matched = null;
      if (watchController && watchController !== null) {
        matched = prefixes.find(
          (o) => o.officeDialPrefix === watchController.officeDialPrefix
        );
        setValue(ctrlName, matched);
      }
    }
    // console.log("do something in effect3 (" + ctrlName + ")");
    return () => {
      // console.log("unmount effect3 (" + ctrlName + ")");
    };
  }, [ctrlName, prefixes, setValue, watchController]);

  useEffect(() => {
    console.log("newPreset: ", newPreset);
    console.log("preset: ", preset);
    if (prefixes.length !== 0) {
      let matched = null;
      if (preset && newPreset) {
        if (preset !== null) {
          if (typeof preset === "object") {
            matched = prefixes.find(
              (o) => o.officeDialPrefix === preset.officeDialPrefix
            );
          } else matched = prefixes.find((o) => o.officeDialPrefix === preset);
        }
        setValue(ctrlName, matched);
        setNewPreset(false);
      }
    }
    // console.log("do something in effect4 (" + ctrlName + ")");
    return () => {
      // console.log("unmount effect4 (" + ctrlName + ")");
    };
  }, [ctrlName, preset, newPreset, prefixes, setValue]);

  return (
    <div className={`${addClass ? " " + addClass : ""}`}>
      <Controller
        name={ctrlName}
        control={control}
        defaultValue={null}
        onChange={([, newValue]) => {
          return newValue;
        }}
        as={
          <Autocomplete
            id={ctrlName}
            className={`${classes.formControl}`}
            classes={{ popper: classes.popper }}
            autoHighlight
            autoSelect
            clearOnBlur
            clearOnEscape
            disabled={disabled}
            loading={loading}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={prefixes}
            // filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option.officeDialPrefix ? option.officeDialPrefix : ""
            }
            renderOption={(option) => (
              <React.Fragment
                key={`${option.officeName}~${option.officeDialPrefix}`}
              >
                <span className="selLabel callPrefix-selLabel">
                  {option.officeName}
                </span>
                <span className="selValue callPrefix-selValue">
                  {option.officeDialPrefix}
                </span>
              </React.Fragment>
            )}
            getOptionSelected={(
              option: Partial<IOffice>,
              value: Partial<IOffice>
            ) => {
              return value?.officeDialPrefix && option?.officeDialPrefix
                ? option.officeDialPrefix === value.officeDialPrefix
                : false;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={ctrlName}
                label={ctrlLabel}
                fullWidth
                //   InputLabelProps={{
                //     className: classes.leftOffset,
                //   }}
                InputProps={{
                  ...params.InputProps,
                  // className: classes.leftOffsetSortOf,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        }
      />
    </div>
  );
}
