import React from "react";
import SplashPage from "../components/splashPage.component";
import NavButtonBar from "../components/navButtonBar.component";
import NavButton from "../components/navButton.component";

export default function Home() {
  return (
    <SplashPage pageId="pgHome" title="Welcome to the ...">
      <div className="bodyText">
        {/* <h2 className="header">Welcome to ...</h2> */}
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <NavButtonBar title="Main Options" addClass="btn-group-med">
          <NavButton
            type="button"
            title="Employees"
            icon="fas fa-users"
            dest="/staff"
          />
          <NavButton
            type="button"
            title="Offices"
            icon="far fa-building"
            dest="/offices"
          />
          <NavButton
            type="button"
            title="Staffing (TBD)"
            icon="fas fa-users-cog"
            dest="/offices/staffing"
          />
          <NavButton
            type="button"
            title="Reports"
            icon="fas fa-database"
            dest="/reports"
          />
        </NavButtonBar>
      </div>
    </SplashPage>
  );
}
