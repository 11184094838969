import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import FormHeader from "./formHeader.component";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
    })
);

type FormContainerProps = {
    id: string;
    title: string | React.ReactNode;
    subText?: string;
}

const FormContainer = (props: React.PropsWithChildren<FormContainerProps>) => {
    const classes = useStyles();

    return (
        <Jumbotron as="div" className="App-formpage py-lg-3" id={props.id}>
            <div className={`${classes.root} card mx-sm-3 my-lg-0`}>
                <FormHeader title={props.title} subText={props.subText} />
                <div className="card-body px-lg-2 py-lg-2">
                    {props.children}
                </div>
            </div>
        </Jumbotron>
    );
}

export default FormContainer;