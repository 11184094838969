import React, { useEffect, useContext } from "react";
import {
  useFormContext,
  Controller,
  useFieldArray,
  ErrorMessage,
} from "react-hook-form";
import NumberFormat from "react-number-format";
import { format } from "date-fns";

import { clientWrapper } from "../api";
import { AppContext } from "../context";
import { EmpInfoActionTypes } from "../reducers/action-types";
import { ITraining, IEmployee, IEmployeeInfo } from "../interfaces";
import { Training, Validation } from "../classes";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import SectHeader from "./sectHeader.component";
import NavButtonBar from "./navButtonBar.component";
import NavButton from "./navButton.component";
import OfficeSelector from "./officeSelector.component";
import CourseSelector from "./courseSelector.component";
import TextMessage from "./textMessage.component";
// import useForm from "../../hooks/useForm";

import "../assets/sass/elements/staffTrainingInputRows.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0),
    },
    leftOffset: {
      left: "5px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    divider: {
      paddingTop: "4px !important",
      borderBottom: "1px inset rgba(52 107 169 / 0.3) !important",
    },
  })
);

type TrainingRowsProps = {
  parentId: string | undefined;
  disabled?: boolean;
};

const StaffTrainingInputRows = ({ parentId, disabled }: TrainingRowsProps) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "empTraining",
  });

  const { state, dispatch } = useContext(AppContext);

  const { watch, register, errors, getValues } = useFormContext<{
    empSelected?: IEmployee;
  }>();

  const selectedEmp = watch("empSelected");

  const resetTrng = (employeeInfo: IEmployeeInfo) => {
    const { existingItems, deletedItems } = employeeInfo.trainingCollection;
    if (existingItems.length > 0 || deletedItems.length > 0) {
      employeeInfo.trainingCollection.reset();

      dispatch({
        type: EmpInfoActionTypes.SET_EMPLOYEE,
        payload: employeeInfo,
      });

      remove();
    }
  };

  useEffect(() => {
    if (!selectedEmp || !selectedEmp.empSSN) {
      state.employeeInfo && resetTrng(state.employeeInfo);
      return;
    }

    async function getTraining(id: string) {
      const client = clientWrapper("aws-us-east-2");
      const trainingDb = client.database("personnelTraining");

      try {
        const trng = (await trainingDb
          .query()
          .match({ empSSN: id })
          .sort({
            func: (o: ITraining) => new Date(o.empTrnStart).toISOString(),
            reverse: true,
          })
          .send()) as Array<ITraining>;

        if (trng && state.employeeInfo) {
          state.employeeInfo.trainingCollection.existingItems.push(
            ...trng.map((d) => new Training(d))
          );
          dispatch({
            type: EmpInfoActionTypes.SET_EMPLOYEE,
            payload: state.employeeInfo,
          });

          const mappedTrng = state.employeeInfo.trainingCollection.existingItems.map(
            (d) => {
              return {
                empTrnId: d.empTrnId,
                empTrnOffice: d.empTrnOffice,
                empTrnStart: d.empTrnStart,
                empTrnEnd: d.empTrnEnd,
                empTrnCourse: d.empTrnCourse,
                empTrnCost: d.empTrnCost,
                empTrnNote: d.empTrnNote,
              };
            }
          );

          append(mappedTrng);
        }
      } catch (error) {
        throw error;
      }
    }

    state.employeeInfo && resetTrng(state.employeeInfo);

    getTraining(selectedEmp.empSSN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmp]);

  return (
    <span className="subform" id="sectTraining">
      <SectHeader title="Training" />
      {fields.map((inputField, index) => {
        return (
          <div className="form-row" key={`${inputField.id}~${index}`}>
            <input
              type="hidden"
              name={`empTraining[${index}].empTrnId`}
              ref={register()}
              defaultValue={fields[index].empTrnId}
            />
            <input
              type="hidden"
              name={`empTraining[${index}].id`}
              ref={register()}
              defaultValue={fields[index].id}
            />
            <input
              type="hidden"
              name={`empTraining[${index}].empSSN`}
              ref={register()}
              defaultValue={selectedEmp && selectedEmp.empSSN}
            />
            <OfficeSelector
              ctrlName={`empTraining[${index}].empTrnOffice`}
              ctrlLabel="Legat"
              // addLabelClass="leftOffset"
              display="short"
              addClass={`${classes.formControl} col-xl-2 col-3`}
              preset={
                fields[index].empTrnOffice ? fields[index].empTrnOffice : null
              }
              required
            />
            <div className="form-row col-xl-4 col-lg-6 col-9">
              <div className="col-6">
                <Controller
                  name={`empTraining[${index}].empTrnStart`}
                  id={`empTraining[${index}].empTrnStart`}
                  as={<TextField />}
                  type="date"
                  label="Start"
                  InputLabelProps={{
                    shrink: true,
                    // className: classes.leftOffset,
                  }}
                  className={`${classes.formControl}`}
                  defaultValue={
                    fields[index].empTrnStart
                      ? format(
                          new Date(fields[index].empTrnStart),
                          "yyyy-MM-dd"
                        )
                      : ""
                  }
                  control={control}
                  rules={{ required: true }}
                />
              </div>
              <div className="col-6">
                <Controller
                  name={`empTraining[${index}].empTrnEnd`}
                  id={`empTraining[${index}].empTrnEnd`}
                  as={<TextField />}
                  type="date"
                  label="End"
                  InputLabelProps={{
                    shrink: true,
                    // className: classes.leftOffset,
                  }}
                  className={`${classes.formControl}`}
                  defaultValue={
                    fields[index].empTrnEnd
                      ? format(new Date(fields[index].empTrnEnd), "yyyy-MM-dd")
                      : ""
                  }
                  control={control}
                  rules={{
                    validate: {
                      greaterThanStart: (value: string) => {
                        const currStart: string = getValues(
                          `empTraining[${index}].empTrnStart`
                        );
                        const clear =
                          !value || !currStart
                            ? true
                            : Validation.isBeforeDate(currStart, value);

                        if (clear) return true;

                        return false;
                      },
                    },
                  }}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name={`empTraining[${index}].empTrnStart`}
                message={<TextMessage text="Start date required" error />}
              />
              <ErrorMessage
                errors={errors}
                name={`empTraining[${index}].empTrnEnd`}
                message={
                  <TextMessage
                    text="'End' date must not precede 'Start'"
                    error
                  />
                }
              />
            </div>
            <CourseSelector
              ctrlName={`empTraining[${index}].empTrnCourse`}
              ctrlLabel="Course"
              addLabelClass="leftOffset"
              addClass={`${classes.formControl}} col-xl-2 col-lg-3 col-6`}
              preset={
                fields[index].empTrnCourse ? fields[index].empTrnCourse : null
              }
              required={true}
            />
            <Controller
              as={<NumberFormat />}
              name={`empTraining[${index}].empTrnCost`}
              control={control}
              id={`empTraining[${index}].empTrnCost`}
              customInput={TextField}
              label="Cost"
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              thousandSeparator={true}
              prefix={"$"}
              className={`${classes.formControl} col-xl-1 col-lg-3 col-6`}
              defaultValue={fields[index].empTrnCost}
            />
            <Controller
              as={<TextField inputProps={{ maxLength: 75 }} />}
              name={`empTraining[${index}].empTrnNote`}
              control={control}
              id={`empTraining[${index}].empTrnNote`}
              type="text"
              label="Note"
              InputLabelProps={{
                className: classes.leftOffset,
              }}
              className={`${classes.formControl} col-xl-2 col-lg-8 col-11`}
              defaultValue={
                (fields[index].empTrnNote && fields[index].empTrnNote) || ""
              }
            />
            <div className="row-control col-1">
              <Tooltip title="Delete Training Item">
                <button
                  className="item-btn btn btn-link"
                  type="button"
                  onClick={() => {
                    if (state.employeeInfo) {
                      const id = fields[index].empTrnId;

                      if (id) {
                        state.employeeInfo.trainingCollection.deleteItems([
                          index,
                        ]);

                        dispatch({
                          type: EmpInfoActionTypes.SET_EMPLOYEE,
                          payload: state.employeeInfo,
                        });
                      }
                    }

                    remove(index);
                  }}
                >
                  <i
                    className="item-icon fas fa-trash-alt"
                    aria-hidden="true"
                  ></i>
                </button>
              </Tooltip>
            </div>
            <hr className={classes.divider} />
          </div>
        );
      })}
      <hr />
      <div className="form-row">
        <NavButtonBar
          title="Add Training"
          addClass="sect-toolbar btn-group-sm col-md-12 text-right"
        >
          <NavButton
            type="button"
            icon="fas fa-plus"
            title="Add Training"
            addClass="btn-secondary"
            dest="#"
            action={() => append({})}
            isDisabled={disabled}
          />
        </NavButtonBar>
      </div>
    </span>
  );
};

export default StaffTrainingInputRows;
