import React, { useEffect, useState, useReducer } from "react";
import { useForm, FormContext, useFieldArray } from "react-hook-form";
import { RouteComponentProps } from "react-router";

import { clientWrapper } from "../../api";
import {
  IEmployee,
  ITravel,
  IOffice,
  ITourOfDuty,
  IJob,
} from "../../interfaces";
import { EmployeeInfo, Travel, TourOfDuty, Save } from "../../classes";
import { ActionTypes } from "../../reducers/action-types";
import { employeeInfoReducer } from "../../reducers/reducers";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

import NavButtonBar from "../navButtonBar.component";
import NavButton from "../navButton.component";
import FormContainer from "../formContainer.component";
import StaffSelector from "../staffSelector.component";
import StaffTravelItem from "../staffTravelItem.component";
import StaffTravelDispGrid from "../staffTravelDispGrid.component";
import StaffTourOfDutyDispGrid from "../staffTourOfDutyDispGrid.component";
import MessageBar, { severity } from "../messageBar.component";

import "../../assets/sass/elements/staffTravel.styles.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: "4px !important",
      paddingTop: "4px !important",
      borderBottom: "1px inset rgba(52 107 169 / 0.3) !important",
    },
  })
);

const itemMapCallback = (item: ITravel) => {
  return new Travel(item);
};

type TParams = { action: string };

export default function StaffTravel({
  match,
  ...props
}: RouteComponentProps<TParams>) {
  const classes = useStyles();

  // API Client

  const save = new Save<Partial<Travel>>();
  // Reducer Initializer
  const initializer: EmployeeInfo = new EmployeeInfo();

  // State
  const [employeeInfo, dispatch] = useReducer(employeeInfoReducer, initializer);
  const [saveObject, showMessage] = useState<{
    show: boolean;
    message: string;
    severity: severity;
  }>({ show: false, message: "", severity: undefined });

  // Form State
  const form = useForm<{
    mode: "onBlur";
    empSelected: IEmployee | null;
    newItems?: Array<ITravel>;
  }>({ defaultValues: { empSelected: null } });

  const { handleSubmit, control, watch, reset } = form;

  const { fields, remove, append } = useFieldArray({
    control: control,
    name: "newItems",
  });

  const hideMessage = () => {
    showMessage({ show: false, message: "", severity: undefined });
  };

  const onSubmit = async (data: {
    empSelected: IEmployee | null;
    newItems?: Array<ITravel>;
  }) => {
    const { travelCollection } = employeeInfo;
    const { newItems } = data;

    try {
      const mappedItems =
        newItems &&
        newItems.map((item) => {
          const { empTripOffice: office } = item;
          return new Travel({
            ...item,
            empSSN: record.empSSN,
            empTourOffice: office ? office : "",
            currentOffice: office as IOffice,
          });
        });

      const itemsToUpdate = travelCollection.existingItems
        .filter((item) => item.updated)
        .map((travel) => {
          return { ...travel.DataToSave };
        });

      const itemsToDelete = travelCollection.deletedItems;

      if (mappedItems) {
        const data = mappedItems.map((tour) => tour.NewSave);
        const numbers = await save.addAction("trips", data);
        numbers.forEach((id, ndx) => (mappedItems[ndx].empTripId = id));
        employeeInfo.travelCollection.prepend(mappedItems, (items) => {
          return items.sort((aTravel, bTravel) => {
            const aDate = new Date(aTravel.empTripStart).toISOString();
            const bDate = new Date(bTravel.empTripStart).toISOString();
            return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
          });
        });
        remove();
      }

      if (itemsToUpdate.length > 0) {
        for (let index = 0; index < itemsToUpdate.length; index++) {
          await save.updateAction(
            "trips",
            "empTripId",
            itemsToUpdate,
            (data, o) => {
              o.empTripEAN = data.empTripEAN;
              o.empTripOffice = data.empTripOffice;
              o.empTripCostIsActual = data.empTripCostIsActual;
              o.empTripNotes = data.empTripNotes;
              o.empTripCost = data.empTripCost;
              o.empTripEnd = data.empTripEnd;
              o.empTripStart = data.empTripStart;
              o.empTripType = data.empTripType;
              return o;
            }
          );
        }
      }

      if (itemsToDelete.length > 0) {
        const itemsDeleted = await save.deleteAction(
          "trips",
          "empTripId",
          itemsToDelete
        );

        if (itemsDeleted) travelCollection.deletedItems.splice(0);
      }

      dispatch({ type: ActionTypes.SET_EMPLOYEE, payload: employeeInfo });

      showMessage({ show: true, message: "Saved!", severity: "success" });
    } catch (error) {
      console.log(error);
      showMessage({
        show: true,
        message: "An error has Occurred!",
        severity: "error",
      });
    }
  };
  const record = watch<string, IEmployee>("empSelected");

  useEffect(() => {
    if (!record) {
      dispatch({
        type: ActionTypes.INITIALIZE,
        payload: new EmployeeInfo(),
      });
      if (fields.length > 0) remove();
      return;
    }

    const selectedEmployee: EmployeeInfo = new EmployeeInfo(record);

    (async () => {
      const client = clientWrapper("aws-us-east-2");
      const tripsDb = client.database("trips");
      const officesDb = client.database("offices");
      const toursOfDutyDb = client.database("tours");
      const jobsDb = client.database("jobs");
      try {
        const travels = (await tripsDb
          .query()
          .match({ empSSN: record.empSSN })
          .where((o: ITravel) => o.empTripType !== "T")
          .sort({
            func: (o: ITravel) => new Date(o.empTripStart),
            reverse: true,
          })
          .send()) as Array<ITravel>;

        const tours = (await toursOfDutyDb
          .query()
          .match({ empSSN: record.empSSN })
          .send()) as Array<ITourOfDuty>;

        if (travels.length > 1) {
          selectedEmployee.travelCollection.existingItems.push(
            ...travels.map(itemMapCallback)
          );

          const officeQueryMatch = selectedEmployee.travelCollection.existingItems
            .map((item) => `o.officeCode === '${item.empTripOffice}'`)
            .join(" || ");

          const offices = (await officesDb
            .query()
            .where(`o => ${officeQueryMatch}`)
            .send()) as Array<IOffice>;

          selectedEmployee.travelCollection.existingItems.forEach(
            (travel, ndx) => {
              const currentOffice = offices.find(
                (office) => office.officeCode === travel.empTripOffice
              );

              if (currentOffice) {
                travel.currentOffice = currentOffice;
              }
            }
          );
        }

        if (tours.length > 0) {
          selectedEmployee.toursOfDuty.existingItems.push(
            ...tours.map((tour) => new TourOfDuty(tour))
          );

          const jobQueryMatch = selectedEmployee.toursOfDuty.existingItems
            .map((item) => `o.jobCode === '${item.empTourJob}'`)
            .join(" || ");

          const jobs = (await jobsDb
            .query()
            .where(`o => ${jobQueryMatch}`)
            .send()) as Array<IJob>;

          selectedEmployee.toursOfDuty.existingItems.forEach((tour, ndx) => {
            const code = (tour.empTourJob as IJob).jobCode || tour.empTourJob;
            const currentJob = jobs.find((job) => job.jobCode === code);

            if (currentJob) {
              tour.currentJob = currentJob;
            }
          });
        }

        if (fields.length > 0) remove();
        dispatch({ type: ActionTypes.INITIALIZE, payload: selectedEmployee });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <FormContainer
      id="pgStaffTravel"
      title="Add/Update Travel"
      subText="(from a Legat)"
    >
      <FormContext {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="formStaffTravel">
          <div className="form-row">
            <StaffSelector
              ctrlName="empSelected"
              ctrlLabel="Select Employee"
              variant="filled"
              addClass="leftOffset col-xl-4 col-lg-5 col-6 mb-2"
            />
          </div>
          {employeeInfo.empSSN && (
            <>
              {/* <Box
                border={1}
                borderRadius="borderRadius"
                borderColor="text.disabled"
                p={1}
              > */}
              <StaffTourOfDutyDispGrid
                parentId="parentId"
                addClass="mt-1 mb-2"
                isDispOnly={true}
                reducer={[employeeInfo, dispatch]}
              />
              {/* </Box> */}
            </>
          )}
          {fields.map((item, index) => {
            return (
              <div className="form-row align-top" key={item.id}>
                <StaffTravelItem
                  parentId="newItems"
                  name="newItems"
                  index={index}
                  addClass="col-lg-11 align-top"
                  preset={item.empTripOffice}
                />
                <div className="row-control col-lg-1 align-top text-center">
                  <Tooltip title="Delete Trip">
                    <button
                      className="item-btn btn btn-link"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <i
                        className="item-icon fas fa-trash-alt"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </Tooltip>
                </div>
                <hr className={classes.divider} />
              </div>
            );
          })}
          <div className="btn-add-trip form-row">
            <NavButtonBar
              title="Add Travel"
              addClass="sect-toolbar btn-group-sm col-md-12 col-7 text-right"
            >
              <NavButton
                type="button"
                icon="fas fa-plus"
                title="Add Travel"
                addClass="btn-secondary"
                dest="#"
                isDisabled={!record}
                action={() => append({})}
              />
            </NavButtonBar>
          </div>
          <StaffTravelDispGrid
            parentId="parentId"
            // dispatch={dispatch}
            addClass="mt-3"
            reducer={[employeeInfo, dispatch]}
          />
          <NavButtonBar
            title="Staff Travel Record Form"
            addClass="btn-group-sm"
          >
            <NavButton
              type="submit"
              title="Submit"
              dest=""
              isDisabled={!record}
            />
            <NavButton
              type="button"
              title="Reset Form"
              dest="/staff/travel"
              isDisabled={!record}
              action={() => {
                dispatch({
                  type: ActionTypes.INITIALIZE,
                  payload: new EmployeeInfo(),
                });
                reset();
              }}
            />
            <NavButton
              type="button"
              // icon="fas fa-users"
              title="Cancel"
              dest="/staff"
            />
          </NavButtonBar>
        </form>
        <MessageBar {...saveObject} onClose={hideMessage} />
      </FormContext>
    </FormContainer>
  );
}
